import Cookies from 'js-cookie';
export function clearAllCookie() {
  const cookies = Cookies.get();
  const { domain } = getCookieOption();
  for (const key in cookies) {
    Cookies.remove(key, { domain, path: '/' });
  }
}
export function clearGrayCookie(keys = []) {
  const { domain } = getCookieOption();
  keys.forEach((key) => {
    Cookies.remove(key, { domain, path: '/' });
  });
}
export function getCookieOption() {
  const hostname = location.hostname.includes(`cpl`) ? `.coros.com` : location.hostname;
  const domain = process.env.NODE_ENV === 'production' ? '.coros.com' : hostname;
  return {
    domain
  };
}
