import axios from 'axios';
import qs from 'query-string';
import { ref } from 'vue';
import dayjs from 'dayjs';
import { userStore as useUserStore } from '@client/store/userStore';
const useServerHealthCheck = () => {
  const checkPassTime = 5;
  const retryTime = ref(0);
  const healthTime = ref(0);
  const redirecting = ref(false);

  const fetchServerData = async () => {
    const { maintenance } = window.__INITIAL_STATE__;
    const url = encodeURIComponent(`${maintenance}/coros/maintenance/query`);
    const res = await axios.get(`https://faq.coros.com/proxy?url=${url}`);
    const data = res.data;
    return data;
  };
  const handleServerError = async (error) => {
    const { response } = error;
    if (response.status >= 500) {
      // 防止重复请求, 重复跳转
      if (redirecting.value) {
        return;
      }
      redirecting.value = true;
      await checkHealth({
        redirect: true
      });
    }
  };
  const handleApiError=async (result)=>{
    if (result=== '1001') {
      // 防止重复请求, 重复跳转
      if (redirecting.value) {
        return;
      }
      redirecting.value = true;
      await checkHealth({
        redirect: true
      });
    }
  }
  const checkHealth = async (options) => {
    const { redirect } = options;
    const res = await fetchServerData();
    if (res.result !== '0000') {
      return;
    }
    if (!res.data) return;
    const { startTime, endTime } = res.data;
    const isDurningMaintenanceTime = testDurningMaintenanceTime(startTime, endTime);
    if (!isDurningMaintenanceTime) return;
    if (location.pathname === '/500') return;
    console.log('即将跳转');
    if (redirect) {
      const lastUrl = encodeURIComponent(location.href);
      // 重定向
      location.href = `/500?lastUrl=${lastUrl}`;
    }
    return res.data;
  };
  const testDurningMaintenanceTime = (startTime, endTime) => {
    const _now = dayjs().unix();
    if (_now >= startTime && _now <= endTime) {
      // 维护中
      return true;
    }
    return false;
  };
  // 持续检查状态, 3 次正常则判断为服务正常
  const pollingCheck = async () => {
    const userStore = useUserStore();
    // 检查业务接口是否恢复
    try {
      const res = await userStore.getUserInfo();
      healthTime.value=healthTime.value + 1
      return {
        healthTime: healthTime.value,
        status: 'ok'
      };
    } catch (error) {
      // 业务没通, 继续请求
      console.log(444, error);
      return {
        healthTime: 0,
        status: 'failed'
      };
    }
  };
  const redirectBack = () => {
    let redirect_url = '';
    const { lastUrl } = qs.parse(location.search);
    if (!lastUrl) {
      redirect_url = location.origin + '/admin/views/dash-board';
    } else {
      redirect_url = lastUrl;
    }
    location.href = redirect_url;
  };
  const pollTask = () => {
    setTimeout(async () => {
      const res = await pollingCheck();
      console.log('polling check status', res);
      const { status, healthTime } = res;
      if (healthTime < checkPassTime) {
        // 继续检查
        pollTask();
      } else {
        // 回跳回去
        console.log('server status ok, now to redirect');
        redirectBack();
      }
    }, 5000);
  };

  return {
    checkHealth,
    pollingCheck,
    pollTask,
    fetchServerData,
    testDurningMaintenanceTime,
    handleServerError,
    handleApiError
  };
};
export default useServerHealthCheck;
