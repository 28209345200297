<template>
  <div class="flex items-center justify-center">
    <Popover
      :title="$t(`C1051`)"
      position="rb"
      trigger="click"
    >
      <span class="iconfont cursor-pointer icona-xuantingbeifen2 text-30" />
      <template #content>
        <div class="more-content">
          <div>
            <Select
              :default-value="currentLocale"
              :disabled="isTempView"
              @change="getI18n"
            >
              <Option
                v-for="(item, index) in languageList"
                :key="index"
                :value="item.code"
              >
                {{ item.nameText }}
              </Option>
            </Select>
          </div>
          <div class="mt-20">
            <Button
              type="primary"
              long
              :disabled="isTempView"
              @click="logout"
            >
              {{ $t(`C1016`) }}
            </Button>
          </div>
        </div>
      </template>
    </Popover>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import { cloneDeep } from 'lodash';
import { Popover, Select, Button, Option, Modal } from '@arco-design/web-vue';
import LANG from '@root/config/dict/lang.json';
import { userStore as useUserStore } from '@client/store/userStore';
import { clearAllCookie, clearGrayCookie } from '@client/helper/index';

import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { switchDayjsLocale } from '@client/helper/switchDayjsLocale';
import useTempView from '@client/hooks/useTempView';

const route = useRoute();
const { isTempView } = useTempView();
const userStore = useUserStore();
const { t } = useI18n();
const currentLocale = computed(() => {
  return userStore.locale;
});
const languageList = ref(cloneDeep(LANG));
const getI18n = (locale) => {
  // TODO:  切换语言后，有一些从接口加载回来的内容还是旧语言
  userStore.switchLocale(locale, /*saveToServer*/ true);
  switchDayjsLocale(locale);
};
const logout = () => {
  Modal.confirm({
    content: t('C1023'),
    title: t('C1018'),
    okText: t('C1005'),
    cancelText: t('C1006'),
    titleAlign: 'start',
    modalClass: 'coros-modal-footer-right',
    bodyClass: 'text-left',
    onOk: async () => {
      // const { email } = store.state.userData;
      // this.traceReport('LogOut', 'logout', { account: email });

      clearAllCookie();
      const { gray_keys = [] } = userStore;
      if (gray_keys.length > 0) {
        clearGrayCookie(gray_keys);
      }
      await userStore.logoutAccount();
      const lastUrl = route.fullPath;
      window.location.href = `/login?lastUrl=${encodeURIComponent(lastUrl)}`;
    }
  });
};
</script>
<style lang="scss" scoped>
@import '@client/styles/_mixins.scss';
.more-content {
  width: px2rem144(227);
}
</style>
