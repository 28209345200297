import { TEMP_TOKEN, TEMP_REGION, getSessionToken } from '@client/helper/http';
import { onBeforeMount, ref } from 'vue';

const isTempView = ref(false);

// url 参数映射
export const tmpAuthKeyMap = {
  [TEMP_TOKEN]: 'tempToken',
  [TEMP_REGION]: 'region'
};

// 进入临时视图
export function enterTempView(query) {
  const tempAuthRegion = query[tmpAuthKeyMap[TEMP_REGION]];
  const tempAuthToken = query[tmpAuthKeyMap[TEMP_TOKEN]];

  if (tempAuthRegion && tempAuthToken) {
    sessionStorage.setItem(TEMP_REGION, tempAuthRegion);
    sessionStorage.setItem(TEMP_TOKEN, tempAuthToken);

    isTempView.value = true;
  }
}

// 退出临时视图
export function leaveTempView() {
  sessionStorage.removeItem(TEMP_TOKEN);
  sessionStorage.removeItem(TEMP_REGION);

  isTempView.value = false;
}

// 获取临时视图token
export function getTempViewToken(query) {
  const tempAuthRegionInQuery = query[tmpAuthKeyMap[TEMP_REGION]];
  const tempAuthTokenInQuery = query[tmpAuthKeyMap[TEMP_TOKEN]];
  const tempAuthToken = getSessionToken() || (tempAuthRegionInQuery && tempAuthTokenInQuery ? tempAuthTokenInQuery : '');
  return tempAuthToken;
}

// 获取临时视图url参数
export const parseTempViewQueryString = () => {
  return Object.keys(tmpAuthKeyMap).reduce((acc, key) => {
    const sessionVal = sessionStorage.getItem(key);
    if (sessionVal) {
      return acc + `&${tmpAuthKeyMap[key]}=${sessionVal}`;
    }

    return acc;
  }, '');
};

const useTempView = ({ init = false } = {}) => {
  if (init) {
    onBeforeMount(() => {
      // 初始化从 sessionStorage 中获取临时视图 token
      isTempView.value = !!getSessionToken();
    });
  }

  return {
    isTempView
  };
};

export default useTempView;
