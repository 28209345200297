<template>
  <div>
    <template v-if="state.loading">
      <SkeletonCard></SkeletonCard>
    </template>
    <template v-else>
      <div
        v-for="(item, index) in state.messageList"
        :key="index"
        class="border-b border-border-1 message-list-item hover:bg-fill-5 p-12 relative"
        :class="{ read: item.isRead }"
        @click="toDetail(item, index)"
      >
        <span class="text-14 text-white leading-normal"> {{ item.titleText }} </span>
        <p class="text-14 text-text-3 my-5 line-clamp-2"> {{ item.contentText }} </p>
        <div class="flex justify-between items-center">
          <p class="text-12 text-text-3 message-list-item__createTime"> {{ item.createTimeText }} </p>
          <i
            class="iconfont iconicon_jichutubiao_shanchu1 delete-icon cursor-pointer hidden"
            @click.stop="deleteItem(item, index)"
          />
        </div>
      </div>
    </template>
    <ActivityCommentDialog ref="acDialogRef" />
    <TeamQuittingListDialog ref="tqlDialogRef" />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';
import { Message } from '@arco-design/web-vue';
import { messageStore } from '@client/store/messageStore';
import ActivityCommentDialog from './activity-comment-dialog.vue';
import TeamQuittingListDialog from './team-quitting-list-dialog.vue';
import { useRouter, useRoute } from 'vue-router';
import { teamSettingStore } from '@client/store/teamSettingStore';
import SkeletonCard from './skeleton.vue';
const router = useRouter();
const route = useRoute();
const vm = getCurrentInstance();

Message._context = vm?.appContext;

const t = vm.proxy.$root.$t;

const emit = defineEmits(['deleteItem', 'showDetail']);
const props = defineProps({
  state: {
    type: Object,
    default: () => {
      return {};
    }
  }
});

const messageStoreRef = messageStore();
const teamSettingStoreRef = teamSettingStore();

const acDialogRef = ref(null);
const tqlDialogRef = ref(null);
const state = ref(props.state);

function deleteItem(item, index) {
  messageStoreRef
    .deleteMessage({
      // 是否全量操作，0：否，1: 是
      forAll: 0,
      // ids: [item.id]
      groupIds: [item.groupId]
    })
    .then(() => {
      emit('deleteItem', item, index);
    });
}

async function toDetail(item, index) {
  let setReadPromise;
  const { userId } = item;

  // 清理未读
  if (!item.isRead) {
    setReadPromise = messageStoreRef
      .updateReadStatus({
        groupIds: [item.groupId]
      })
      .then(() => {
        item.readStatus = 1;
        item.isRead = true;
        messageStoreRef.countAll();
      });
  }

  // 消息的类型，1:运动评论，2:运动回复，3:申请加入团队，4:退出团队
  if ([1, 2].includes(item.type)) {
    if (!item?.contentObj?.labelId || !item?.contentObj?.sportState) {
      return;
    }

    // 打开运动详情评论弹窗
    acDialogRef.value.open({
      labelId: item.contentObj.labelId,
      commentId: item.dataId
    });
    return;
  }

  if (item.type === 5) {
    const collapse = 'sportNote';
    const { teamInfo = {}, sportType, userId, labelId, sportState } = item.contentObj || {};
    if (labelId && sportState && teamInfo.teamId) {
      const queryParams = new URLSearchParams();
      queryParams.append('labelId', labelId);
      queryParams.append('sportType', sportType);
      queryParams.append('collapse', collapse);
      queryParams.append('teamId', teamInfo.teamId);
      queryParams.append('userId', userId);

      return window.open(`/activity-detail?${queryParams.toString()}`);
    }

    return;
  }

  if ([3].includes(item.type)) {
    if (!item || !item.contentObj || !item.contentObj.teamInfo || !item.contentObj.teamInfo.teamId) {
      return;
    }

    // 不是团长，进行提示
    if (item.contentObj.teamInfo.groupMgr !== userId) {
      Message.error(t('E12008'));
      return;
    }

    // 若团队已经解散，进行提示
    if (item.contentObj.teamInfo.status === 0) {
      Message.error(t('N0034'));
      return;
    }

    // 跳转到团队申请页
    teamSettingStoreRef.changeListType(2);
    // 确保上面的清理未读执行完成
    await setReadPromise;
    if (route.path === '/admin/settings') {
      router.replace({
        name: 'teamSetting',
        params: {
          teamId: item.contentObj.teamInfo.teamId
        }
      });
    } else {
      router.push({
        name: 'teamSetting',
        params: {
          teamId: item.contentObj.teamInfo.teamId
        }
      });
    }
    return;
  }

  if ([4].includes(item.type)) {
    if (!item || !item.contentObj || !item.contentObj.teamInfo || !item.contentObj.teamInfo.teamId) {
      return;
    }

    // 不是团长，进行提示
    if (item.contentObj.teamInfo.groupMgr !== userId) {
      Message.error(t('E12008'));
      return;
    }

    // 若团队已经解散，进行提示
    if (item.contentObj.teamInfo.status === 0) {
      Message.error(t('N0034'));
      return;
    }

    // 打开退出团队列表弹窗
    tqlDialogRef.value.open({
      teamId: item.contentObj.teamInfo.teamId
    });
    return;
  }
}
</script>

<style lang="scss" scoped>
@import '@client/styles/_mixins.scss';
.message-list-item {
  &::before {
    content: ' ';
    position: absolute;
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f8283b;
  }
  &.read::before {
    visibility: hidden;
  }
  &:hover {
    .delete-icon {
      display: block;
    }
  }
  .message-list-item__createTime {
    height: px2rem144(28);
    line-height: px2rem144(28);
  }
}
.delete-icon:hover {
  color: #00aaff;
}
</style>
