<template>
  <div class="py-10">
    <div
      v-for="(item, index) in listNums"
      :key="index"
      class="text-white border-b border-border-1 mb-16 py-12 px-16"
    >
      <Skeleton :animation="true">
        <Space
          direction="vertical"
          :style="{ width: '100%' }"
          size="large"
        >
          <SkeletonLine
            :rows="rows"
            :line-spacing="spacing"
          />
        </Space>
      </Skeleton>
    </div>
  </div>
</template>
<script setup>
import { Skeleton, Space, SkeletonLine, SkeletonShape } from '@arco-design/web-vue';
const listNums = 3;
const props = defineProps({
  listNums: {
    type: Number,
    default: 10
  },
  rows: {
    type: Number,
    default: 3
  },
  spacing: {
    type: Number,
    default: 8
  }
});
</script>
