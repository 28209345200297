import { defineStore } from 'pinia';
import axios from '@client/helper/http';
import { messages } from '@client/api/index';

export const messageStore = defineStore('message', {
  state() {
    return {
      openStatus: false,
      // 显示在侧边未读数量
      highlightUnreadNum: 0
    };
  },
  getters: {},
  actions: {
    /**
     * 侧边栏状态
     * @param {boolean} status
     */
    updateOpenStatus(status) {
      this.openStatus = status;
    },
    toggleOpenStatus() {
      this.openStatus = !this.openStatus;
    },

    clearUnreadNum() {
      this.highlightUnreadNum = 0;
    },

    // 获取未读数量
    async countAll() {
      const res = await axios.get(`${messages.countAll}`);
      const { data } = res;
      this.highlightUnreadNum = data.count;
      return data;
    },
    // 获取整个消息列表
    fetchMessageList(data = {}) {
      return axios.post(`${messages.index}`, data).then((res) => {
        return res.data;
      });
    },

    /**
     * 删除消息
     * @param {number} data.forAll 是否全量操作，0：否，1: 是
     * @param {string[]} data.groupIds [item.groupId]
     * @returns
     */
    deleteMessage(data = {}) {
      return axios.post(`${messages.clean}`, data).then((res) => {
        // TODO: todo_xjf 验证返回成功
        if (data.forAll === 1) {
          this.clearUnreadNum();
        }
        return res.data;
      });
    },
    /**
     * 设置为已读
     * @param {number} data.forAll 是否全量操作，0：否，1: 是
     * @param {string[]} data.groupIds [item.groupId]
     * @returns
     */
    async updateReadStatus(data) {
      const res = await axios.post(`${messages.setread}`, data);
      return res.data;
    },

    // 这下面几个是否放到单独的 store 里面？
    // 获取评论列表
    async fetchLeavingmessages(params) {
      return axios.get(`${messages.leavingmessage}`, params).then((res) => {
        return res.data;
      });
    },
    // 删除评论
    async deleteReply(data) {
      const res = await axios.post(`${messages.cleanReply}`, data);
      return res.data;
    },
    async addLeavingmessage(data) {
      return axios.post(`${messages.addLeavingmessage}`, data).then((res) => {
        return res.data;
      });
    }
  }
});
