import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import REGIONCONFIG from '@root/config/dict/regionConfig.json';
import { other } from '@client/api/index';
import { profiles } from './utils/profile.config';
import { assetsRequire } from '@client/helper/asset';

export const initStore = defineStore('initStore', {
  state() {
    const __INITIAL_STATE__ = JSON.parse(window.__INITIAL_STATE__);
    const { regionId, env } = __INITIAL_STATE__;

    const config = REGIONCONFIG[regionId];
    window.__INITIAL_STATE__ = { ...__INITIAL_STATE__, ...config };
    const regCN = /^cn.*$/;
    const isCN = regCN.test(env);
    const CPL_Token = Cookies.get(config['TOKEN']);
    const CPL_Region = Cookies.get(config['CREGION']);
    return {
      ...config,
      ...__INITIAL_STATE__,
      CPL_Token,
      CPL_Region,
      isCN,
      activitySourceDataProfile: [],
      activityModeProfileList: [],
      activityStrengthProfile: {},
      activityExportFileTypes: [],
      PB_VERSION: 2, // 固定值
      theme: 'theme-dark'
    };
  },
  getters: {
    isProd() {
      return /prod/.test(this.env);
    },
    tokenName() {
      return REGIONCONFIG[this.regionId]?.TOKEN;
    },
    regionName() {
      return REGIONCONFIG[this.regionId]?.CREGION;
    },
    isLocal() {
      return this.env === 'local';
    },
    domain4cookie() {
      return this.isLocal ? location.hostname : '.coros.com';
    },
    exerciseCategoryObj() {
      return (
        this.activitySourceDataProfile
          .find((item) => item.subDataType === 20)
          ?.detailObj.reduce((acc, item) => {
            if (!acc[item.dataType]) {
              acc[item.dataType] = {};
            }
            acc[item.dataType][item.dataValue] = item;

            return acc;
          }, Object.create(null)) || {}
      );
    }
  },
  actions: {
    getPublicProfile() {
      const isProd = /^.*\.prod$/.test(this.env);
      const isGray = /^.*gray\.prod$/.test(this.env);
      let ext_name = isProd ? '_prod' : '_test';
      if(isProd&&isGray){
        ext_name='_gray_prod'
      }
      const params = {
        t: Date.now()
      };

      Promise.all(
        profiles.map((item) => {
          const { api, filename } = item;
          const url = assetsRequire(`@assets/coros-traininghub-v2/static/profile/${filename}${ext_name}.json`);
          return other[api](url, {
            params
          });
        })
      ).then(([res4activitySourceDataProfile, res4activityModeProfileList, res4activityStrengthProfile, activityExportFileTypes]) => {
        const unRefRes = (resRef) => resRef.data.value;
        this.activitySourceDataProfile = unRefRes(res4activitySourceDataProfile);
        this.activityModeProfileList = unRefRes(res4activityModeProfileList);
        this.activityStrengthProfile = unRefRes(res4activityStrengthProfile);
        this.activityExportFileTypes = unRefRes(activityExportFileTypes);
      });
    },
    getRandomThumbnail(type) {
      const allThumbnails = this.activitySourceDataProfile.find((item) => item.subDataType === type)?.detailObj;

      if (allThumbnails && allThumbnails.length > 0) {
        return allThumbnails[Math.floor(Math.random() * allThumbnails.length)];
      }
    }
  }
});
