const Team = () => import('../pages/team/index.vue');
import { TEAM } from '@client/pages/layout/components/instance';
const meta = {
  menuName: TEAM
};
export default [
  {
    name: 'team',
    path: '/admin/team',
    component: Team,
    children: [],
    meta
  }
];
