const SchedulePlan = () => import('../pages/schedule-plan/index.vue'); // 兼容老链接的router-view
const SchedulePlanShare = () => import('../pages/schedule-plan/share/index.vue');
const SchedulePlanDone = () => import('../pages/schedule-plan/done/index.vue');
const SchedulePlanAdd = () => import('../pages/schedule-plan/add/index.vue');
const SchedulePlanEdit = () => import('../pages/schedule-plan/edit/index.vue');
const SchedulePlanDoing = () => import('../pages/schedule-plan/doing/index.vue');
const WorkoutShare = () => import('../pages/workout/index.vue');
export default [
  {
    path: '/schedule-plan',
    name: 'schedulePlan',
    component: SchedulePlan,
    children: [
      {
        name: 'schedulePlanShare',
        path: '/schedule-plan/share',
        component: SchedulePlanShare
      },
      {
        name: 'schedulePlanDone',
        path: '/schedule-plan/done',
        component: SchedulePlanDone
      },
      {
        name: 'schedulePlanEdit',
        path: '/schedule-plan/edit',
        component: SchedulePlanEdit
      },
      {
        name: 'schedulePlanDoing',
        path: '/schedule-plan/doing',
        component: SchedulePlanDoing
      },
      {
        name: 'schedulePlanAdd',
        path: '/schedule-plan/add',
        component: SchedulePlanAdd
      }
    ]
  },
  {
    path: '/workout-program',
    name: 'workout',
    component: WorkoutShare
  }
];
