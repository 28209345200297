<template>
  <div class="flex items-center justify-center cursor-pointer relative">
    <div @click="openMessageBox">
      <span class="iconfont iconicon_jichutubiao_xiaoxi_wuxinxiaoxi text-30" />
      <span
        v-if="highlightUnreadNum > 0"
        class="highlight-unread-msg-count"
      >
        {{ highlightUnreadNum }}
      </span>
    </div>
    <Drawer
      class="message-container !z-100"
      :mask="false"
      popup-container="#messageParentNode"
      :visible="openStatus"
      :placement="state.position"
      unmount-on-close
      :footer="false"
    >
      <template #header>
        <div class="w-full border-b border-border-1">
          <div class="text-white text-16 font-bold header-label flex justify-start items-center border-b px-12"> {{ $t(`N0001`) }} </div>
          <!-- 条件区-->
          <div class="message-selector flex justify-between py-10 px-10">
            <div>
              <Select
                v-model="state.filterType"
                size="small"
                :style="{ width: '80px' }"
                :trigger-props="{ autoFitPopupMinWidth: true }"
                @change="changeType"
              >
                <Option
                  v-for="(item, index) in TYPEOPTIONS"
                  :key="index"
                  :value="item.value"
                >
                  {{ $t(item.text) }}
                </Option>
              </Select>
            </div>
            <div>
              <Dropdown @select="handleDropdownSelect">
                <Button size="small">
                  <template #icon>
                    <span class="iconfont iconicon_jichutubiao_gengd1uo-copy rotate-90" />
                  </template>
                </Button>
                <template #content>
                  <Doption
                    v-for="(item, index) in OPT_OPTIONS"
                    :key="index"
                    :value="item.value"
                  >
                    {{ $t(item.label) }}
                  </Doption>
                </template>
              </Dropdown>
            </div>
          </div>
        </div>
      </template>
      <List
        ref="popcontainerBody"
        class="text-white"
        :max-height="state.listHeight"
        :bottom-offset="20"
        :bordered="false"
        @reach-bottom="handleScroll"
      >
        <MessageList
          :state="state"
          @deleteItem="handleDeleteItem"
        />
        <template #scroll-loading>
          <div
            v-if="state.loadedAll[state.filterType]"
            class="text-14"
            >{{ $t('N0020') }}</div
          >
        </template>
      </List>
    </Drawer>
  </div>
</template>
<script setup>
import { Drawer, Select, Option, Button, Dropdown, Doption, List, Spin, Modal, Message } from '@arco-design/web-vue';
import { messageStore } from '@client/store/messageStore';

import MessageList from './MessageList.vue';
import { reactive, nextTick, ref, watch, getCurrentInstance, onBeforeMount, onBeforeUnmount, computed } from 'vue';
import mixin from '@client/mixins/getDateFormat';
import { onMounted } from 'vue';

const vm = getCurrentInstance();
Message._context = vm?.appContext;

const getDateFormat = mixin.methods.getDateFormat;

const messageStoreRef = messageStore();
const t = vm.proxy.$root.$t;

const TYPEOPTIONS = [
  {
    // 全部
    value: 'all',
    text: 'N0006',
    // typeList: [1, 2]
    typeList: [1, 2, 3, 4, 5]
  },
  {
    // 运动评论
    value: '1',
    text: 'N0010',
    typeList: [1, 2, 5]
  },
  {
    // 团队动态
    value: '2',
    text: 'N0029',
    typeList: [3, 4]
  }
];
const OPT_OPTIONS_ACTION = {
  DELETE: 1,
  MARK_ALL_READ: 2
};
const OPT_OPTIONS = [
  {
    label: 'N0009',
    value: OPT_OPTIONS_ACTION.DELETE
  },
  {
    label: 'N0005',
    value: OPT_OPTIONS_ACTION.MARK_ALL_READ
  }
];
const typeoptions = {};
const state = reactive({
  position: 'left',
  searchCondition: {
    // 类型,1:运动评论，2:运动回复，3:申请加入团队，4:退出团队
    // type: 'all',
    // 阅读状态,0:全部，0:未读，1:已读
    readStatus: '',
    // 查询数据的数据，第一次为0开始查
    startNo: 0,
    // 查询多少条数据，默认10条
    size: 20
  },
  filterType: 'all',
  messageList: [],
  listHeight: window.innerHeight,
  loading: false,
  loadedAll: {
    all: false,
    1: false,
    2: false
  }
});

TYPEOPTIONS.forEach((item) => {
  typeoptions[item.value] = item;
});

// TODO: todo_xjf 监听 highlightUnreadNum 变化，如果变化了并且当前打开了消息侧边栏，应该重新 fetchMessageList()
const highlightUnreadNum = computed(() => messageStoreRef.highlightUnreadNum);
const openStatus = computed(() => messageStoreRef.openStatus);

// TODO: todo_xjf fetchOption好像没有用到
function fetchMessage(fetchOption = {}) {
  let { searchCondition, filterType } = state;
  // let { activeTypeOption } = this;
  if (state.loadedAll[filterType]) return;
  if (state.loading) return; // 请求中, 拒绝其他请求
  state.loading = true;
  messageStoreRef
    .fetchMessageList({
      ...searchCondition,
      typeList: typeoptions[filterType].typeList,
      ...fetchOption
    })
    .then((res) => {
      if (res.length < state.searchCondition.size) {
        state.loadedAll[filterType] = true;
      }
      state.searchCondition.startNo += state.searchCondition.size;
      const listData = dealResData(res || []);
      state.messageList = state.messageList.concat(listData);
      state.loading = false;
    })
    .catch((err) => {
      console.error(err);
      state.loading = false;
    });
}

function changeType() {
  reset();
  fetchMessage();
}
function dealResData(data) {
  const TYPETEXT = {
    // 评论
    1: t('H1201'),
    // 回复
    2: t('H9012'),
    // 动态
    3: t('N0029'),
    // 动态
    4: t('N0029')
  };
  return data.map((item) => {
    let { type } = item;

    item.isRead = item.readStatus === 1;

    const result = { ...item };

    try {
      result.contentObj = JSON.parse(result.content);

      let { sportName = '', nickName = '', leavingMessageContent = '', teamInfo = {} } = result.contentObj;
      sportName = sportName.trim();
      result.titleText = '';
      result.contentText = '';

      // 运动评论、运动回复
      if (type == 1 || type == 2) {
        result.contentObj.sportName = sportName || t('H1071');
        result.titleText = t('N0004', {
          name1: nickName,
          name2: sportName
        });
        result.contentText = leavingMessageContent;
      }
      // 申请加入团队
      if (type == 3) {
        result.titleText = t('N0028', {
          name: teamInfo.name || ''
        });
        result.contentText = t('N0031', {
          name: nickName
        });
      }
      // 退出团队
      if (type == 4) {
        result.titleText = t('N0027', {
          name: teamInfo.name || ''
        });
        result.contentText = t('N0030', {
          name: nickName
        });
      }

      // sportNote
      if (item.type == 5) {
        const { nickName, sportName = '' } = result.contentObj;
        result.titleText = t('N0039', {
          name1: nickName,
          name2: sportName.trim() || t('H1071')
        });
      }
    } catch (e) {
      console.error(e);
    }

    result.typeText = TYPETEXT[type];
    result.createTimeText = getDateFormat(item.createTime * 1000, t);

    return result;
  });
}
function openMessageBox() {
  messageStoreRef.toggleOpenStatus();
}
function handleScroll() {
  fetchMessage();
}
function handleDropdownSelect(key) {
  if (key === OPT_OPTIONS_ACTION.DELETE) {
    deleteAll();
  } else if (key === OPT_OPTIONS_ACTION.MARK_ALL_READ) {
    //read all
    setAllRead().then(() => {
      messageStoreRef.clearUnreadNum();
      state.messageList.forEach((item) => {
        item.readStatus = 1;
        item.isRead = true;
      });
    });
  }
}
// 弹出confirm 删除所有消息
function deleteAll() {
  Modal.confirm({
    title: t('N0019'),
    content: '',
    okText: `${t('C1005')}`,
    cancelText: `${t('C1006')}`,
    onOk: () => {
      messageStoreRef
        .deleteMessage({
          forAll: 1
        })
        .then((res) => {
          handleCleanResult(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  });
}
// 全部已读
async function setAllRead() {
  const data = await messageStoreRef.countAll();
  // 数量有问题直接返回
  if (!data.teamMessageCount || data.teamMessageCount === 0) return;

  return new Promise((resolve) => {
    Modal.warning({
      title: t('N0014', { num: data.teamMessageCount }),
      content: '',
      okText: `${t('C1005')}`,
      cancelText: `${t('C1006')}`,
      onOk: async () => {
        await messageStoreRef.updateReadStatus({ forAll: 1 });
        resolve();
      }
    });
  });
}

function handleCleanResult(data) {
  // 弹出信息提示
  console.log(data);
  Message.success(`${t('N0013')}`);
  reset();
  fetchMessage();
}
function reset() {
  state.searchCondition.startNo = 0;
  state.messageList = [];
  state.loading = false;
  state.loadedAll[state.filterType] = false;
}

function handleDeleteItem(item, index) {
  state.searchCondition.startNo--;
  state.messageList.splice(index, 1);
}

// 轮询读取未读消息数量
let timer;
// function pullMessageUnreadCount() {
//   timer = setTimeout(async () => {
//     await messageStoreRef.countAll();
//     pullMessageUnreadCount();
//   }, 5000);
// }

// 低优先级接口
onMounted(() => {
  setTimeout(() => {
    messageStoreRef.countAll();
  }, 1000);
  // pullMessageUnreadCount();
});
onBeforeUnmount(() => clearTimeout(timer));

// TODO: todo_xjf 高度监听
const popcontainerBody = ref();
watch(
  () => popcontainerBody,
  () => {
    // TODO: todo_xjf
    console.log('xjf🔔');
  }
);

nextTick(() => {
  if (!openStatus.value) return;
  if (popcontainerBody.value) {
    let height = popcontainerBody.value.$el.parentNode.offsetHeight;
    if (state.listHeight !== height) state.listHeight = height;
  }
});
</script>

<style lang="scss">
@import '@client/styles/_mixins.scss';

.message-container {
  width: px2rem144(210);

  .arco-drawer {
    // 这里要用 max-width  覆盖 Drawer 组件动态生成的 style 属性
    max-width: px2rem144(210);
    @apply bg-bg-3;
    box-shadow:
      0 4px 8px 0 rgb(14 18 32 / 40%),
      0 6px 16px 0 rgb(14 18 32 / 20%),
      0 2px 4px 0 rgb(14 18 32 / 60%);
  }
  .arco-drawer-header {
    height: px2rem144(114);
    border: none;
    padding: 0;
    align-items: flex-start;
  }
  .header-label {
    height: px2rem144(56);
    @apply border-dark-60;
  }
  .arco-drawer-body {
    padding: 0;
    // @apply bg-dark-500;
  }
  .arco-scrollbar-container {
    max-height: calc(100% - 114px) !important;
    @apply rounded-none;
  }
  .arco-scrollbar {
    height: 100%;
  }
  .arco-scrollbar-track-direction-vertical {
    width: 8px;

    .arco-scrollbar-thumb.arco-scrollbar-thumb-direction-vertical {
      width: 8px;
    }
    .arco-scrollbar-thumb-bar {
      width: 5px;
    }
  }
}

.highlight-unread-msg-count {
  position: absolute;
  top: 4px;
  right: 12px;
  width: 16px;
  height: 16px;
  background: #f8283b;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  line-height: 16px;
  text-align: center;
}
</style>
