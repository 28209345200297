import LANG from '@root/config/dict/lang.json';
import dayjs from 'dayjs';
const dayjsLocales = import.meta.glob('/node_modules/dayjs/esm/locale/*.js');

export function switchDayjsLocale(currentLocale) {
  const l = LANG.find((item) => item.code === currentLocale).apiLangType;
  try {
    const localeModule = dayjsLocales[`/node_modules/dayjs/esm/locale/${l}.js`];
    if (localeModule) {
      localeModule().then((v) => {
        dayjs.locale(v.default);
      });
    }
  } catch (e) {
    console.log(`🔔 e`, e);
  }
}
