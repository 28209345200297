export const distanceM2Km = (m = 0, fix = 2) => {
  return Number((m / 1000).toFixed(fix));
};
//  米转成英里
export const distanceM2Mile = (m = 0, fix = 2) => {
  return Number((m / 1000 / 1.609344).toFixed(fix));
};
//  千米转成英里
export const distanceKM2mile = (m = 0, fix = 2, unit) => {
  if (unit === 0) {
    return Number(m.toFixed(fix));
  }
  return Number((m / 1.609344).toFixed(fix));
};

export const distanceKm2Mi = (m = 0, fix = 2) => {
  return Number((m / 1.609344).toFixed(fix));
};

//  千米转成米
export const distanceKm2m = (m = 0, fix = 2) => {
  return Number((m * 1000).toFixed(fix));
};

//  千米转成厘米
export const distanceKm2Cm = (m = 0, fix = 2) => {
  return Number((m * 1000 * 100).toFixed(fix));
};

//  英里转成千米
export const distanceMile2Km = (m = 0, fix = 2) => {
  return Number((m * 1.609344).toFixed(fix));
};

//  英里转成米
export const distanceMile2M = (m = 0, fix = 2) => {
  return Number((m * 1.609344 * 1000).toFixed(fix));
};

//  英里转成厘米
export const distanceMile2Cm = (m = 0, fix = 2) => {
  return Number((m * 1.609344 * 1000 * 100).toFixed(fix));
};

//  米转成丫的
export const distanceM2yard = (m = 0, fix = 2, unit) => {
  if (unit === 0) {
    return Number(m.toFixed(fix));
  }
  return Number((m / 0.9144).toFixed(fix));
};

//  米转成丫的
export const distanceM2Yard = (m = 0, fix = 2) => {
  return Number((m / 0.9144).toFixed(fix));
};

//  米转成英尺
export const distanceM2ft = (m = 0, fix = 2, unit) => {
  if (unit === 0) {
    return Number(m.toFixed(fix));
  }
  return Number((m / 0.3048).toFixed(fix));
};

//  英尺转成米
export const distanceFT2m = (m = 0, fix = 2) => {
  return Number((m * 0.3048).toFixed(fix));
};

//  厘米转成英尺
export const distanceCM2FT = (m = 0, fix = 2) => {
  return Number((m / 100 / 0.3048).toFixed(fix));
};

//  厘米转成英寸
export const distanceCM2inch = (m = 0, fix = 2, unit) => {
  if (unit === 0) {
    return Number(m.toFixed(fix));
  }
  return Number((m / 2.54).toFixed(fix));
};

//  英寸转成厘米
export const distanceInch2CM = (m = 0, fix = 2) => {
  return Number((m * 2.54).toFixed(fix));
};

// yard转换为mile
export const distanceYard2Mile = (m = 0, fix = 2) => {
  return Number(((m * 0.9144) / 1000 / 1.609344).toFixed(fix));
};

// yard转换为m
export const distanceYard2M = (m = 0, fix = 2, unit) => {
  if (unit === 0) {
    return Number(m.toFixed(fix));
  }
  return Number((m * 0.9144).toFixed(fix));
};

// yard转换为m
export const distanceYard2m = (m = 0, fix = 2) => {
  return Number((m * 0.9144).toFixed(fix));
};

// yard转换为cm
export const distanceYard2Cm = (m = 0, fix = 2) => {
  return Number((m * 0.9144 * 100).toFixed(fix));
};
// 将距离单位为米的数值，转换为其他距离单位的数值
export const distanceMToOther = (unitName, value, fix = 2) => {
  let result = 0;
  value = Number(value);

  switch (unitName) {
    case 'km':
      result = distanceM2Km(value, fix);
      break;
    case 'm':
      result = value;
      break;
    case 'mi':
      result = distanceM2Mile(value, fix);
      break;
    case 'yd':
      result = distanceM2Yard(value, fix);
      break;
  }
  return result;
};
// 将其他距离单位的数值，转换为单位为米的数值
export const distanceToM = (unitName, value, fix = 2) => {
  let result = 0;
  value = Number(value);

  switch (unitName) {
    case 'km':
      result = distanceKm2m(value, fix);
      break;
    case 'm':
      result = value;
      break;
    case 'mi':
      result = distanceMile2M(value, fix);
      break;
    case 'yd':
      result = distanceYard2m(value, fix);
      break;
  }
  return result;
};
