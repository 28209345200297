const Competition = () => import('../pages/competition/list/index.vue');
import { COMPETITION } from '@client/pages/layout/components/instance';
const meta = {
  menuName: COMPETITION
};
export default [
  {
    name: 'competition',
    path: '/admin/competition',
    component: Competition,
    meta,
    children: []
  }
];
