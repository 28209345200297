<template>
  <Modal
    v-model:visible="tipsModalVisible"
    title-align="start"
    :width="800"
    :footer="false"
    modal-class="tips-dialog coros-modal-dialog"
    body-class="tips-dialog-body p-0"
    @cancel="hideTipsModal"
  >
    <template #title>
      <div class="text-16 text-white font-normal">
        {{ $t(`C6034`) }}
      </div>
    </template>
    <div class="flex flex-row tips-dialog-content h-full items-stretch">
      <ul class="dialog-left pt-30 h-full overflow-y-scroll">
        <li
          v-for="(item, index) in I18N_KEYS_INFO"
          :key="index"
          class="color-fff item-title pointer py-10 truncate text-14 pl-20 cursor-pointer hover:text-primary-6"
          :class="[tipsModalActiveIndex === index ? 'active-bg text-white' : 'text-text-3']"
          @click="changeActiveIndex(index)"
        >
          {{ $t(item.title) }}
        </li>
      </ul>
      <div class="flex-item text-14 dialog-right px-[20px] pt-[20px] h-full overflow-y-scroll divide-y divide-gray-600 divide-dashed">
        <!-- 页面特殊布局展示需要禁用prettier 的配置 -->
        <div
          v-for="(item, index) in I18N_KEYS_INFO"
          :id="`tip-model-content-${index}`"
          :key="index"
          class="py-20"
          :class="[index === I18N_KEYS_INFO.length - 1 ? '' : 'pb-20']"
        >
          <div
            class="leading-normal whitespace-pre-wrap text-gray-200 line-height-[26px]"
            v-html="$t(item.content)"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { Modal } from '@arco-design/web-vue';
import { I18N_KEYS_INFO } from './config';
import { useTipsModal } from './useTipsModal';
import { watch } from 'vue';
import { nextTick } from 'vue';

const { tipsModalVisible, tipsModalActiveIndex, hideTipsModal, setTipsModalActiveIndex } = useTipsModal();

function changeActiveIndex(index) {
  if (index < 0) return;
  setTipsModalActiveIndex(index);
}

watch(tipsModalActiveIndex, (val) => nextTick(() => indexChangeEffect(val)));

function indexChangeEffect(index) {
  const idName = `tip-model-content-${index}`;

  document.getElementById(idName)?.scrollIntoView({
    // behavior: 'smooth'
  });
}
</script>

<style lang="scss">
.dialog-left {
  width: 147px;
  flex: 0 0 147px;
  background-color: rgba(206, 217, 242, 0.05);
}
.tips-dialog-body {
  height: 580px;
}
.arco-modal-wrapper .tips-dialog {
  // @apply bg-dark-500;
  .arco-icon {
    width: 24px;
    height: 24px;
    @apply text-gray-300;
  }
  .active-bg {
    background: rgba(206, 217, 242, 0.1);
  }
}

// .tips-dialog-content {
//   overflow-y: scroll;
// }
.dialog-content-border {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.15);
}
</style>
