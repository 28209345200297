export const detectUA = () => {
  const ua = window.navigator.userAgent.toLowerCase() || '';
  const isAndroid = /android/i.test(ua);
  const isIos = /iphone|ipad|ipod/i.test(ua);
  const isWechat = /micromessenger\/([\d.]+)/i.test(ua);
  const isWeibo = /(weibo).*weibo__([\d.]+)/i.test(ua);
  const isQQ = /qq\/([\d.]+)/i.test(ua);
  const isQQBrowser = /(qqbrowser)\/([\d.]+)/i.test(ua);
  const isQzone = /qzone\/.*_qz_([\d.]+)/i.test(ua);
  // 安卓 chrome 浏览器，很多 app 都是在 chrome 的 ua 上进行扩展的
  const isOriginalChrome = /chrome\/[\d.]+ Mobile Safari\/[\d.]+/i.test(ua) && isAndroid;
  // chrome for ios 和 safari 的区别仅仅是将 Version/<VersionNum> 替换成了 CriOS/<ChromeRevision>
  // ios 上很多 app 都包含 safari 标识，但它们都是以自己的 app 标识开头，而不是 Mozilla
  const isSafari = /safari\/([\d.]+)$/i.test(ua) && isIos && ua.includes('Crios') < 0 && ua.includes('Mozilla') === 0;

  return {
    isAndroid,
    isIos,
    isWechat,
    isWeibo,
    isQQ,
    isQQBrowser,
    isQzone,
    isOriginalChrome,
    isSafari
  };
};

export const getClientPlatform = () => {
  const ua = navigator.userAgent;
  const isWindowsPhone = /(?:Windows Phone)/.test(ua);
  const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
  const isAndroid = /(?:Android)/.test(ua);
  const isFireFox = /(?:Firefox)/.test(ua);
  const isPad = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua));
  const isPhone = /(?:iPhone)/.test(ua) && !isPad;
  const isPc = !isPhone && !isAndroid && !isSymbian;
  let platform = 'pc';
  if (isPad) {
    platform = 'pc';
  } else if (isAndroid || isPhone) {
    platform = 'mobile';
  } else if (isPc) {
    platform = 'pc';
  }
  return platform;
};
export const getOS = () => {
  const sUserAgent = navigator.userAgent;
  const isWin = navigator.platform == 'Win32' || navigator.platform == 'Windows';
  const isMac =
    navigator.platform == 'Mac68K' ||
    navigator.platform == 'MacPPC' ||
    navigator.platform == 'Macintosh' ||
    navigator.platform == 'MacIntel';
  if (isMac) return 'Mac';
  const isUnix = navigator.platform == 'X11' && !isWin && !isMac;
  if (isUnix) return 'Unix';
  const isLinux = String(navigator.platform).indexOf('Linux') > -1;
  if (isLinux) return 'Linux';
  if (isWin) {
    const isWin2K = sUserAgent.indexOf('Windows NT 5.0') > -1 || sUserAgent.indexOf('Windows 2000') > -1;
    if (isWin2K) return 'Win2000';
    const isWinXP = sUserAgent.indexOf('Windows NT 5.1') > -1 || sUserAgent.indexOf('Windows XP') > -1;
    if (isWinXP) return 'WinXP';
    const isWin2003 = sUserAgent.indexOf('Windows NT 5.2') > -1 || sUserAgent.indexOf('Windows 2003') > -1;
    if (isWin2003) return 'Win2003';
    const isWinVista = sUserAgent.indexOf('Windows NT 6.0') > -1 || sUserAgent.indexOf('Windows Vista') > -1;
    if (isWinVista) return 'WinVista';
    const isWin7 = sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 7') > -1;
    if (isWin7) return 'Win7';
    const isWin10 = sUserAgent.indexOf('Windows NT 10') > -1 || sUserAgent.indexOf('Windows 10') > -1;
    if (isWin10) return 'Win10';
  }
  return 'other';
};
export const getNavigatorLang = () => {
  const lang = navigator.language.toLowerCase();
  if (lang === 'zh-cn' || lang === 'zh') {
    return 'zh-CN';
  } else if (lang === 'zh-tw' || lang === 'zh-hk') {
    return 'zh-TW';
  } else if (lang === 'fr' || lang.includes('fr-')) {
    return 'fr';
  } else if (lang === 'pl' || lang.includes('pl-')) {
    return 'pl';
  } else if (lang === 'de' || lang.includes('de-')) {
    return 'de';
  } else if (lang === 'es' || lang.includes('es-')) {
    return 'es';
  } else if (lang === 'th' || lang.includes('th-')) {
    return 'th';
  } else if (lang === 'ja' || lang.includes('ja-')) {
    return 'ja';
  } else {
    return 'en';
  }
};
