<template>
  <span
    :class="[fontName, sportConfig.icon]"
    :style="{ color: sportConfig.color }"
    :data-sport="sportType"
  ></span>
</template>

<script setup>
import { ref, computed } from 'vue';
import { getSportIconBySportType, getSportIconBySportCategoryType } from '@client/helper/sport';
const fontName = ref('iconfont-sport');
const props = defineProps({
  sportType: {
    type: Number,
    default: 0
  },
  // 自定义运动需要传这个参数
  mapType: {
    type: Number,
    default: 0
  },
  // 预留, 暂未使用
  color: {
    type: String,
    default: '#ffffff'
  }
});

const sportConfig = computed(() => {
  // 组合运动使用彩色图标
  if (props.sportType === 10001) {
    fontName.value = 'iconfont-sport-color';
    return {
      icon: 'icon-multisport'
    };
  }
  fontName.value = 'iconfont-sport';
  const categoryConfig = getSportIconBySportCategoryType(props.sportType);
  if (categoryConfig) return categoryConfig;
  const config = getSportIconBySportType(props.sportType, props.mapType);
  return config || { icon: '', color: '#fff' };
});
</script>
