import { useAxios } from '@vueuse/integrations/useAxios';
import { h, ref } from 'vue';
import { Modal, Message, Button } from '@arco-design/web-vue';
import { useI18n } from 'vue-i18n';
import { team } from '@client/api/index';
import { instance } from '../../helper/http';

const availableTeams = ref([]);

const useTeams = () => {
  const { t } = useI18n();

  const getAllTeam = (filterFunc) => {
    const { execute: api4getAllTeam } = useAxios('/team/user/teamlist', { method: 'GET' }, instance, { immediate: false });
    return api4getAllTeam()
      .then(({ data }) => {
        const teamList = data.value.teamList;
        if (teamList && teamList.length === 0) {
          // this.$nuxt.$loading.finish()
        } else {
          teamList.reverse();
          availableTeams.value = filterFunc ? teamList.filter(filterFunc) : teamList;
          // this.setActiveTeam();
          // this.report();
        }

        return availableTeams.value;
      })
      .catch((err) => {
        console.log(err);
        //   this.$nuxt.$loading.finish()
      });
  };

  const checkTaskSyncStatus = (taskId, text, cb) => {
    return team
      .api4checkAddStatusFromProgramAndPlan({
        params: {
          taskId
        }
      })
      .then(({ data: { value: data } }) => {
        console.log(data);
        if (Number(data) !== 1) {
          // that.addingUser = ``;
          // that.addingName = ``;
          // that.addingTeamId = null;
          // that.addingUserId = null;
          // that.addingId = null;
          // that.trs = new Date();
          Modal.info({
            title: text,
            content: '',
            okText: t('C1005'),
            onOk: cb
          });

          return true;
        } else {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(checkTaskSyncStatus(taskId, text, cb));
            }, 1000);
          });
        }
      });
  };

  // 执行计划更新
  const updatePlan4user = (planId, teamId, groupId, userId) => {
    return team
      .api4syncTrainingPlan({
        params: {
          planId,
          teamId,
          teamGroupId: groupId,
          userId
        }
      })
      .then(({ data: { value: taskId } }) => {
        // console.log(res)
        return checkTaskSyncStatus(taskId, t(`D1023`));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 弹窗询问是否更新
  const confirmUpdatePlan4user = ({ onButton1, onButton2 }) => {
    return Modal.open({
      title: ' ',
      content: h('div', { class: 'info-modal-content' }, [
        h('div', t('R9016')),
        h('div', { class: 'mt-20 flex justify-center' }, [
          //
          h(Button, { onClick: onButton1 }, t('R1205')),
          h(Button, { type: 'primary', onClick: onButton2, class: 'ml-10' }, t('R1204'))
        ])
      ]),
      footer: false
    });
  };

  const checkIfUserInPlan = ({ planId, teamId, groupId, userId }) => {
    return team
      .api4getUsersInTrainingPlan({
        params: {
          planId,
          teamId,
          teamGroupId: groupId
        }
      })
      .then(({ data: { value: data } }) => {
        console.log(data.userIds, userId);
        return data.userIds.includes(userId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const quiteTeam = (teamId, name) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: name,
        content: t('TG1006'),
        okText: `${t('C1005')}`,
        cancelText: `${t('C1006')}`,
        type: 'warning',
        onOk: () => {
          team
            .api4quitTeam({
              data: {
                teamId
              }
            })
            .then(() => {
              Message.success(t('TD1022').toString());
              // this.$trace.traceCustomEvent('QuitTeam', '', {
              //   account_type: this.$store.state?.userData?.role,
              //   team_id: this.teamId,
              //   status: true
              // })
              resolve();
            })
            .catch((err) => {
              console.log(err);
              // this.$trace.traceCustomEvent('QuitTeam', '', {
              //   account_type: this.$store.state?.userData?.role,
              //   team_id: this.teamId,
              //   status: false
              // })
            })
        }
      });
    });
  };

  return {
    confirmUpdatePlan4user,
    updatePlan4user,
    checkIfUserInPlan,
    checkTaskSyncStatus,
    availableTeams,
    getAllTeam,
    quiteTeam
  };
};

export default useTeams;
