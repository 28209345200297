const Personal = () => import('../pages/personal/index.vue');
const Dashboard = () => import('../pages/personal/dashboard/index.vue');
const DataAnalysis = () => import('../pages/personal/data-analysis/dataAnalysis.vue');
const Activities = () => import('../pages/personal/activities/index.vue');
const Schedule = () => import('../pages/personal/schedule/index.vue');
import { DASHBOARD } from '@client/pages/layout/components/instance';
const meta = {
  menuName: DASHBOARD
};
export default [
  {
    name: 'views',
    path: '/admin/views/personal',
    redirect: '/admin/views/dash-board',
    component: Personal,
    children: [
      {
        name: 'dashboard',
        path: '/admin/views/dash-board',
        component: Dashboard,
        meta
      },
      {
        name: 'dataAnalysis',
        path: '/admin/views/data-analysis',
        component: DataAnalysis,
        meta
      },
      {
        name: 'activities',
        path: '/admin/views/activities',
        component: Activities,
        meta
      },
      {
        name: 'schedule',
        path: '/admin/views/schedule',
        component: Schedule,
        meta
      }
    ]
  }
];
