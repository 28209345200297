import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import * as corosSportConfig from '@coros/sport-config';
import Definition from '../config/definition';
import { i18n } from '@client/helper/i18n';

const climbConfig = corosSportConfig.sport.climb;

const { t } = i18n;

// 公制key
const METRIC_UNITS = Definition.METRIC_UNITS;
// 英制key
const IMPERIAL_UNITS = Definition.IMPERIAL_UNITS;
// SALT 常量
const SALT = 'EtsPYVgTCk184RxjLveofpW0BIMNn2wr';
class Unit {
  getToken() {
    return Cookies.get(process.env.TOKEN);
  }

  clearAllCookie() {
    const keys = document.cookie.match(/[^ =;]+(?==)/g);
    if (keys) {
      for (let i = keys.length; i--; ) {
        document.cookie = keys[i] + '=0;path=/;domain=coros.com;expires=' + new Date(0).toUTCString();
      }
    }
  }

  signPassword(password) {
    return `bear-${SALT}${window.btoa(password)}`;
  }

  iPadDeviceCheck() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('ipad');
  }

  // 判断语言
  language() {
    const lang = navigator.language.toLowerCase();
    if (lang === 'zh-cn' || lang === 'zh') {
      return 'zh-CN';
    } else if (lang === 'zh-tw' || lang === 'zh-hk') {
      return 'zh-TW';
    } else if (lang === 'fr' || lang.includes('fr-')) {
      return 'fr';
    } else if (lang === 'pl' || lang.includes('pl-')) {
      return 'pl';
    } else if (lang === 'de' || lang.includes('de-')) {
      return 'de';
    } else if (lang === 'es' || lang.includes('es-')) {
      return 'es';
    } else if (lang === 'th' || lang.includes('th-')) {
      return 'th';
    } else if (lang === 'ja' || lang.includes('ja-')) {
      return 'ja';
    } else {
      return 'en';
    }
  }

  // 国际通用语言key跟产品定义语言key相互切换
  langChange(sb) {
    if (sb === 'zh') {
      return 'zh_CN';
    } else if (sb === 'zh-Hant') {
      return 'zh_TW';
    } else if (sb === 'zh_CN') {
      return 'zh';
    } else if (sb === 'zh_TW') {
      return 'zh-Hant';
    } else {
      return sb;
    }
  }

  // 复制
  clone(data) {
    if (['string', 'number', 'boolean', 'function'].includes(typeof data)) {
      return data;
    }
    if ([undefined, null].includes(data)) {
      return data;
    }
    if (data instanceof RegExp) {
      return new RegExp(data);
    }
    if (data instanceof Date) {
      return new Date(data);
    }
    if (Array.isArray(data)) {
      return data.map((item) => {
        return this.clone(item);
      });
    }
    if (data instanceof Object) {
      const newObj = {};
      for (const n in data) {
        newObj[n] = this.clone(data[n]); // 对子项进行递归复制
      }
      return newObj;
    }
    return data;
  }

  // 从数组中查给的数据找对应的最近的点数据
  findVal(x, arr) {
    let k = Math.abs(x - arr[0].pos);
    for (let i = 1; i < arr.length; i++) {
      if (Math.abs(x - arr[i].pos) > k) {
        return arr[i - 1].val;
      } else {
        k = Math.abs(x - arr[i].pos);
      }
    }
    return arr[6].val;
  }

  /**
   * 仪表盘、数据分析中除运动记录和阈值配速外的有值Y轴显示规则
   * @param y
   * @param denominator
   * @returns any
   */
  yAxisCalculate(y, denominator = 10) {
    y = y.sort((a, b) => a - b);
    const last = y[y.length - 1];
    if (last === 0 || !last) return false;
    y[0] = y[0] - 10 < 0 ? 0 : y[0] - 10;
    const min = Math.floor(y[0] / denominator) * denominator;
    const max = Math.ceil(last / denominator) * denominator;
    const gap = (max - min) / 5;
    return {
      min,
      max,
      gap
    };
  }

  /**
   * 仪表盘、数据分析中运动记录和负荷强度的有值Y轴显示规则
   * @param y
   * @returns any
   */
  yBarAxisCalculate(y) {
    y = y.sort((a, b) => a - b);
    const num = Math.ceil(y[y.length - 1]);
    if (num === 0 || !num) return false;
    const arr = num.toString().split('');
    const denominator = 10 ** (arr.length - 1);
    const max = Math.ceil(num / denominator) * denominator;
    const gap = max / 5;
    return {
      max,
      gap
    };
  }

  /**
   * 计算阈值配速Y轴显示规则
   * @param y
   * @returns any
   */
  reCalculateLtsp(y) {
    const arr = y.sort((a, b) => a - b);
    const last = arr[arr.length - 1];
    if (last === 0 || !last) return false;
    let min = arr[0];
    let max = last + 30;
    // min
    let minute = Math.floor(min / 60);
    let second = min % 60;
    if (second > 30) {
      second = 30;
    } else if (second < 30) {
      second = 0;
    }
    min = minute * 60 + second;
    // max
    minute = Math.floor(max / 60);
    second = max % 60;
    if (second > 30) {
      second = 0;
      minute++;
    } else if (second < 30) {
      second = 30;
    }
    max = minute * 60 + second;
    return {
      min,
      max,
      gap: Math.abs((max - min) / 5)
    };
  }

  // 转换为完整的时间字符串
  s2hmsFullStr(second = 0, splitStr = ':') {
    second = parseInt(second);

    let ss = (second % 3600) % 60;
    let mm = Math.floor((second % 3600) / 60);
    let hh = Math.floor(second / 3600);

    ss = ss.toString().length === 1 ? `0${ss}` : ss;
    mm = mm.toString().length === 1 ? `0${mm}` : mm;
    hh = hh.toString().length === 1 ? `0${hh}` : hh;
    return `${hh}${splitStr}${mm}${splitStr}${ss}`;
  }

  // 最左侧单位的数值为0的时候，不需要补0，其余的单位需要补0
  s2hmsStr(second = 0, splitStr = ':') {
    second = parseInt(second);

    let ss = (second % 3600) % 60;
    let mm = Math.floor((second % 3600) / 60);
    const hh = Math.floor(second / 3600);

    ss = ss.toString().length === 1 ? `0${ss}` : ss;

    // 只有秒
    if (second < 60) {
      return `0${splitStr}${ss}`;
    }

    // 只有分秒
    if (second < 3600) {
      return `${mm}${splitStr}${ss}`;
    }

    // 时分秒
    mm = mm.toString().length === 1 ? `0${mm}` : mm;
    return `${hh}${splitStr}${mm}${splitStr}${ss}`;
  }

  s2ClockStr(second) {
    const timeStr = this.s2hmsFullStr(second)
      .split(':')
      .map((item) => {
        return Number(item);
      });
    const h = timeStr[0] ? `${timeStr[0]}h` : '';
    const m = timeStr[1] ? `${timeStr[1]}m` : h ? '0m' : '';
    const s = timeStr[2] ? `${timeStr[2]}s` : m ? '0s' : '';
    const last = !h && !m && !s ? '0s' : '';
    // const last = !h && !m && !s ? '--' : ''
    return `${h}${m}${s}${last}`;
  }

  //  将秒（s）转换成时分秒（hh:mm:ss）
  s2hms(s = 0) {
    const second = parseInt(s);
    let hh = Math.floor(second / 3600);
    let mm = Math.floor((second % 3600) / 60);
    let ss = second % 60;
    hh = hh === 0 ? '00' : hh > 9 ? hh : `0${hh}`;
    mm = mm === 0 ? '00' : mm > 9 ? mm : `0${mm}`;
    ss = ss === 0 ? '00' : ss > 9 ? ss : `0${ss}`;
    return `${hh}:${mm}:${ss}`;
  }

  //  将秒（s）转换成时分秒（hh:mm）
  s2hm(s = 0) {
    const second = parseInt(s);
    let hh = Math.floor(second / 3600);
    let mm = Math.floor((second % 3600) / 60);
    const ss = second % 60;
    hh = hh === 0 ? '00' : hh > 9 ? hh : `0${hh}`;
    mm = ss > 29 ? mm + 1 : mm;
    if (mm === 60) {
      hh++;
      mm = 0;
    }
    mm = mm === 0 ? '00' : mm > 9 ? mm : `0${mm}`;
    return `${hh}:${mm}`;
  }

  //  将秒（s）转换成分秒（mm'ss"）
  s2ms(s = 0) {
    const second = Math.round(parseFloat(s));
    const mm = Math.floor(second / 60);
    const ss = Math.floor(second % 60);
    return `${mm === 0 ? '00' : mm > 9 ? mm : '0' + mm}'${ss === 0 ? '00' : ss > 9 ? ss : '0' + ss}"`;
  }

  // 从日期对象中获取时分秒加起来的总秒数
  getDateSecondCount(date = new Date()) {
    const targetDate = dayjs(date);
    const h = targetDate.hour();
    const m = targetDate.minute();
    const s = targetDate.second();
    return h * 3600 + m * 60 + s;
  }

  //  将20200220字符串转化成2020-02-20，中间符号可传
  dateStrFormatAll(str = '20200220', middleStr = '-') {
    const realStr = str.toString();
    const a = realStr.split('');
    return `${a[0]}${a[1]}${a[2]}${a[3]}${middleStr}${a[4]}${a[5]}-${a[6]}${a[7]}`;
  }

  //  将20200220字符串转化成02-20，中间符号可传
  dateStrFormatHalf(str = '20200220', middleStr = '-') {
    const realStr = str.toString();
    const a = realStr.split('');
    return `${a[4]}${a[5]}${middleStr}${a[6]}${a[7]}`;
  }

  //  日期对比，返回：今天、一周内和月/日

  //  返回日期属于周几
  dateIsWhatDayInWeek(str = 20200220) {
    const num = Number(dayjs(`${str}`).format('d'));
    switch (num) {
      case 1:
        // 周一
        return t('C1039');
      case 2:
        // 周二
        return t('C1040');
      case 3:
        // 周三
        return t('C1041');
      case 4:
        // 周四
        return t('C1042');
      case 5:
        // 周五
        return t('C1043');
      case 6:
        // 周六
        return t('C1044');
      case 0:
        // 周日
        return t('C1045');
    }
  }

  /**
   * 获取相对于今天经过x天的日期对象
   * @param throughDay
   * @returns {Date}
   */
  getDateFromNow(throughDay = 0) {
    const date = new Date();
    const y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();

    m = m < 10 ? `0${m}` : m;
    d = d < 10 ? `0${d}` : d;

    const nowDate = new Date(`${y}-${m}-${d}`);
    if (typeof throughDay !== 'number') {
      return nowDate;
    }
    nowDate.setDate(throughDay);
    return nowDate;
  }

  /**
   * 根据时间戳和时区，算出相对于当前时区的时间
   * @param timestamp
   * @param timezone  为接口数据的时区使用的15分钟时区
   * @returns {Date}
   */
  getTimezoneDate(timestamp, timezone) {
    if ([undefined, ''].includes(timezone)) {
      timezone = (-new Date().getTimezoneOffset() / 60) * 4;
    }
    const thatDay = new Date();
    // 接口数据的时区使用的15分钟时区，所以要乘4
    const nowTimezone = (-thatDay.getTimezoneOffset() / 60) * 4;
    const zoneGap = (nowTimezone - timezone) * 15 * 60 * 1000;
    thatDay.setTime(timestamp - zoneGap);
    return thatDay;
  }

  /**
   * 获取相对于当前第几周的周一日期（以周一为每周的第一天）
   * @param throughWeek 经过的周数
   * @returns {Date}
   */
  getMondayFromWeek(throughWeek = 0) {
    // const nowDate = new Date('2021-03-16')
    const result = dayjs(dayjs().format('YYYY-MM-DD')).toDate();
    const day = result.getDay();

    let throughDay = 0;

    if (day === 0) {
      throughDay = 7 * throughWeek - 6;
    } else {
      throughDay = 7 * throughWeek - result.getDay() + 1;
    }

    result.setDate(result.getDate() + throughDay);
    return result;
  }

  // 获取相对于输入日期的上一个最近的周一日期对象
  getLastMonday(date) {
    const result = dayjs(dayjs(date).format('YYYY-MM-DD')).toDate();
    const day = result.getDay();

    let throughDay = 0;

    if (day === 0) {
      throughDay = -6;
    } else {
      throughDay = -result.getDay() + 1;
    }

    result.setDate(result.getDate() + throughDay);
    return result;
  }

  /**
   * 获取相对于传入的时间戳经过的天数的日期对象
   * @param timestamp
   * @param throughDay
   * @returns {Date}
   */
  getDateByThroughDay(timestamp, throughDay = 0) {
    const result = new Date();
    result.setTime(timestamp);
    result.setDate(result.getDate() + throughDay);
    return result;
  }

  // 根据传入的时间戳，获取所在月份的起始和结束日期对象
  getMonthStartAndEnd(timestamp) {
    const date = dayjs(timestamp);

    // 月份第一天
    const startDate = dayjs(date.format('YYYY-MM-DD')).toDate();
    startDate.setDate(startDate.getDate() - date.toDate().getDate() + 1);

    // 月份最后一天
    const endDate = this.clone(startDate);
    endDate.setDate(endDate.getDate() + 32);
    endDate.setDate(endDate.getDate() - endDate.getDate());

    return [startDate, endDate];
  }

  /**
   * 获取日期对应的国际化文本key（仅一周内的时间有返回key）
   * @param timestamp
   * @returns {string|*}
   */
  getDateTextI18nKey(timestamp) {
    const thatDay = new Date();
    thatDay.setTime(timestamp);

    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 1);
    maxDate = new Date(dayjs(maxDate).format('YYYY-MM-DD'));

    const minDate = new Date(maxDate);
    minDate.setDate(minDate.getDate() - 7);

    // 今天
    if (dayjs(thatDay).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
      return 'C1038';
    }

    // 一周内
    if (minDate.getTime() <= thatDay.getTime()) {
      return {
        0: 'C1045',
        1: 'C1039',
        2: 'C1040',
        3: 'C1041',
        4: 'C1042',
        5: 'C1043',
        6: 'C1044'
      }[thatDay.getDay()];
    }

    return '';
  }

  /**
   * 获取日期对应的国际化文本
   * @param timestamp
   * @returns {string|*}
   */
  getDateText(timestamp) {
    const i18nKey = this.getDateTextI18nKey(timestamp);
    return i18nKey ? `${t(i18nKey)}  ${dayjs(timestamp).format('HH:mm')}` : dayjs(timestamp).format('YYYY-MM-DD HH:mm');
  }

  weightKg2Lbs(v = 0, fix = 2) {
    return Number((v / 0.4536).toFixed(fix));
  }

  weightLbs2Kg(v = 0, fix = 2) {
    return Number((v * 0.4536).toFixed(fix));
  }

  //  0 的时候是公制，1 的时候是英制

  //  米转成千米
  distanceM2Km(m = 0, fix = 2) {
    return Number((m / 1000).toFixed(fix));
  }

  //  米转成英里
  distanceM2Mile(m = 0, fix = 2) {
    return Number((m / 1000 / 1.609344).toFixed(fix));
  }

  //  千米转成英里
  distanceKM2mile(m = 0, fix = 2, unit) {
    if (unit === 0) {
      return Number(m.toFixed(fix));
    }
    return Number((m / 1.609344).toFixed(fix));
  }

  distanceKm2Mi(m = 0, fix = 2) {
    return Number((m / 1.609344).toFixed(fix));
  }

  //  千米转成米
  distanceKm2m(m = 0, fix = 2) {
    return Number((m * 1000).toFixed(fix));
  }

  //  千米转成厘米
  distanceKm2Cm(m = 0, fix = 2) {
    return Number((m * 1000 * 100).toFixed(fix));
  }

  //  英里转成千米
  distanceMile2Km(m = 0, fix = 2) {
    return Number((m * 1.609344).toFixed(fix));
  }

  //  英里转成米
  distanceMile2M(m = 0, fix = 2) {
    return Number((m * 1.609344 * 1000).toFixed(fix));
  }

  //  英里转成厘米
  distanceMile2Cm(m = 0, fix = 2) {
    return Number((m * 1.609344 * 1000 * 100).toFixed(fix));
  }

  //  米转成丫的
  distanceM2yard(m = 0, fix = 2, unit) {
    if (unit === 0) {
      return Number(m.toFixed(fix));
    }
    return Number((m / 0.9144).toFixed(fix));
  }

  //  米转成丫的
  distanceM2Yard(m = 0, fix = 2) {
    return Number((m / 0.9144).toFixed(fix));
  }

  //  米转成英尺
  distanceM2ft(m = 0, fix = 2, unit) {
    if (unit === 0) {
      return Number(m.toFixed(fix));
    }
    
    return Number((m / 0.3048).toFixed(fix));
  }

  //  英尺转成米
  distanceFT2m(m = 0, fix = 2) {
    return Number((m * 0.3048).toFixed(fix));
  }

  // 152cm to 5'0"
  convertCmToFt(cm) {
    // Calculate total inches
    const totalInches = cm / 2.54;

    // Calculate feet and inches
    let feet = Math.floor(totalInches / 12);
    let inches = Math.round(totalInches % 12);

    // Adjust feet and inches if inches exceed 12
    if (inches === 12) {
      feet++;
      inches = '0';
    }

    // Generate the formatted output
    const formattedOutput = `${feet}'${inches < 10 ? '0' : ''}${inches}"`;

    return formattedOutput;
  }

  //  厘米转成英尺
  distanceCM2FT(m = 0, fix = 2) {
    return Number((m / 100 / 0.3048).toFixed(fix));
  }

  //  厘米转成英寸
  distanceCM2inch(m = 0, fix = 2, unit) {
    if (unit === 0) {
      return Number(m.toFixed(fix));
    }
    return Number((m / 2.54).toFixed(fix));
  }

  //  英寸转成厘米
  distanceInch2CM(m = 0, fix = 2) {
    return Number((m * 2.54).toFixed(fix));
  }

  // yard转换为mile
  distanceYard2Mile(m = 0, fix = 2) {
    return Number(((m * 0.9144) / 1000 / 1.609344).toFixed(fix));
  }

  // yard转换为m
  distanceYard2M(m = 0, fix = 2, unit) {
    if (unit === 0) {
      return Number(m.toFixed(fix));
    }
    return Number((m * 0.9144).toFixed(fix));
  }

  // yard转换为m
  distanceYard2m(m = 0, fix = 2) {
    return Number((m * 0.9144).toFixed(fix));
  }

  // yard转换为cm
  distanceYard2Cm(m = 0, fix = 2) {
    return Number((m * 0.9144 * 100).toFixed(fix));
  }

  //  千克转成lbs
  kg2lbs(m = 0, fix = 2, unit) {
    if (unit === 0) {
      return Number(m.toFixed(fix));
    }
    return Number((m / 0.4536).toFixed(fix));
  }

  //  lbs转成千克
  lbs2kg(m = 0, fix = 2) {
    return Number((m * 0.4536).toFixed(fix));
  }

  //  配速的秒/千米转换成秒/英里
  paceKm2mile(m = 0, fix = 2, unit) {
    if (unit === 0) {
      return Number(m.toFixed(fix));
    }
    return Number((m * 1.609344).toFixed(fix));
  }

  //  配速的秒/英里转换成秒/千米
  paceMI2km(m = 0, fix = 2) {
    return Number((m / 1.609344).toFixed(fix));
  }

  //  配速的秒/米转换成秒/丫的
  paceM2yard(m = 0, fix = 2, unit) {
    if (unit === 0) {
      return Number(m.toFixed(fix));
    }
    return Number((m * 0.9144).toFixed(fix));
  }

  //  配速的（s/100m）转换成（s/km）
  pace100mToKm(m = 0, fix = 2) {
    return Number((m * 10).toFixed(fix));
  }

  //  配速的（s/km）转换成（s/100m）
  paceKmTo100m(m = 0, fix = 2) {
    return Number((m / 10).toFixed(fix));
  }

  // 温度转换
  temperatureC2F(val = 0, fix = 2) {
    return Number(((val * 9) / 5 + 32).toFixed(fix));
  }

  // 获取配速或者速度转换后的数值
  getSpeedValueWithSpeedType(speedUnitType = 0, value, fix = 0, unit) {
    const that = this;
    const type = {
      0: '- -',
      1: that.distanceKM2mile(value / 100, fix, unit),
      2: value / 100,
      3: that.s2ms(that.paceKm2mile(value, 9, unit)),
      // 3: that.s2ms(that.paceKm2mile(value, fix, unit), fix, unit),
      4: that.s2ms(that.distanceYard2M(value, 9, unit)),
      5: this.s2ms(value)
    };
    unit = unit || that.$unit;
    return type[speedUnitType];
  }

  // 获取配速或者速度的单位
  getSpeedUnitText(speedUnitType = 0, unit) {
    const type = {
      0: {
        [METRIC_UNITS]: '',
        [IMPERIAL_UNITS]: ''
      },
      1: {
        [METRIC_UNITS]: 'km/h',
        [IMPERIAL_UNITS]: 'mph'
      },
      2: {
        [METRIC_UNITS]: 'kts',
        [IMPERIAL_UNITS]: 'kts'
      },
      3: {
        [METRIC_UNITS]: '/km',
        [IMPERIAL_UNITS]: '/mi'
      },
      4: {
        [METRIC_UNITS]: '/100m',
        [IMPERIAL_UNITS]: '/100yard'
      },
      5: {
        [METRIC_UNITS]: '/500m',
        [IMPERIAL_UNITS]: '/500m'
      }
    };
    return type[speedUnitType][unit];
  }

  // 获取运动配速的值（与《获取运动配速的单位》配套使用）
  getPaceValue(value = 0, sportType = 0, fix = 0, unit) {
    const sportName = Definition.getSportNameByType(sportType);

    if (['Run', 'Indoor_Run', 'Trail_Run', 'Track_Run', 'Mtn_Climb', 'Hike', 'Walk', 'GPS_Cardio', 'Custom_Sport'].includes(sportName)) {
      return {
        [METRIC_UNITS]: this.s2ms(value),
        [IMPERIAL_UNITS]: this.s2ms(this.paceKm2mile(value, fix, unit))
      }[unit];
    }

    if (['Pool_Swim', 'Open_Water'].includes(sportName)) {
      return {
        [METRIC_UNITS]: this.s2ms(value),
        [IMPERIAL_UNITS]: this.s2ms(Math.round(this.distanceYard2m(value, 4)))
        // [IMPERIAL_UNITS]: this.s2ms(this.distanceYard2M(value, fix, unit))
      }[unit];
    }

    if (['Row', 'Indoor_Row'].includes(sportName)) {
      return this.s2ms(value);
    }
    return '';
  }

  // 获取运动配速的单位（与《获取运动配速的值》配套使用）
  getPaceUnit(sportType = 0, unit) {
    const sportName = Definition.getSportNameByType(sportType);

    if (['Run', 'Indoor_Run', 'Trail_Run', 'Track_Run', 'Mtn_Climb', 'Hike', 'Walk', 'GPS_Cardio', 'Custom_Sport'].includes(sportName)) {
      return {
        [METRIC_UNITS]: '/km',
        [IMPERIAL_UNITS]: '/mi'
      }[unit];
    }

    if (['Pool_Swim', 'Open_Water'].includes(sportName)) {
      return {
        [METRIC_UNITS]: '/100m',
        [IMPERIAL_UNITS]: '/100yard'
      }[unit];
    }

    if (['Row', 'Indoor_Row'].includes(sportName)) {
      return '/500m';
    }
    return '';
  }

  // 获取运动速度的值（与《获取运动速度的单位》配套使用）
  getSpeedValue(value = 0, sportType = 0, fix = 0, unit) {
    const sportName = Definition.getSportNameByType(sportType);

    if (['Windsurfing'].includes(sportName)) {
      return Number((value / 100).toFixed(fix));
    }

    return {
      [METRIC_UNITS]: Number((value / 100).toFixed(fix)),
      [IMPERIAL_UNITS]: this.distanceKM2mile(value / 100, fix, unit)
    }[unit];
  }

  // 获取运动速度的单位（与《获取运动速度的值》配套使用）
  getSpeedUnit(sportType = 0, unit, speedUnit) {
    const sportName = Definition.getSportNameByType(sportType);

    if (['Windsurfing'].includes(sportName)) {
      if ([1].includes(speedUnit)) {
        return {
          [METRIC_UNITS]: 'km/h',
          [IMPERIAL_UNITS]: 'mph'
        }[unit];
      }
      if ([2].includes(speedUnit)) {
        return 'kts';
      }
    }

    return {
      [METRIC_UNITS]: 'km/h',
      [IMPERIAL_UNITS]: 'mph'
    }[unit];
  }

  isNoValue(value) {
    return [undefined, null, ''].includes(value);
  }

  noUserChangeDistance(value) {
    return [undefined, null, '', -1, 0].includes(value);
  }

  // 将unitType转换为可使用的0,1
  getUnitType(unitType, defaultUnitType) {
    if (defaultUnitType === undefined) console.error('defaultUnitType缺失');
    if ([0, 1].includes(unitType)) {
      return unitType;
    }

    return defaultUnitType;
  }

  // distance单位为m
  getDistanceInfo(sportType, distance, unitType) {
    const sportName = Definition.getSportNameByType(sportType);

    // 泳池游泳、公开水域
    if (['Pool_Swim', 'Open_Water'].includes(sportName)) {
      const value = {
        [METRIC_UNITS]: (value) => {
          value = value > 9999 ? (value / 1000).toFixed(2) : value.toFixed(0);
          return value;
        },
        [IMPERIAL_UNITS]: (value) => {
          const yard = this.distanceM2Yard(value, 2);
          if (yard > 9999) {
            value = this.distanceYard2Mile(yard, 2, unitType);
          } else {
            value = yard.toFixed(0);
          }
          return value;
        }
      }[unitType](distance);

      const unit = {
        [METRIC_UNITS]: (value) => {
          return value > 9999 ? 'km' : 'm';
        },
        [IMPERIAL_UNITS]: (value) => {
          const yard = this.distanceM2Yard(value, 2);
          return yard > 9999 ? 'mi' : 'yard';
        }
      }[unitType](distance);

      return {
        value,
        unit
      };
    }

    // 赛艇、划船机
    if (['Row', 'Indoor_Row'].includes(sportName)) {
      const value = distance > 9999 ? (distance / 1000).toFixed(2) : distance.toFixed(0);
      const unit = distance > 9999 ? 'km' : 'm';
      return {
        value,
        unit
      };
    }

    // 其他运动
    const unit = {
      [METRIC_UNITS]: 'km',
      [IMPERIAL_UNITS]: 'mi'
    }[unitType];

    const value = {
      [METRIC_UNITS]: (value) => {
        return this.distanceM2Km(value, 4, unitType).toFixed(2);
      },
      [IMPERIAL_UNITS]: (value) => {
        return this.distanceM2Mile(value, 4, unitType).toFixed(2);
      }
    }[unitType](distance);

    return {
      value,
      unit
    };
  }

  // 找出对象数组中与与目标值最接近的对象数据
  getNearlyData(originalData, key, value, condition) {
    condition =
      condition ||
      function () {
        return true;
      };
    const data = this.clone(originalData);
    let minGapNum = 0;
    let minGapIndex = 0;
    for (let n = 0, length = data.length; n < length; n++) {
      const xNum = data[n][key];
      if (this.isNoValue(xNum)) {
        continue;
      }
      const conditionParams = {
        value: xNum,
        item: data[n],
        index: n
      };
      if (Math.abs(xNum - value) < Math.abs(minGapNum - value) && condition(conditionParams)) {
        minGapNum = xNum;
        minGapIndex = n;
      }
    }

    return {
      index: minGapIndex,
      data: this.clone(data[minGapIndex] || {})
    };
  }

  // 使用二分法进行查找最接近的数
  getNearlyDataByBinarySearch(option) {
    const originalData = option.originalData || [];
    let startIndex = this.isNoValue(option.startIndex) ? 0 : option.startIndex;
    let endIndex = this.isNoValue(option.endIndex) ? originalData.length : option.endIndex;
    const key = option.key;
    const value = option.value;
    const condition = option.condition || null;

    if (originalData.length === 0) {
      return null;
    }

    if (endIndex - startIndex < 25) {
      const nearlyData = this.getNearlyData(originalData.slice(startIndex, endIndex), key, value, condition);
      nearlyData.index += startIndex;
      return nearlyData;
    }

    const centerIndex = Math.round((endIndex - startIndex) / 2) + startIndex;

    if (originalData[centerIndex][key] > value) {
      // 目标在左侧
      endIndex = centerIndex;
    } else if (originalData[centerIndex][key] < value) {
      // 目标在右侧
      startIndex = centerIndex;
    } else {
      // 当前就是它
      return {
        index: centerIndex,
        data: originalData[centerIndex] || {}
      };
    }
    return this.getNearlyDataByBinarySearch({
      originalData,
      startIndex,
      endIndex,
      key,
      value,
      condition
    });
  }

  // 计算储备心率的区间值
  countRHr(restHr, maxHr, ratio) {
    return (maxHr - restHr) * (ratio / 100) + restHr;
  }

  // 计算储备心率的区间百分比值
  countRHrRatio(restHr, maxHr, hr) {
    return ((hr - restHr) / (maxHr - restHr)) * 100;
  }

  // 根据类型获取心率区间类型名称
  getHrNameByHrType(hrType) {
    // 1：使用最大心率类型，2：使用储备心率类型，3: 乳酸阈心率类型
    const nameMaps = {
      1: 'maxHr',
      2: 'rhr',
      3: 'lthr'
    };
    return nameMaps[hrType] || '';
  }

  // 根据类型获取心率区间类型名称
  getHrTypeByHrName(hrName) {
    // 1：使用最大心率类型，2：使用储备心率类型，3: 乳酸阈心率类型
    const nameMaps = {
      maxHr: 1,
      rhr: 2,
      lthr: 3
    };
    return nameMaps[hrName] || 0;
  }

  // 根据类型获取心率区间类型定义
  getHrZoneOptionsByHrType(hrType) {
    const defaultHrZone = {
      maxHr: [
        {
          name: 'G3038',
          rename: 'G3010',
          color: '#1199ee',
          ratioRange: [50, 60]
        },
        {
          name: 'G3039',
          rename: 'G3011',
          color: '#00ff44',
          ratioRange: [60, 70]
        },
        {
          name: 'G3043',
          rename: 'G3012',
          color: '#ffcc00',
          ratioRange: [70, 80]
        },
        {
          name: 'G3045',
          rename: 'G3013',
          color: '#ff9900',
          ratioRange: [80, 90]
        },
        {
          name: 'G3042',
          rename: 'G3014',
          color: '#ff0055',
          ratioRange: [90, 100]
        }
      ],
      rhr: [
        {
          name: 'G3043',
          rename: 'G3010',
          color: '#1199ee',
          ratioRange: [59, 74]
        },
        {
          name: 'G3044',
          rename: 'G3011',
          color: '#00ff44',
          ratioRange: [74, 84]
        },
        {
          name: 'G3045',
          rename: 'G3012',
          color: '#ffcc00',
          ratioRange: [84, 88]
        },
        {
          name: 'G5016',
          rename: 'G3013',
          color: '#ff9900',
          ratioRange: [88, 95]
        },
        {
          name: 'G5017',
          rename: 'G3014',
          color: '#ff0055',
          ratioRange: [95, 100]
        }
      ],
      lthr: [
        {
          name: 'G3048',
          rename: 'G3010',
          color: '#1199ee',
          ratioRange: [80, 90]
        },
        {
          name: 'G3049',
          rename: 'G3011',
          color: '#00ff44',
          ratioRange: [90, 95]
        },
        {
          name: 'G3050',
          rename: 'G3012',
          color: '#ffcc00',
          ratioRange: [95, 102]
        },
        {
          name: 'G5016',
          rename: 'G3013',
          color: '#ff9900',
          ratioRange: [102, 106]
        },
        {
          name: 'G5017',
          rename: 'G3014',
          color: '#ff0055',
          ratioRange: [106, '']
        }
      ]
    };
    return defaultHrZone[this.getHrNameByHrType(hrType)] || [];
  }

  // 生产心率范围数据
  getHrZone(zoneData, hrType) {
    let hadChange = false;
    const key = this.getHrNameByHrType(hrType);
    const zone = this.getHrZoneOptionsByHrType(hrType);

    let userZone = zoneData[`${key}Zone`];
    const userHr = zoneData[key] || 0;

    const isRHr = key === 'rhr';

    if (userZone) {
      userZone = userZone.slice(0, userZone.length - 1);

      userZone.forEach((item, index) => {
        const defaultItem = zone[index];
        if (!defaultItem) {
          return;
        }
        if (item.ratio !== defaultItem.ratioRange[0]) {
          hadChange = true;
        }
      });
    }

    const hrZone = zone
      .map((item, index) => {
        const result = {
          id: index + 1,
          type: index + 1,
          name: item[hadChange ? 'rename' : 'name'],
          defaultName: item.name,
          color: item.color
        };

        const addStart = ![0, zone.length - 1].includes(index);

        if (hadChange) {
          let startRadio = userZone[index].ratio;
          const endRadio = userZone[index + 1] ? userZone[index + 1].ratio : item.ratioRange[1];

          // 数值范围
          let start = (userHr * startRadio) / 100;
          let end = (userHr * endRadio) / 100;

          if (isRHr) {
            start = this.countRHr(zoneData.rhr, zoneData.maxHr, startRadio);
            end = this.countRHr(zoneData.rhr, zoneData.maxHr, endRadio);
          }

          if (addStart) {
            start = Number(start) + 1;
            startRadio++;
          }

          result.range = [start, end];
          result.ratioRange = [startRadio, endRadio];
        } else {
          // 使用系统默认范围
          result.ratioRange = item.ratioRange;
          if (isRHr) {
            result.range = [
              this.countRHr(zoneData.rhr, zoneData.maxHr, item.ratioRange[0]),
              this.countRHr(zoneData.rhr, zoneData.maxHr, item.ratioRange[1])
            ];
          } else {
            result.range = [Math.round((userHr * item.ratioRange[0]) / 100), Math.round((userHr * item.ratioRange[1]) / 100)];
          }

          if (addStart) {
            result.range[0] = Number(result.range[0]) + 1;
            result.ratioRange[0] = Number(result.ratioRange[0]) + 1;
          }
        }

        result.range = [Math.round(result.range[0]), Math.round(result.range[1])];

        result.rangeText = `${result.range[0]}-${result.range[1]}`;
        result.ratioRangeText = `${result.ratioRange[0]}%-${result.ratioRange[1]}%`;

        if (result.range[1] === '') {
          result.rangeText = `>${result.range[0]}`;
        }
        if (result.ratioRange[1] === '') {
          result.ratioRangeText = `>${result.ratioRange[0]}%`;
        }

        return result;
      })
      .filter((item) => {
        return !!item;
      });

    return this.clone(hrZone);
  }

  // 生产配速范围数据
  getPaceZone(zoneData, ltsp) {
    const secondToStr = (second) => {
      const s = second % 60;
      const m = Math.floor(second / 60);
      return `${m}"${s}'`;
    };
    let zone = [
      {
        // 有氧耐力区
        name: 'G3060',
        color: '#ff0055',
        ratioRange: [77, 87]
      },
      {
        // 有氧动力区
        name: 'G3061',
        color: '#ff9900',
        ratioRange: [87, 94]
      },
      {
        // 乳酸阀区
        name: 'G3062',
        color: '#ffcc00',
        ratioRange: [94, 108]
      },
      // {
      //   // 超乳酸阀区
      //   name: 'G3063',
      //   color: '#00ff44',
      //   ratioRange: [100, 108]
      // },
      {
        // 无氧耐力区
        name: 'G3064',
        color: '#00ffee',
        ratioRange: [108, 118]
      },
      {
        // 无氧动力区
        name: 'G3065',
        color: '#1199ee',
        ratioRange: [118, '']
      }
    ];
    let userZone = this.clone(zoneData.ltspZone);

    if (userZone) {
      userZone = userZone.slice(0, userZone.length - 1);
    }

    zone = zone.map((item, index) => {
      const result = {
        name: item.name,
        defaultName: item.name,
        color: item.color,
        range: [0, 0],
        ratioRange: item.ratioRange,
        zoneInfo: {
          thresholdPace: 308
        },
        type: index + 1
      };
      const defaultItem = userZone && userZone[index];
      if (!defaultItem) {
        return result;
      }
      return {
        ...item,
        ...defaultItem,
        ...result
      };
    });

    if (!userZone) {
      return zone;
    }

    const result = zone.map((item, index) => {
      // 比例范围
      let startRatio = item.ratio;
      const endRatio = index === userZone.length - 1 ? '' : userZone[index + 1].ratio;

      item.type = index + 1;

      // 数值范围
      const shouldChange = ![0, userZone.length - 1].includes(index);
      let start = Math.round((ltsp * 100) / startRatio);
      const end = index === userZone.length - 1 ? '' : Math.round((ltsp * 100) / endRatio);

      if (shouldChange) {
        start = Number(start) - 1;
        startRatio = Number(startRatio) + 1;
      }

      item.range = [start, end];
      item.rangeText = `${secondToStr(start)}-${secondToStr(end)}`;
      item.ratioRange = [startRatio, endRatio];
      item.ratioRangeText = `${Math.round(startRatio)}%-${Math.round(endRatio)}%`;

      // 数值文本
      if (end === '') {
        item.rangeText = `<${secondToStr(start)}`;
      }

      if (!start && !end) {
        item.rangeText = '';
      }

      // 比例文本
      if (endRatio === '') {
        item.ratioRangeText = `>${Math.round(startRatio)}%`;
      }

      if (!startRatio && !endRatio) {
        item.ratioRangeText = '';
      }

      return item;
    });

    return this.clone(result);
  }

  // 保留多少位小数
  dealNumberPrecision(value, precision) {
    value = String(value).replace(/[^\d^.]+/g, '');
    if (!(precision >= 0)) {
      return value;
    }
    if (precision === 0) {
      value = value.replace(/\./g, '');
    } else {
      if (value.includes('.')) {
        value = value.split('.');
        value[0] = `${value[0]}.`;
        value = value.join('');
      }
      if (value[0] === '.') {
        value = value.replace(/\./g, '');
      }

      // 保留小数位
      if (value.includes('.')) {
        // precision
        value = value.split('.');
        value[1] = value[1].substr(0, precision);
        value = value.join('.');
      }
    }
    return value;
  }

  // 将字符串中的换行符替换成br标签
  dealStrLineBreakToHtmlBr(str) {
    if (typeof str !== 'string') {
      return '';
    }
    str = str.replace(/\r\n/g, '<br>');
    str = str.replace(/\n/g, '<br>');
    return str;
  }

  // 判断当前浏览器使用的过渡结束事件是什么
  whichTransitionEndEvent() {
    let t;
    const el = document.createElement('surface');
    const transitions = {
      transition: 'transitionend',
      OTransition: 'oTransitionEnd',
      MozTransition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd'
    };

    for (t in transitions) {
      if (el.style[t] !== undefined) {
        return transitions[t];
      }
    }
    return '';
  }

  xss(str) {
    str = str.replace(/&/g, '&amp;');
    str = str.replace(/</g, '&lt;');
    str = str.replace(/>/g, '&gt;');
    str = str.replace(/"/g, '&quot;');
    str = str.replace(/'/g, '&#39;');
    str = str.replace(/`/g, '&#96;');
    str = str.replace(/\//g, '&#47;');
    // str = str.replace(/\//g, '&#x2F;')
    return str;
  }

  downloadFile(url, fileName) {
    if (!url) {
      return;
    }

    // 定义文件名
    if (!fileName) {
      const num = url.lastIndexOf('/') + 1;
      fileName = url.substring(num);
      fileName = decodeURI(fileName.split('?')[0]);
    }

    // 保存文件
    const saveLink = document.createElement('a');
    document.body.appendChild(saveLink);
    saveLink.style.display = 'none';
    saveLink.href = url;
    saveLink.download = fileName;
    saveLink.click();
    document.body.removeChild(saveLink);
  }

  /**
   * @deprecated 请使用 corosSportConfig.sport.climb 里面的 getGradingSystem
   */
  // 使用难度系统type获取对应的难度系统信息
  getGradingSystem(type) {
    const systemName = climbConfig.getGradingSystemName(type);
    if (!systemName) {
      return [];
    }

    return climbConfig.GRADING_SYSTEM_DATA[systemName] || [];
  }

  /**
   * @deprecated 请使用 corosSportConfig.sport.climb 里面的 getGradingGrade
   */
  // 获取难度系统下的等级对应的公共等级信息
  getGradingGrade(system, grade) {
    const systemName = climbConfig.getGradingSystemName(system);
    if (!systemName) {
      return null;
    }

    const systemConfig = climbConfig.GRADING_SYSTEM_DATA[systemName];
    if (!systemConfig) {
      return null;
    }

    const result = systemConfig[grade];
    if (!result) {
      return null;
    }

    // result.grade = grade

    return result;
  }

  /**
   * @deprecated 请使用 corosSportConfig.sport.climb 里面的 getGradingGradeByCommonGrade
   */
  // 使用难度系统和公共难度序号获取对应的难度等级
  getGradingGradeByCommonGrade(system, commonGrade) {
    const systemName = climbConfig.getGradingSystemName(system);
    if (!systemName) {
      return null;
    }

    const systemConfig = climbConfig.gradingSystemData[systemName];
    if (!systemConfig) {
      return null;
    }

    let result = null;
    for (let n = 0, length = systemConfig.length; n < length; n++) {
      const grade = systemConfig[n];

      if (grade.commonGrade.includes(commonGrade)) {
        result = grade;
        break;
      }
    }

    return result;
  }

  // 将字符串的第一个符号变为大写
  firstUpperCase(str) {
    const arr = str.split('');
    arr[0] = arr[0].toUpperCase();
    return arr.join();
  }

  // query字符串转化成对象
  queryToObject(query = '') {
    if (typeof query !== 'string') {
      return {};
    }

    const result = {};
    query.split('&').forEach((item) => {
      const arr = item.split('=');
      if (arr.length < 3) {
        return;
      }

      const key = arr.shift();
      arr.shift();
      const value = arr.join('');

      result[key] = value;
    });

    return result;
  }

  // 将距离单位为米的数值，转换为其他距离单位的数值
  distanceMToOther(unitName, value, fix = 2) {
    let result = 0;
    value = Number(value);

    switch (unitName) {
      case 'km':
        result = this.distanceM2Km(value, fix);
        break;
      case 'm':
        result = value;
        break;
      case 'mi':
        result = this.distanceM2Mile(value, fix);
        break;
      case 'yd':
        result = this.distanceM2Yard(value, fix);
        break;
    }
    return result;
  }

  // 将其他距离单位的数值，转换为单位为米的数值
  distanceToM(unitName, value, fix = 2) {
    let result = 0;
    value = Number(value);

    switch (unitName) {
      case 'km':
        result = this.distanceKm2m(value, fix);
        break;
      case 'm':
        result = value;
        break;
      case 'mi':
        result = this.distanceMile2M(value, fix);
        break;
      case 'yd':
        result = this.distanceYard2m(value, fix);
        break;
    }
    return result;
  }

  // 将重量单位为克的数值，转换为其他重量单位的数值
  weightGToOther(unitName, value, fix = 2) {
    let result = 0;
    value = Number(value);

    switch (unitName) {
      case 'kg':
        result = Number((value / 1000).toFixed(fix));
        break;
      case 'lbs':
        result = this.weightKg2Lbs(value / 1000, fix);
        break;
    }
    return result;
  }

  // 将其他重量单位的数值，转换为重量单位为克的数值
  weightToG(unitName, value, fix = 2) {
    let result = 0;
    value = Number(value);

    switch (unitName) {
      case 'kg':
        result = Number(value);
        break;
      case 'lbs':
        result = this.weightLbs2Kg(value, fix);
        break;
    }
    return Number((result * 1000).toFixed(fix));
  }

  // 将配速单位为（秒/千米）的数值，转换为其他配速单位的数值
  paceSKmToOther(unitName, value, fix = 2) {
    let result = 0;
    value = Number(value);

    // 实际上时间单位均为秒
    switch (unitName) {
      case 'min/km':
        result = Number(value.toFixed(fix));
        break;
      case 'min/mi':
        result = this.paceKm2Mile(value, fix);
        break;
      case 's/100m':
        result = this.paceKmTo100m(value, fix);
        break;
    }
    return result;
  }

  // 将其他配速单位的数值，转换为配速单位为（秒/千米）的数值
  paceToSKm(unitName, value, fix = 2) {
    let result = 0;
    value = Number(value);

    // 实际上时间单位均为秒
    switch (unitName) {
      case 'min/km':
        result = Number(value.toFixed(fix));
        break;
      case 'min/mi':
        result = this.paceMI2km(value, fix);
        break;
      case 's/100m':
        result = this.pace100mToKm(value, fix);
        break;
    }
    return result;
  }

  // 将速度单位为（千米/小时）的数值，转换为其他速度单位的数值
  speedKmhToOther(unitName, value, fix = 2) {
    let result = 0;
    value = Number(value);

    switch (unitName) {
      case 'km/h':
        result = Number(value.toFixed(fix));
        break;
      case 'mph':
        result = this.distanceKm2Mi(value, fix);
        break;
    }
    return result;
  }

  // 将其他速度单位的数值，转换为速度单位为（千米/小时）的数值
  speedToKmh(unitName, value, fix = 2) {
    let result = 0;
    value = Number(value);

    switch (unitName) {
      case 'km/h':
        result = Number(value.toFixed(fix));
        break;
      case 'mph':
        result = this.distanceMile2Km(value, fix);
        break;
    }
    return result;
  }
}

export default new Unit();
