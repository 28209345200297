<template>
  <div class="layout flex bg-bg-1">
    <LayoutLeft class="layout-left items-stretch flex-none overflow-y-auto bg-bg-2 border-r border-border-1" />
    <div class="layout-right overflow-y-auto overflow-x-hidden flex relative w-full relative">
      <router-view />
      <!-- 消息主体 -->
      <div
        id="messageParentNode"
        class="message-parent-node"
      >
        <DrawerToggle
          hiddenWhenFold
          :openStatus="drawgerToggleShow"
          @toggle="messageStoreRef.toggleOpenStatus()"
        />
        <!-- 这里是消息 Drawer -->
      </div>
    </div>
    <!-- tipsModal 弹窗提示 -->
    <TipsModal />
  </div>
</template>

<script setup>
import LayoutLeft from './components/layoutLeft.vue';
import { userStore } from '@client/store/userStore';
import { memberDataStore as memberDataStoreRef } from '@client/store/memberDataStore';
import { messageStore } from '@client/store/messageStore';
import TipsModal from './components/tipsModal/tipsModal.vue';
// import { mapState, mapActions } from 'pinia';
import DrawerToggle from '@client/components/DrawerToggle.vue';
import { onBeforeMount, watch, nextTick } from 'vue';
import Gray from '@client/helper/gray';
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';
import { initStore } from '@client/store/initStore';
import { ref } from 'vue';
const grayInstance = new Gray();
const initStoreRef = initStore();
const route = useRoute();
const messageStoreRef = messageStore();
const userStoreRef = userStore();
const drawgerToggleShow = ref(false);
const memberDataStore = memberDataStoreRef();
watch(
  () => messageStoreRef.openStatus,
  (val) => {
    // 延迟防止过早出现按钮
    if (val) {
      setTimeout(() => {
        drawgerToggleShow.value = true;
      }, 300);
    } else {
      drawgerToggleShow.value = false;
    }
  }
);

onBeforeMount(async () => {
  // TODO: todo_xjf 这个可以考虑在更上层的 layout （目前没有）上面做 init，因为有好几个 route 是没有用当前这个 layout，比如 /apply /activity-detail 等
  await userStoreRef.initUserInfo({ saveLocale: true });
  if (userStoreRef.userId) {
    const result = await grayInstance.syncV2Gray(userStoreRef.userId);
    // 保存到store
    if (result && result.status && result.rule_id) {
      userStoreRef.gray_keys.push(result.rule_id.gray_key);
    }
  }
});

onMounted(() => {
  initStoreRef.getPublicProfile();
});
watch(
  () => route.path,
  () => {
    if (route.name !== 'team') {
      // 重置团队数据
      if (memberDataStore.teamViewMode) {
        memberDataStore.resetData();
      }
    }
    messageStoreRef.updateOpenStatus(false);
  }
);
</script>

<style lang="scss">
@import '@client/styles/_mixins.scss';
@import '@client/styles/theme-dark.css';
</style>

<style lang="scss" scoped>
@import '@client/styles/_mixins.scss';

.layout {
  width: 100%;
  height: 100%;
}
.layout-left {
  width: px2rem144(64);
  z-index: 999;
}

// Drawer 组件内部的逻辑是：如果 Drawer 出现在容器里面，
// position 从 fixed 变成 absolute，z-index 也变成
// inherit，所以在父层级加 z-index
.message-parent-node {
  z-index: 1001;
}
</style>
