<template>
  <ConfigProvider
    :locale="arcoCurrentLocale"
    :global="true"
  >
    <router-view class="app-container" />
  </ConfigProvider>
  <LogoutTempViewBen v-if="isTempView" />
</template>

<script>
const arcoUiLocales = import.meta.glob('/node_modules/@arco-design/web-vue/es/locale/lang/*.js');
import { initStore } from '@client/store/initStore';
import { userStore } from '@client/store/userStore';
import { ConfigProvider, Message } from '@arco-design/web-vue';
import { mapActions, mapState } from 'pinia';
import { switchDayjsLocale } from './helper/switchDayjsLocale';
import useTempView, { enterTempView } from '@client/hooks/useTempView';
import VConsole from 'vconsole';
import { defineAsyncComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import * as corosSportConfig from '@coros/sport-config';
import { i18n } from './helper';

const LogoutTempViewBen = defineAsyncComponent(() => import('@client/components/LogoutTempViewBtn/index.vue'));

const pathPrefix = '/node_modules/@arco-design/web-vue/es/locale/lang/';

export default {
  name: 'App',
  components: { ConfigProvider, LogoutTempViewBen },
  setup() {
    const { isTempView } = useTempView({ init: true });
    const router = useRouter();
    const route = useRoute();

    router.isReady().then(() => {
      const { debug } = route.query;
      enterTempView(route.query);

      if (debug === '1') {
        const vConsole = new VConsole();
      }
    });
    return { isTempView };
  },
  data() {
    // 初始化数据
    const initData = initStore();
    return { ...initData, arcoCurrentLocale: null };
  },
  computed: {
    ...mapState(userStore, ['currentLocale']),
    ...mapState(initStore, ['warningMessageStatus', 'warningMessage'])
  },
  watch: {
    warningMessageStatus: function (val) {
      if (!val) return;
      let content = this.$t(this.warningMessage);
      Message.warning({
        content,
        onClose: () => {
          this.updateWarningMessageStatus(false);
        }
      });
    },
    async currentLocale() {
      const arcoCurrentLocale = await this.getArcoUiLocale();
      this.arcoCurrentLocale = arcoCurrentLocale;
    }
  },
  created() {
    // 设置i8n
    this.init();
  },
  methods: {
    ...mapActions(initStore, ['updateWarningMessageStatus']),
    ...mapActions(userStore, ['initLocaleFromClientCache']),
    getArcoUiLocale() {
      let l = this.currentLocale;
      if (l === 'zh-TW') {
        l = 'zh-tw';
      }
      let uiLocaleModule = arcoUiLocales[`${pathPrefix}${l.toLowerCase()}.js`];
      if (!uiLocaleModule) {
        uiLocaleModule = arcoUiLocales[`${pathPrefix}en-us.js`];
      }
      return uiLocaleModule().then((v) => {
        return v.default;
      });
    },
    async init() {
      this.arcoCurrentLocale = await this.getArcoUiLocale();
      switchDayjsLocale(this.currentLocale);
      this.initLocaleFromClientCache();
      corosSportConfig.setUpT(i18n.t);
    }
  }
};
</script>
