import { createRouter as createVueRouter, createMemoryHistory, createWebHistory } from 'vue-router';
import Layout from '../pages/layout/index.vue';
import Outside from './outside'; // 非授权页面
import Personal from './personal';
import Team from './team';
import TeamManagement from './teamManagement';
import PersonalSetting from './personalSetting';
import Competition from './competition';
import SchedulePlan from './schedulePlan';
import Error from './error';
import Cookies from 'js-cookie';
import REGIONCONFIG from '../../../../../config/dict/regionConfig.json';
import { getTempViewToken } from '@client/hooks/useTempView';

// 注意: 初layout 与login 外, 其余均采用按需加载模式
export const createRouter = (type) => {
  const router = createVueRouter({
    history: type === 'client' ? createWebHistory() : createMemoryHistory(),
    routes: [
      {
        path: '/admin',
        name: 'index',
        meta: {
          title: '首页',
          keepAlive: true,
          requireAuth: true
        },
        component: Layout,
        redirect: '/admin/views/dash-board',
        children: [...Personal, ...Team, ...TeamManagement, ...PersonalSetting, ...Competition]
      },
      ...Outside,
      ...SchedulePlan,
      ...Error,
      {
        path: '/',
        name: 'home',
        redirect: '/admin/views/personal'
      }
    ]
  });

  let regionId;
  try {
    regionId = JSON.parse(window.__INITIAL_STATE__).regionId || '';
  } catch (error) {
    //
  }
  if (regionId) {
    router.beforeEach((to, from) => {
      // 获取客户端（本地）cookie中的token字段

      const tempAuthToken = getTempViewToken(to.query);
      const isAuthenticated = tempAuthToken || Cookies.get(REGIONCONFIG[regionId]?.['TOKEN']);

      // 这里的跳转都要用 location.href 触发刷新界面，不然 login 和其他页面的样式会互相干扰
      if (to.name === 'login') {
        if (isAuthenticated) {
          location.href = '/admin/views/dash-board';
          return;
        }
      } else {
        if (
          to.meta.requireAuth &&
          !isAuthenticated
          // ❗️ Avoid an infinite redirect
        ) {
          // redirect the user to the login page
          location.href = `/login?lastUrl=${encodeURIComponent(to.fullPath)}`;
          return;
        }
      }
    });
  }
  return router;
};
