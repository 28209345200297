import { defineStore } from 'pinia';
import { Message } from '@arco-design/web-vue';
import { i18n } from '@client/helper/i18n';

const { t } = i18n;
const EXPIRE_DURATION = 1 * 60 * 1000; //1分钟内只提示一次
export const exceptionStore = defineStore('exceptionStore', {
  state() {
    return {
      // 全局错误信息弹窗
      warningMessageStatus: false,
      warningMessageExpiredAt: '',
      warningMessage: ''
    };
  },
  actions: {
    updateWarningMessageStatus(status, message) {
      if (status) {
        let _now = Date.now();
        this.warningMessageStatus = true;
        this.warningMessageExpiredAt = _now + EXPIRE_DURATION;
        this.warningMessage = message;
        Message.error(t(message));
      } else {
        // 关闭弹窗
        this.warningMessageStatus = false;
        this.warningMessage = '';
      }
    }
  }
});
