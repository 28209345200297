import Definition from '@client/config/definition';
import { distanceM2Yard, distanceYard2Mile, distanceM2Km, distanceM2Mile } from './distance';
import { sportTypeConfigMap, customSportConfigMap, sportCategoryConfigMap, eventTagConfigMap } from '@client/config/sport';
const METRIC_UNITS = Definition.METRIC_UNITS;
// 英制key
const IMPERIAL_UNITS = Definition.IMPERIAL_UNITS;

// distance单位为m
export const getDistanceInfo = (sportType, distance, unitType) => {
  const sportName = Definition.getSportNameByType(sportType);

  // 泳池游泳、公开水域
  if (['Pool_Swim', 'Open_Water'].includes(sportName)) {
    const value = {
      [METRIC_UNITS]: (value) => {
        value = value > 9999 ? (value / 1000).toFixed(2) : value.toFixed(0);
        return value;
      },
      [IMPERIAL_UNITS]: (value) => {
        const yard = distanceM2Yard(value, 2);
        if (yard > 9999) {
          value = distanceYard2Mile(yard, 2, unitType);
        } else {
          value = yard.toFixed(0);
        }
        return value;
      }
    }[unitType](distance);

    const unit = {
      [METRIC_UNITS]: (value) => {
        return value > 9999 ? 'km' : 'm';
      },
      [IMPERIAL_UNITS]: (value) => {
        const yard = distanceM2Yard(value, 2);
        return yard > 9999 ? 'mi' : 'yard';
      }
    }[unitType](distance);

    return {
      value,
      unit
    };
  }

  // 赛艇、划船机
  if (['Row', 'Indoor_Row'].includes(sportName)) {
    const value = distance > 9999 ? (distance / 1000).toFixed(2) : distance.toFixed(0);
    const unit = distance > 9999 ? 'km' : 'm';
    return {
      value,
      unit
    };
  }

  // 其他运动
  const unit = {
    [METRIC_UNITS]: 'km',
    [IMPERIAL_UNITS]: 'mi'
  }[unitType];

  const value = {
    [METRIC_UNITS]: (value) => {
      return distanceM2Km(value, 4, unitType).toFixed(2);
    },
    [IMPERIAL_UNITS]: (value) => {
      return distanceM2Mile(value, 4, unitType).toFixed(2);
    }
  }[unitType](distance);

  return {
    value,
    unit
  };
};
export const isCustomSport = (sportType) => {
  return (sportType >= 9800 && sportType <= 9930) || sportType === 98;
};
// 根据运动类型获取运动图标
export const getSportIconBySportType = (sportType, mapType) => {
  const result = isCustomSport(sportType);
  if (!result) {
    // 普通运动
    if (sportType == 10001) {
      return {
        icon: 'iconfont-sport-color icon-multisport',
        color: '#FFFFFF'
      }
    }
    const sportTypeConfig = sportTypeConfigMap[sportType];
    return sportTypeConfig;
  } else {
    // 自定义运动
    const sportTypeConfig = customSportConfigMap[mapType];

    return sportTypeConfig;
  }
};
export const getSportIconBySportCategoryType = (sportType) => {
  return sportCategoryConfigMap[sportType];
};

export const getEventTagByType = (type) => {
  return eventTagConfigMap[type];
};
