import corosTrace from '@coros/trace-core';

const getTraceHost = (env) => {
  const hostMap = {
    dev: 'https://webtest-mapstatic.coros.com',
    prod: 'https://mapstatic.coros.com'
  };

  return hostMap[/^.*.prod$/.test(env) ? 'prod' : 'dev'];
};

export async function initTrace(app) {
  const MODE = import.meta.env.MODE;
  const reg = /(\.test|\.prod)/i;
  if (!reg.test(MODE)) return;

  const { env, _trace_ext: ext, traceTokenMask } = JSON.parse(window.__INITIAL_STATE__) || {};
  console.log(`🔔 traceTokenMask`, traceTokenMask);
  // traceTokenMask 没有正确生成
  if (!traceTokenMask) return;

  const { nonce, mask, trace_app_id } = traceTokenMask;
  if (!nonce || !mask || !trace_app_id) return;

  console.log(`🔔 corosTrace.install`);
  corosTrace.install(app, {
    request_url: `${getTraceHost(env)}/traceapi`,
    app_name: 'th-v2',
    app_id: trace_app_id,
    app_env: env,
    nonce,
    mask,
    // locale 不标准，改用 thisRegion
    // locale: thisRegion,
    ext,
    pvReportEnable: false,
    feature: {
      pv: true
    },
    maxWaitingTime: 1000,
    maxCacheLen: 2
  });
}
