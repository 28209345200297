export const I18N_KEYS_INFO = [
  {
    title: 'SQ9007',
    content: 'Y9037'
  },
  {
    title: 'Y9036',
    content: 'Y9035'
  },
  {
    title: 'SQ1320',
    content: 'Y1511'
  },
  {
    title: 'SQ1321',
    content: 'Y1512'
  },
  {
    title: 'Y9040',
    content: 'Y9041'
  },
  {
    title: 'SQ9001',
    content: 'Y1514'
  },
  {
    title: 'SQ7001',
    content: 'Y1515'
  },
  {
    title: 'Y1301',
    content: 'Y1516'
  },
  {
    title: 'SQ1322',
    content: 'Y1517'
  },
  {
    title: 'SQ4001',
    content: 'Y1518'
  },
  {
    title: 'H1101',
    content: 'Y1519'
  },
  {
    title: 'TD1065',
    content: 'R9026'
  },
  {
    title: 'Y9061',
    content: 'Y9075'
  }
];
