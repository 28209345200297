// 运动类型配置
// name --- 对应字段名称
// color --- 对应UI颜色分类
// sportType --- 对应运动类型
// icon 对应iconfont 上的font class
export const sportConfig = [
  {
    // 跑步类
    name: 'Run',
    sportType: '-100',
    color: '#F8C032',
    children: [
      {
        // 户外跑
        name: 'Run',
        icon: 'icon-outrun',
        sportType: 100,
        label: 'H1001'
      },
      {
        // 跑步机
        name: 'Indoor_Run',
        icon: 'icon-indoor_run',
        sportType: 101,
        label: 'H1002'
      },
      {
        // 越野跑
        name: 'Trail_Run',
        icon: 'icon-trailrun',
        sportType: 102,
        label: 'H1003'
      },
      {
        // 操场跑圈
        name: 'Track_Run',
        icon: 'icon-groundrun',
        sportType: 103,
        label: 'H1004'
      }
    ]
  },
  {
    // 步行类
    name: 'Walk',
    color: '#FAE13C',
    sportType: '-104',
    children: [
      {
        // 徒步
        name: 'Hike',
        icon: 'icon-hike',
        sportType: 104,
        label: 'H1005'
      },
      {
        // 健走
        name: 'Walk',
        icon: 'icon-walk',
        sportType: 900,
        label: 'H2024'
      }
    ]
  },
  {
    // 攀登类
    name: 'Climb',
    color: '#30C9CA',
    sportType: '-105',
    children: [
      {
        // 攀登, 与10003 合并
        name: 'Multi_Pitch',
        icon: 'icon-PitchClimb',
        sportType: 106,
        label: 'H2025'
      },
      {
        // 攀登
        name: 'Multi_Pitch',
        icon: 'icon-PitchClimb',
        sportType: 10003,
        label: 'H2025'
      },
      {
        // 登山
        name: 'Mtn_Climb',
        icon: 'icon-climb',
        sportType: 105,
        label: 'H1006'
      },
      {
        // 室内攀岩
        name: 'IndoorClimb',
        icon: 'icon-indoor_climb',
        sportType: 800,
        label: 'H1006'
      },
      {
        // 抱石
        name: 'Bouldering',
        icon: 'icon-bouldering_w',
        sportType: 801,
        label: 'H1006'
      }
    ]
  },
  {
    // 骑行类
    name: 'Bike',
    color: '#1CB540',
    sportType: '-200',
    children: [
      {
        // 骑行
        name: 'Bike',
        icon: 'icon-cycle',
        sportType: 200,
        label: 'H1007'
      },
      {
        // 室内骑行
        name: 'Indoor_Bike',
        icon: 'icon-indoor_bike',
        sportType: 201,
        label: 'H1008'
      },
      {
        // 头盔骑行
        name: 'Helmet_Bike',
        icon: 'icon-cycle',
        sportType: 299,
        label: 'H1072'
      },
      {
        // 砾石路骑行
        name: 'Gravel_Road_Bike',
        icon: 'icon-gravel-road-riding',
        sportType: 203,
        label: '砾石路骑行'
      },
      {
        // 山地骑行
        name: 'Mountain_Riding',
        icon: 'icon-mountain-riding',
        sportType: 204,
        label: '山地骑行'
      },
      {
        // 山地 e-bike
        name: 'Mountain_Ebike',
        icon: 'icon-mteb',
        sportType: 205,
        label: '山地 e-bike'
      },
      {
        // 公路 e-bike
        name: 'Road_Ebike',
        icon: 'icon-road-ebike',
        sportType: 202,
        label: '公路 e-bike'
      }
    ]
  },
  {
    // 游泳类
    name: 'Swim',
    color: '#3070FF',
    sportType: '-300',
    children: [
      {
        // 泳池游泳
        name: 'Pool_Swim',
        icon: 'icon-poolswim',
        sportType: 300,
        label: 'H1009'
      },
      {
        // 公开水域
        name: 'Open_Water',
        icon: 'icon-openwater',
        sportType: 301,
        label: 'H1010'
      }
    ]
  },
  {
    // 有氧运动类
    name: 'Cardio',
    color: '#D92EDA',
    sportType: '-400',
    children: [
      {
        // 室内有氧
        name: 'Gym_Cardio',
        icon: 'icon-Indoor_erobics',
        sportType: 400,
        label: 'H1013'
      },
      {
        // 户外有氧
        name: 'GPS_Cardio',
        icon: 'icon-outdoor_aerobics',
        sportType: 401,
        label: 'H1014'
      },
      {
        // 跳绳
        name: 'Jump_Rope',
        icon: 'icon-jump',
        sportType: 901,
        label: 'H9028'
      }
    ]
  },
  {
    // 健身类
    name: 'Strength',
    color: '#D92EDA',
    sportType: '-402',
    children: [
      {
        // 健身
        name: 'Strength',
        icon: 'icon-strength',
        sportType: 402,
        label: 'H1012'
      }
    ]
  },
  {
    // 滑雪类
    name: 'Ski',
    color: '#449AFA',
    sportType: '-500',
    children: [
      {
        // 雪场滑雪, 双板
        name: 'Ski',
        icon: 'icon-ski',
        sportType: 500,
        label: 'H1015'
      },
      {
        // 雪场单板
        name: 'Snowboard',
        icon: 'icon-snowboard',
        sportType: 501,
        label: 'H1016'
      },
      {
        // 越野滑雪
        name: 'XC_Ski',
        icon: 'icon-cross_ski',
        sportType: 502,
        label: 'H1017'
      },
      {
        // 登山滑雪（新）
        name: 'Ski_Touring',
        icon: 'icon-mountain_ski',
        sportType: 503,
        label: 'H1018'
      },
      {
        // 登山滑雪（旧）
        name: 'Ski_Touring_Old',
        icon: 'icon-mountain_ski',
        sportType: 10002,
        label: 'H1018'
      }
    ]
  },
  {
    // 组合运动类
    name: 'GroupSport',
    color: '#F54E4E',
    sportType: '-10000',
    children: [
      {
        // 铁三
        name: 'Triathlon',
        icon: 'icon-triathlon',
        sportType: 10000,
        label: 'H1011'
      },
      {
        // 组合运动
        name: 'Multi_Sport',
        icon: 'icon-multisport-w',
        sportType: 10001,
        label: 'H1019'
      }
    ]
  },
  {
    // 水上运动类
    name: 'Aquatics',
    color: '#3070FF',
    sportType: '-700',
    children: [
      {
        // 赛艇
        name: 'Row',
        icon: 'icon-row',
        sportType: 700,
        label: 'H1045'
      },
      {
        // 划船机
        name: 'Indoor_Row',
        icon: 'icon-indoor_row',
        sportType: 701,
        label: 'H1046'
      },
      {
        // 激流运动
        name: 'Whitewater',
        icon: 'icon-kayak',
        sportType: 702,
        label: 'H1047'
      },
      {
        // 静水运动
        name: 'Flatwater',
        icon: 'icon-sup',
        sportType: 704,
        label: 'H1048'
      },
      {
        // 帆板
        name: 'Windsurfing',
        icon: 'icon-windsurfing',
        sportType: 705,
        label: 'H1044'
      },
      {
        // 竞速帆板
        name: 'Speedsurfing',
        icon: 'icon-speedsurfing',
        sportType: 706,
        label: 'H1043'
      },
      {
        // 船钓路亚
        name: 'BoatFishingLure',
        icon: 'icon-boatFishingLure',
        sportType: 707,
        label: 'H1043'
      },
      {
        // 岸钓路亚
        name: 'ShoreFishingLure',
        icon: 'icon-shoreFishingLure',
        sportType: 708,
        label: 'H1043'
      },
      {
        // 池钓路亚
        name: 'PondFishing_Lure',
        icon: 'icon-PondFishing_Lure_w',
        sportType: 709,
        label: 'H1043'
      }
    ]
  }
];

export const customSportConfig = [
  {
    // 步行类
    name: 'Custom_Sport',
    color: '#8F52D9',
    sportType: '98',
    children: [
      {
        name: 'Custom_Star',
        id: '1',
        icon: 'icon-Custom_star'
      },
      {
        name: 'Custom_Ball',
        id: '2',
        icon: 'icon-Custom_ball'
      },
      {
        name: 'Custom_Bike',
        id: '3',
        icon: 'icon-Custom_bike'
      },
      {
        name: 'Custom_Craft',
        id: '4',
        icon: 'icon-Custom_craft'
      },
      {
        name: 'Custom_Walk',
        id: '5',
        icon: 'icon-Custom_walk'
      },
      {
        name: 'Custom_Adventure',
        id: '6',
        icon: 'icon-Custom_adventure1'
      },
      {
        name: 'Custom_Car',
        id: '7',
        icon: 'icon-Custom_car'
      },
      {
        name: 'Custom_Fitness',
        id: '8',
        icon: 'icon-Custom_fitness'
      },
      {
        name: 'Custom_Boat',
        id: '9',
        icon: 'icon-Custom_boat'
      },
      {
        name: 'Custom_Surfing',
        id: '10',
        icon: 'icon-Custom_surfing'
      },
      {
        name: 'Custom_Hill',
        id: '11',
        icon: 'icon-Custom_hill'
      },
      {
        name: 'Custom_River',
        id: '12',
        icon: 'icon-Custom_river'
      },
      {
        name: 'Custom_Forest',
        id: '13',
        icon: 'icon-Custom_forest'
      },
      {
        name: 'Custom_Tent',
        id: '14',
        icon: 'icon-Custom_tent'
      },
      {
        name: 'Custom_Photo',
        id: '15',
        icon: 'icon-Custom_photo'
      },
      {
        name: 'Custom_Like',
        id: '16',
        icon: 'icon-Custom_like'
      },
      {
        name: 'Custom_Footprint',
        id: '17',
        icon: 'icon-Custom_footprint'
      },
      {
        name: 'Custom_Snow',
        id: '18',
        icon: 'icon-Custom_snow'
      },
      {
        name: 'Custom_Fish',
        id: '19',
        icon: 'icon-Custom_fish'
      },
      {
        name: 'Custom_Highpoint',
        id: '20',
        icon: 'icon-Custom_highpoint'
      },
      {
        name: 'Custom_badminton',
        id: '21',
        icon: 'icon-Custom_badminton'
      },
      {
        name: 'Custom_tabletennis',
        id: '22',
        icon: 'icon-Custom_tabletennis'
      },
      {
        name: 'Custom_golf',
        id: '23',
        icon: 'icon-Custom_golf'
      },
      {
        name: 'Custom_equestrian',
        id: '24',
        icon: 'icon-Custom_equestrian'
      },
      {
        name: 'Custom_frisbee',
        id: '25',
        icon: 'icon-Custom_frisbee'
      },
      {
        name: 'Custom_rugby',
        id: '26',
        icon: 'icon-Custom_rugby'
      },
      {
        name: 'Custom_skateboarding',
        id: '27',
        icon: 'icon-Custom_skateboarding'
      },
      {
        name: 'Custom_skating',
        id: '28',
        icon: 'icon-Custom_skating'
      },
      {
        name: 'Custom_king',
        id: '29',
        icon: 'icon-Custom_king'
      },
      {
        name: 'Custom_gym',
        id: '30',
        icon: 'icon-Custom_gym'
      },
      {
        name: 'Custom_football',
        id: '31',
        icon: 'icon-custom_football'
      },
      {
        name: 'Custom_baseball',
        id: '32',
        icon: 'icon-custom_baseball'
      },
      {
        name: 'Custom_volleyball',
        id: '33',
        icon: 'icon-custom_volleyball'
      },
      {
        name: 'Custom_tennis',
        id: '34',
        icon: 'icon-custom_tennis'
      },
      {
        name: 'Custom_hockey',
        id: '35',
        icon: 'icon-custom_hockey'
      },
      {
        name: 'Custom_cricket',
        id: '36',
        icon: 'icon-custom_cricket'
      },
      {
        name: 'Custom_stairs',
        id: '37',
        icon: 'icon-custom_stairs'
      },
      {
        name: 'Custom_motorcycle',
        id: '38',
        icon: 'icon-custom_motorcycle'
      }
    ]
  }
];
// 运动大类key
export const sportCategoryConfig = [
  {
    name: 'run',
    icon: 'icon-outrun',
    type: 1,
    color: '#F8C032',
    label: 'H2005'
  },
  {
    name: 'bike',
    icon: 'icon-cycle',
    type: 2,
    color: '#1CB540',
    label: 'H2006'
  },
  {
    name: 'swim',
    icon: 'icon-openwater',
    type: 3,
    color: '#3070FF',
    label: 'H2007'
  },
  {
    name: 'strength',
    icon: 'icon-strength',
    type: 4,
    color: '#D92EDA',
    label: 'H2008'
  },
  {
    name: 'trailRun',
    icon: 'icon-trailrun',
    type: 5,
    color: '#F8C032',
    label: 'H1003'
  },
  {
    name: 'indoorClimb',
    icon: 'icon-indoor_climb',
    type: 6,
    color: '#30C9CA',
    label: 'H9038'
  },
  {
    name: 'bouldering',
    icon: 'icon-bouldering_w',
    type: 7,
    color: '#30C9CA',
    label: 'H9076'
  }
];
export const eventTagConfig = [
  {
    icon: 'iconfont iconlabel-other',
    color: '#FFCA28',
    type: 1,
    label: 'R5002'
  },
  {
    icon: 'iconfont iconbisaibiaoqian1',
    color: '#FFCA28',
    type: 2,
    label: 'R5003'
  },
  {
    icon: 'iconfont iconlabel-test',
    color: '#FFCA28',
    type: 3,
    label: 'R5004'
  }
];
// 生成一份sportType 配置, 用来根据sportType 查找对应的配置
export const sportTypeConfigMap = {};
sportConfig.forEach((category) => {
  const { children = [], color } = category;
  children.forEach((sportItemConfig) => {
    const { sportType } = sportItemConfig;
    sportItemConfig.color = color;
    // family 前缀
    sportItemConfig.icon = 'iconfont-sport ' + sportItemConfig.icon;
    sportTypeConfigMap[sportType] = sportItemConfig;
  });
});
// 生成一份自定义运动的sportType 配置, 用来根据sportType 和bitMapType查找对应的配置
export const customSportConfigMap = {};
customSportConfig.forEach((parent) => {
  const { children = [], color, sportType } = parent;
  children.forEach((item) => {
    const { id } = item;
    // family 前缀
    item.color = color;
    item.icon = 'iconfont-sport ' + item.icon;
    item.parentSportType = sportType;
    customSportConfigMap[id] = item;
  });
});
// 生成一份运动分类的配置
export const sportCategoryConfigMap = {};
sportCategoryConfig.forEach((item) => {
  item.icon = 'iconfont-sport ' + item.icon;
  sportCategoryConfigMap[item.type] = item;
});

// 生成一份运动分类的配置
export const eventTagConfigMap = {};

eventTagConfig.forEach((item) => {
  item.icon = 'iconfont ' + item.icon;
  eventTagConfigMap[item.type] = item;
});
