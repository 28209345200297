import { useAxios } from '@vueuse/integrations/useAxios';
import { instance } from '@client/helper/http';
import axios from 'axios';
import { assetsRequire } from '@client/helper/asset';
export const messages = {
  index: '/genericmessage/querybygroup',
  clean: '/genericmessage/delete',
  setread: '/genericmessage/setread',
  countAll: '/genericmessage/countAll',
  cleanReply: '/leavingmessage/delete',
  leavingmessage: '/leavingmessage/board/view',
  addLeavingmessage: '/leavingmessage/add',
  listwithoutread: '/leavingmessage/listwithoutread'
};
const { execute: api4passwordLogin } = useAxios('/account/login', { method: 'POST' }, instance, { immediate: false });
// 用户设置视攀等级
const { execute: api4setclimbconfig } = useAxios('/account/setclimbconfig', { method: 'POST' }, instance, { immediate: false });

export const user = {
  accountUpdate: '/account/update',
  account: '/account/query',
  logout: '/account/logout',
  api4passwordLogin,
  api4setclimbconfig
};
export const dashboard = {
  query: '/dashboard/query',
  detail: '/dashboard/detail/query',
  teamQuery: '/dashboard/team/query',
  teamDetail: '/dashboard/detail/team/query'
};
export const profile = {
  private: '/profile/private/query',
  privateSave: '/profile/private/save'
};

const { execute: api4quitTeam } = useAxios('/team/user/quit', { method: 'POST' }, instance, { immediate: false });
const { execute: api4getTrainingMix } = useAxios('/training/program/query', { method: 'POST' }, instance, { immediate: false });
const { execute: api4getTrainingPlans } = useAxios('/training/plan/query', { method: 'POST' }, instance, { immediate: false });
const { execute: api4getUsersInTrainingPlan } = useAxios('/training/plan/executinguser', { method: 'GET' }, instance, { immediate: false });
const { execute: api4deleteTrainingGroups } = useAxios('/team/group/delete', { method: 'POST' }, instance, { immediate: false });
const { execute: api4updateTrainingGroup } = useAxios('/team/group/update', { method: 'POST' }, instance, { immediate: false });
const { execute: api4createTrainingGroup } = useAxios('/team/group/add', { method: 'POST' }, instance, { immediate: false });
const { execute: api4addUsers2TrainingGroup } = useAxios('/team/group/adduser', { method: 'POST' }, instance, { immediate: false });
const { execute: api4removeUsersFromTrainingGroup } = useAxios('/team/group/deleteuser', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4addTrainingProgram2group } = useAxios('/training/schedule/teamexecuteprogram', { method: 'POST' }, instance, {
  immediate: false
});

const { execute: api4checkAddStatusFromProgramAndPlan } = useAxios('/training/plan/syncstatus', { method: 'GET' }, instance, {
  immediate: false
});
const { execute: api4syncTrainingPlan } = useAxios('/training/plan/sync', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4GetTeamUserDetail } = useAxios('/team/user/detail', { method: 'GET' }, instance, {
  immediate: false
});
const { execute: api4GetTeamData } = useAxios('/team/user/data', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4getTeamUserInfo } = useAxios('/team/user/info', { method: 'GET' }, instance, { immediate: false });
const { execute: api4getUsersInTeam } = useAxios('/team/user/search', { method: 'POST' }, instance, { immediate: false });
export const team = {
  api4quitTeam,
  api4getTrainingMix,
  api4getTrainingPlans,
  api4getUsersInTrainingPlan,
  api4deleteTrainingGroups,
  api4updateTrainingGroup,
  api4createTrainingGroup,
  api4addUsers2TrainingGroup,
  api4removeUsersFromTrainingGroup,
  api4addTrainingProgram2group,
  api4checkAddStatusFromProgramAndPlan,
  api4syncTrainingPlan,
  api4GetTeamUserDetail,
  api4GetTeamData,
  api4getTeamUserInfo,
  api4getUsersInTeam,
  update: '/team/update',
  save: '/team/save',
  approve: '/team/approve',
  info: '/team/info',
  userTeamlist: '/team/user/teamlist',
  groupQuery: '/team/group/query',
  userSearch: '/team/user/search'
};

// url 从 initStore 传过来
const { execute: api4getActivitySourceDataProfile } = useAxios(
  '',
  { method: 'GET' },
  axios,
  {
    immediate: false
  }
);
const { execute: api4getActivityModeProfileList } = useAxios(
  '',
  { method: 'GET' },
  axios,
  {
    immediate: false
  }
);
const { execute: api4getActivityStrengthProfile } = useAxios(
  '',
  { method: 'GET' },
  axios,
  {
    immediate: false
  }
);
const { execute: api4getActivityExportFileTypes } = useAxios(
  '',
  { method: 'GET' },
  axios,
  {
    immediate: false
  }
);

export const other = {
  api4getActivitySourceDataProfile,
  api4getActivityModeProfileList,
  api4getActivityStrengthProfile,
  api4getActivityExportFileTypes
};

const { execute: api4addStrengthExercise } = useAxios('/training/exercise/add', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4updateStrengthExercise } = useAxios('/training/exercise/update', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4deleteStrengthExercise } = useAxios('/training/exercise/delete', { method: 'POST' }, instance, {
  immediate: false
});
export const strengthExercise = {
  api4addStrengthExercise,
  api4updateStrengthExercise,
  api4deleteStrengthExercise
};

export const activities = {
  category: '/profile/public/query',
  query: '/activity/query',
  team: '/activity/team/query',
  update: '/activity/update',
  getImportSportList: '/activity/fit/getImportSportList',
  deleteSportImport: '/activity/fit/deleteSportImport'
};
const { execute: api4ActivityDownload } = useAxios('/activity/detail/download', { method: 'POST' }, instance, {
  immediate: false
});
export const activity = {
  api4ActivityDownload
};
const { execute: api4addTrainingPlan2user } = useAxios('/training/schedule/teamexecuteplan', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4deleteTrainingProgram } = useAxios('/training/program/delete', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4CalculateTrainingProgram } = useAxios('/training/program/calculate', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4copyTrainingProgram2currentUser } = useAxios('/training/program/copy', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4DeletePlan } = useAxios('/training/plan/delete', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4copyPlan } = useAxios('/training/plan/copy', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4UpdateSchedule } = useAxios('/training/schedule/update', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4CopyWeek } = useAxios('/training/schedule/copyWeek', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4DeleteWeek } = useAxios('/training/schedule/deleteWeek', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4QuitTrainingPlan } = useAxios('/training/schedule/quitSubPlan', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4ProgramEstimate } = useAxios('/training/program/estimate', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4ExerciseQuery } = useAxios('/training/exercise/query', { method: 'GET' }, instance, {
  immediate: false
});
export const training = {
  plan: '/training/plan/query',
  addPlan: '/training/plan/add',
  updatePlan: '/training/plan/update',
  copyPlan: '/training/plan/copy',
  schedule: 'training/schedule/query',
  addProgram: '/training/program/add',
  updateProgram: '/training/program/update',
  querysum: '/training/schedule/querysum',
  update: '/training/schedule/update',
  detail: '/training/program/detail',
  import: '/activity/fit/import',
  planDetail: '/training/plan/detail',
  executeSubPlan: '/training/schedule/executeSubPlan',
  api4copyPlan,
  api4DeletePlan,
  api4addTrainingPlan2user,
  api4copyTrainingProgram2currentUser,
  api4deleteTrainingProgram,
  api4UpdateSchedule,
  api4CalculateTrainingProgram,
  api4QuitTrainingPlan,
  api4DeleteWeek,
  api4CopyWeek,
  api4ProgramEstimate,
  api4ExerciseQuery
};

const { execute: api4getCompetions } = useAxios('/team/competition/query', { method: 'GET' }, instance, {
  immediate: false
});
const { execute: api4importTrack } = useAxios('/team/competition/importTrack', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4getTrack } = useAxios('/team/competition/getTrack', { method: 'GET' }, instance, {
  immediate: false
});
const { execute: api4removeCompetition } = useAxios('/team/competition/delete', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4updateCompetition } = useAxios('/team/competition/update', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4addCompetition } = useAxios('/team/competition/add', { method: 'POST' }, instance, {
  immediate: false
});
const { execute: api4updateUserInfo } = useAxios('/team/competition/userdata/update', { method: 'POST' }, instance, {
  immediate: false
});
// 赛事直播-路线
const { execute: api4getLiveTrack } = useAxios('/team/competition/getTrack/share', { method: 'GET' }, instance, {
  immediate: false
});
const { execute: api4getLiveRealtimeboard } = useAxios('/team/competition/realtimeboard/share', { method: 'POST' }, instance, {
  immediate: false
});

export const competitions = {
  api4getCompetions,
  api4importTrack,
  api4removeCompetition,
  api4updateCompetition,
  api4addCompetition,
  api4getTrack,
  api4updateUserInfo,
  api4getLiveTrack,
  api4getLiveRealtimeboard,
  realtimeboard: '/team/competition/realtimeboard',
  liveRealtimeboard: '/team/competition/realtimeboard/share'
};