export const TEAM_MANAGEMENT = 'teamManagement';
export const COMPETITION = 'competition';
export const DASHBOARD = 'dashboard';
export const TEAM = 'team';
export const TABS = [
  {
    name: DASHBOARD,
    icon: 'iconxuanzhong',
    type: 'icon'
  },
  {
    name: TEAM,
    icon: 'iconxuanzhong1',
    type: 'icon'
  },
  {
    name: TEAM_MANAGEMENT,
    icon: 'icona-icondaohangtuanduishezhi',
    type: 'icon',
    hideWhenInit: true
  },
  {
    name: COMPETITION,
    icon: 'icongesai',
    type: 'icon',
    hideWhenInit: true
  }
];
