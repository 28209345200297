<template>
  <div
    class="close-btn cursor-pointer"
    :class="cls"
    @click="$emit('toggle')"
  >
    <img
      :src="props.openStatus ? COLLAPSE_BTN : OPEN_BTN"
      :style="{
        transform: foldDirection === 'right' ? 'rotate(180deg)' : ''
      }"
    />
  </div>
</template>

<script setup>
// Drawer 的那个收起按钮
import { assetsRequire } from '@client/helper/asset';
import { computed } from 'vue';
const COLLAPSE_BTN = assetsRequire('@assets/coros-traininghub-v2/images/icon/pull.png');
const OPEN_BTN = assetsRequire('@assets/coros-traininghub-v2/images/icon/push.png');
const props = defineProps({
  openStatus: { type: Boolean, default: false },
  hiddenWhenFold: { type: Boolean, default: false },
  useTransition: { type: Boolean, default: false },
  foldDirection: { type: String, default: 'left' },
  fixedWidth: { type: Boolean, default: false }
});

const cls = computed(() => {
  return {
    'open-status': props.openStatus,
    'folder-status': !props.openStatus,
    'hidden-status': !props.openStatus && props.hiddenWhenFold,
    'left-direction': props.foldDirection === 'left',
    'right-direction': props.foldDirection === 'right',
    'transition-all': props.useTransition,
    'duration-150': props.useTransition,
    'ease-in-out': props.useTransition,
    'fixed-width': props.fixedWidth
  };
});
</script>

<style lang="scss" scoped>
@import '@client/styles/_mixins.scss';
.close-btn {
  position: absolute;
  top: 50%;
  width: 8px;
  height: 80px;
  transform: translateY(-50%);
  z-index: 102;
}
.folder-status {
  &.right-direction {
    right: 0;
  }
  &.left-direction {
    left: 0;
  }

  &.hidden-status {
    &.right-direction {
      right: 0;
      opacity: 0;
    }
    &.left-direction {
      left: -10px;
      opacity: 0;
    }
  }
}

.open-status {
  &.left-direction {
    left: px2rem144(210);
    &.fixed-width {
      left: 210px;
    }
  }
  &.right-direction {
    right: px2rem144(340);
    &.fixed-width {
      right: 340px;
    }
  }
}
</style>
