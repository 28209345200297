import { getImageAssetsFile } from '@client/helper/asset';

// 运动大类key
const sportCategoryName = {
  1: 'run', //跑步类
  2: 'bike', //骑行类
  3: 'swim', //游泳类
  4: 'strength', //力量类
  5: 'trailRun', //越野跑
  6: 'indoorClimb', //室内攀岩
  7: 'bouldering' //抱石
};
// 运动大类定义, 已废弃
const sportCategory = {
  run: {
    i18n: 'H2005',
    sportIcon: getImageAssetsFile('sport/Running.svg'),
    sportIconWhite: getImageAssetsFile('sport/Running-w.svg'),
    sportIconGray: getImageAssetsFile('sport/Running-g.svg'),
    exerciseTypes: ['warmup', 'train', 'relax'],
    sportType: 'icon-outrun'
  },
  trailRun: {
    i18n: 'H1003',
    sportIcon: getImageAssetsFile('sport/Trail_Run.svg'),
    sportIconWhite: getImageAssetsFile('sport/Trail_Run-w.svg'),
    sportIconGray: getImageAssetsFile('sport/Trail_Run-g.svg'),
    exerciseTypes: ['warmup', 'train', 'relax'],
    sportType: 'icon-trailrun'
  },
  bike: {
    i18n: 'H2006',
    sportIcon: getImageAssetsFile('sport/Bike.svg'),
    sportIconWhite: getImageAssetsFile('sport/Bike-w.svg'),
    sportIconGray: getImageAssetsFile('sport/Bike-g.svg'),
    exerciseTypes: ['warmup', 'train', 'relax'],
    sportType: 'icon-cycle'
  },
  swim: {
    i18n: 'H2007',
    sportIcon: getImageAssetsFile('sport/Pool_Swim.svg'),
    sportIconWhite: getImageAssetsFile('sport/Pool_Swim-w.svg'),
    sportIconGray: getImageAssetsFile('sport/Pool_Swim-g.svg'),
    exerciseTypes: ['warmup', 'train', 'relax'],
    sportType: 'icon-openwater'
  },
  strength: {
    i18n: 'H2008',
    sportIcon: getImageAssetsFile('sport/Strength.svg'),
    sportIconWhite: getImageAssetsFile('sport/Strength-w.svg'),
    sportIconGray: getImageAssetsFile('sport/Strength-g.svg'),
    exerciseTypes: ['warmup', 'train', 'relax', 'rest'],
    sportType: 'icon-strength'
  },
  indoorClimb: {
    i18n: 'H9038',
    sportIcon: getImageAssetsFile('sport/Strength.svg'),
    sportIconWhite: getImageAssetsFile('sport/Strength-w.svg'),
    sportIconGray: getImageAssetsFile('sport/Strength-g.svg'),
    exerciseTypes: ['warmup', 'train', 'relax', 'rest'],
    sportType: 'icon-indoor_climb'
  },
  bouldering: {
    i18n: 'H9076',
    sportIcon: getImageAssetsFile('sport/Strength.svg'),
    sportIconWhite: getImageAssetsFile('sport/Strength-w.svg'),
    sportIconGray: getImageAssetsFile('sport/Strength-g.svg'),
    exerciseTypes: ['warmup', 'train', 'relax', 'rest'],
    sportType: 'icon-bouldering_w'
  }
};
// 目标类型key
const targetTypeName = {
  0: 'notSet',
  1: 'manualEnd',
  2: 'time',
  3: 'count',
  4: 'heart',
  5: 'distance',
  6: 'load',
  7: 'heartRateRecovery',
  8: 'cumulativeClimb', //累计爬升 cm
  9: 'routes' //线路
};
// 目标类型
const targetType = {
  notSet: {
    i18n: 'R6008'
  },
  manualEnd: {
    i18n: '手动结束'
  },
  time: {
    i18n: '时间'
  },
  count: {
    i18n: '次数'
  },
  heart: {
    i18n: '心率'
  },
  distance: {
    i18n: '距离'
  },
  load: {
    i18n: '负荷'
  },
  heartRateRecovery: {
    i18n: '心率恢复'
  },
  cumulativeClimb: {
    i18n: '累计上升'
  },
  routes: {
    i18n: '线路'
  }
};
// 目标单位类型key
const targetUnitName = {
  1: 'km',
  2: 'm',
  3: 'mi',
  4: 'yd',
  5: 'ft'
};
// 强度类型key
const intensityTypeName = {
  0: 'notSet',
  1: 'weight',
  2: 'heart',
  3: 'pace',
  4: 'speed',
  5: 'swimmingStyle',
  6: 'power',
  7: 'cadence',
  8: 'adjustedPace',
  9: 'ftp',
  10: 'gradeSystem'
};
// 强度类型
// const intensityType = {
//   notSet: {
//     i18n: '未定义'
//   },
//   weight: {
//     i18n: '重量'
//   },
//   heart: {
//     i18n: '心率'
//   },
//   pace: {
//     i18n: '配速'
//   },
//   speed: {
//     i18n: '速度'
//   },
//   swimmingStyle: {
//     i18n: '泳姿'
//   },
//   power: {
//     i18n: '功率'
//   },
//   // 跑步是步频，骑行是踏频）
//   cadence: {
//     i18n: '步频'
//   }
// };
// 强度单位类型key
const intensityUnitName = {
  1: 'min/km',
  2: 'min/mi',
  3: 's/100m',
  4: 'km/h',
  5: 'mph',
  6: 'kg',
  7: 'lbs'
};
// 休息类型key定义
const restTypeName = {
  // 手动结束
  0: 'manualEnd',
  // 时间
  1: 'time',
  // 心率恢复
  2: 'heart',
  // 不休息
  3: 'noRest',
  // 距离
  4: 'distance'
};
// 训练支持的休息类型
const restType = {
  manualEnd: {
    i18n: '手动结束'
  },
  time: {
    i18n: '时间'
  },
  heart: {
    i18n: '心率恢复'
  },
  noRest: {
    i18n: '不休息'
  },
  distance: {
    i18n: '距离'
  }
};
// 动作类型key定义
const exerciseTypeName = {
  // 热身
  1: 'warmup',
  // 训练
  2: 'train',
  // 放松
  3: 'relax',
  // 休息
  4: 'rest'
};
// 动作类型
const exerciseTypeOptions = {
  warmup: {
    i18n: 'T1120',
    color: '#FFA400'
  },
  train: {
    i18n: 'T1121',
    color: '#20CD61'
  },
  relax: {
    i18n: 'T1122',
    color: '#00D5FF'
  },
  rest: {
    i18n: 'T1123',
    color: '#9A9A9A'
  }
};
// 运动类型编号与名称映射
const sportTypeName = {
  // 跑步类
  '-100': 'Category_Run',
  // 步行类
  '-104': 'Category_Walk',
  // 攀登类
  '-105': 'Category_Climb',
  // 骑行类
  '-200': 'Category_Bike',
  // 游泳类
  '-300': 'Category_Swim',
  // 有氧运动类
  '-400': 'Category_Cardio',
  // 健身类
  '-402': 'Category_Strength',
  // 滑雪类
  '-500': 'Category_Ski',
  // 组合运动类
  '-10000': 'Category_GroupSport',
  // 水上运动类
  '-700': 'Category_Aquatics',

  // 全部
  0: 'All',
  // 户外跑
  100: 'Run',
  // 跑步机
  101: 'Indoor_Run',
  // 越野跑
  102: 'Trail_Run',
  // 操场跑圈
  103: 'Track_Run',
  // 徒步
  104: 'Hike',
  // 登山（下撤）（接近）
  105: 'Mtn_Climb',
  // 骑行
  200: 'Bike',
  // 室内骑行
  201: 'Indoor_Bike',
  // 公路 e-bike
  202: 'Road_Ebike',
  // 砾石路骑行
  203: 'Gravel_Road_Bike',
  // 山地骑行
  204: 'Mountain_Riding',
  // 山地 e-bike
  205: 'Mountain_Ebike',
  // 头盔骑行
  299: 'Helmet_Bike',
  // 泳池游泳
  300: 'Pool_Swim',
  // 公开水域
  301: 'Open_Water',
  // 铁三
  10000: 'Triathlon',
  // 健身
  402: 'Strength',
  // 室内有氧
  400: 'Gym_Cardio',
  // 户外有氧
  401: 'GPS_Cardio',
  // 雪场双板
  500: 'Ski',
  // 雪场单板
  501: 'Snowboard',
  // 越野滑雪
  502: 'XC_Ski',
  // 登山滑雪（新）
  503: 'Ski_Touring',
  // 登山滑雪（旧）
  10002: 'Ski_Touring_Old',
  // 组合运动
  10001: 'Multi_Sport',
  // 竞速帆板
  706: 'Speedsurfing',
  // 帆板
  705: 'Windsurfing',
  // 赛艇
  700: 'Row',
  // 划船机
  701: 'Indoor_Row',
  // 激流运动
  702: 'Whitewater',
  // 静水运动
  704: 'Flatwater',
  // 户外攀登
  10003: 'Multi_Pitch',
  // 攀登
  106: 'Climb',
  // 室内攀岩
  800: 'IndoorClimb',
  // 抱石
  801: 'Bouldering',
  // 健走
  900: 'Walk',
  // 跳绳
  901: 'Jump_Rope',
  // 自定义运动大类
  98: 'Custom_Sport'
};
const customSportIconMap = {
  // 全部
  1: 'Custom_Star',
  2: 'Custom_Ball',
  3: 'Custom_Bike',
  4: 'Custom_Craft',
  5: 'Custom_Walk',
  6: 'Custom_Adventure',
  7: 'Custom_Car',
  8: 'Custom_Fitness',
  9: 'Custom_Boat',
  10: 'Custom_Surfing',
  11: 'Custom_Hill',
  12: 'Custom_River',
  13: 'Custom_Forest',
  14: 'Custom_Tent',
  15: 'Custom_Photo',
  16: 'Custom_Like',
  17: 'Custom_Footprint',
  18: 'Custom_Snow',
  19: 'Custom_Fish',
  20: 'Custom_Highpoint',
  21: 'Custom_badminton',
  22: 'Custom_tabletennis',
  23: 'Custom_golf',
  24: 'Custom_equestrian',
  25: 'Custom_frisbee',
  26: 'Custom_rugby',
  27: 'Custom_skateboarding',
  28: 'Custom_skating',
  29: 'Custom_king',
  30: 'Custom_gym'
};
// 运动图标映射, 已废弃
const sportIcon = {
  // 全部
  All: '',
  // 户外跑
  Run: getImageAssetsFile('sport/Running.svg'),
  // 跑步机
  Indoor_Run: getImageAssetsFile('sport/Indoor_Run.svg'),
  // 越野跑
  Trail_Run: getImageAssetsFile('sport/Trail_Run.svg'),
  // 操场跑圈
  Track_Run: getImageAssetsFile('sport/Track_Run.svg'),
  // 徒步
  Hike: getImageAssetsFile('sport/Hike.svg'),
  // 登山
  Mtn_Climb: getImageAssetsFile('sport/Mtn_Climb.svg'),
  // 骑行
  Bike: getImageAssetsFile('sport/Bike.svg'),
  // 头盔骑行
  Helmet_Bike: getImageAssetsFile('sport/Bike.svg'),
  // 室内骑行
  Indoor_Bike: getImageAssetsFile('sport/Indoor_Bike.svg'),
  // 泳池游泳
  Pool_Swim: getImageAssetsFile('sport/Pool_Swim.svg'),
  // 公开水域
  Open_Water: getImageAssetsFile('sport/Open_Water.svg'),
  // 铁三
  Triathlon: getImageAssetsFile('sport/Triathlon.svg'),
  // 健身
  Strength: getImageAssetsFile('sport/Strength.svg'),
  // 室内有氧
  Gym_Cardio: getImageAssetsFile('sport/Gym_Cardio.svg'),
  // 户外有氧
  GPS_Cardio: getImageAssetsFile('sport/GPS_Cardio.svg'),
  // 雪场双板
  Ski: getImageAssetsFile('sport/Ski.svg'),
  // 雪场单板
  Snowboard: getImageAssetsFile('sport/Snowboard.svg'),
  // 越野滑雪
  XC_Ski: getImageAssetsFile('sport/XC_Ski.svg'),
  // 登山滑雪
  Ski_Touring: getImageAssetsFile('sport/Ski_Touring.svg'),
  // 登山滑雪（旧）
  Ski_Touring_Old: getImageAssetsFile('sport/Ski_Touring.svg'),
  // 组合运动
  Multi_Sport: getImageAssetsFile('sport/Multi_Sport.svg'),
  // 竞速帆板
  Speedsurfing: getImageAssetsFile('sport/Speedsurfing.svg'),
  // 帆板
  Windsurfing: getImageAssetsFile('sport/Windsurfing.svg'),
  // 赛艇
  Row: getImageAssetsFile('sport/Row.svg'),
  // 划船机
  Indoor_Row: getImageAssetsFile('sport/Indoor_Row.svg'),
  // 激流运动
  Whitewater: getImageAssetsFile('sport/Whitewater.svg'),
  // 静水运动
  Flatwater: getImageAssetsFile('sport/Flatwater.svg'),
  // 多段攀登
  Multi_Pitch: getImageAssetsFile('sport/Multi_Pitch.svg'),
  // 走路
  Walk: getImageAssetsFile(`sport/Walk.svg`),
  Competition: getImageAssetsFile('sport/label-race.svg'),
  // 跳绳
  Jump_Rope: getImageAssetsFile('sport/Jump_Rope.svg'),
  /** ***************** 自定义运动图标 ****************/
  // 兴趣点
  Custom_Star: getImageAssetsFile('customSport/Custom_star.svg'),
  // 球类
  Custom_Ball: getImageAssetsFile('customSport/Custom_ball.svg'),
  // 骑行
  Custom_Bike: getImageAssetsFile('customSport/Custom_bike.svg'),
  // 艇类
  Custom_Craft: getImageAssetsFile('customSport/Custom_craft.svg'),
  // 步行跑步类
  Custom_Walk: getImageAssetsFile('customSport/Custom_walk.svg'),
  // 探险类
  Custom_Adventure: getImageAssetsFile('customSport/Custom_adventure.svg'),
  // 载具类
  Custom_Car: getImageAssetsFile('customSport/Custom_car.svg'),
  // 健身/塑体/舞蹈
  Custom_Fitness: getImageAssetsFile('customSport/Custom_fitness.svg'),
  // 船内划桨
  Custom_Boat: getImageAssetsFile('customSport/Custom_boat.svg'),
  // 冲浪
  Custom_Surfing: getImageAssetsFile('customSport/Custom_surfing.svg'),
  // 山顶，雪山
  Custom_Hill: getImageAssetsFile('customSport/Custom_hill.svg'),
  // 水上运动
  Custom_River: getImageAssetsFile('customSport/Custom_river.svg'),
  // 丛林
  Custom_Forest: getImageAssetsFile('customSport/Custom_forest.svg'),
  // 露营
  Custom_Tent: getImageAssetsFile('customSport/Custom_tent.svg'),
  // 拍照
  Custom_Photo: getImageAssetsFile('customSport/Custom_photo.svg'),
  // 喜欢
  Custom_Like: getImageAssetsFile('customSport/Custom_like.svg'),
  // 狩猎
  Custom_Footprint: getImageAssetsFile('customSport/Custom_footprint.svg'),
  // 雪上运动
  Custom_Snow: getImageAssetsFile('customSport/Custom_snow.svg'),
  // 钓鱼
  Custom_Fish: getImageAssetsFile('customSport/Custom_fish.svg'),
  // 跳伞
  Custom_Highpoint: getImageAssetsFile('customSport/Custom_highpoint.svg'),
  // 毛球/网球
  Custom_badminton: getImageAssetsFile('customSport/Custom_badminton.svg'),
  // 乒乓球
  Custom_tabletennis: getImageAssetsFile('customSport/Custom_tabletennis.svg'),
  // 高尔夫
  Custom_golf: getImageAssetsFile('customSport/Custom_golf.svg'),
  // 马术
  Custom_equestrian: getImageAssetsFile('customSport/Custom_equestrian.svg'),
  // 飞盘
  Custom_frisbee: getImageAssetsFile('customSport/Custom_frisbee.svg'),
  // 橄榄球
  Custom_rugby: getImageAssetsFile('customSport/Custom_rugby.svg'),
  // 滑板
  Custom_skateboarding: getImageAssetsFile('customSport/Custom_skateboarding.svg'),
  // 滑冰
  Custom_skating: getImageAssetsFile('customSport/Custom_skating.svg'),
  // 冠军
  Custom_king: getImageAssetsFile('customSport/Custom_king.svg'),
  // 器械运动
  Custom_gym: getImageAssetsFile('customSport/Custom_gym.svg')
};
// 运动图标映射(白色) 已废弃
const sportIconWhite = {
  // 全部
  All: '',
  // 户外跑
  Run: getImageAssetsFile('sport/Running-w.svg'),
  // 跑步机
  Indoor_Run: getImageAssetsFile('sport/Indoor_Run-w.svg'),
  // 越野跑
  Trail_Run: getImageAssetsFile('sport/Trail_Run-w.svg'),
  // 操场跑圈
  Track_Run: getImageAssetsFile('sport/Track_Run-w.svg'),
  // 徒步
  Hike: getImageAssetsFile('sport/Hike-w.svg'),
  // 登山
  Mtn_Climb: getImageAssetsFile('sport/Mtn_Climb-w.svg'),
  // 骑行
  Bike: getImageAssetsFile('sport/Bike-w.svg'),
  // 头盔骑行
  Helmet_Bike: getImageAssetsFile('sport/Bike-w.svg'),
  // 室内骑行
  Indoor_Bike: getImageAssetsFile('sport/Indoor_Bike-w.svg'),
  // 泳池游泳
  Pool_Swim: getImageAssetsFile('sport/Pool_Swim-w.svg'),
  // 公开水域
  Open_Water: getImageAssetsFile('sport/Open_Water-w.svg'),
  // 铁三
  Triathlon: getImageAssetsFile('sport/Triathlon-w.svg'),
  // 健身
  Strength: getImageAssetsFile('sport/Strength-w.svg'),
  // 室内有氧
  Gym_Cardio: getImageAssetsFile('sport/Gym_Cardio-w.svg'),
  // 户外有氧
  GPS_Cardio: getImageAssetsFile('sport/GPS_Cardio-w.svg'),
  // 雪场双板
  Ski: getImageAssetsFile('sport/Ski-w.svg'),
  // 雪场单板
  Snowboard: getImageAssetsFile('sport/Snowboard-w.svg'),
  // 越野滑雪
  XC_Ski: getImageAssetsFile('sport/XC_Ski-w.svg'),
  // 登山滑雪
  Ski_Touring: getImageAssetsFile('sport/Ski_Touring-w.svg'),
  // 登山滑雪（旧）
  Ski_Touring_Old: getImageAssetsFile('sport/Ski_Touring-w.svg'),
  // 组合运动
  Multi_Sport: getImageAssetsFile('sport/Multi_Sport-w.svg'),
  // 竞速帆板
  Speedsurfing: getImageAssetsFile('sport/Speedsurfing-w.svg'),
  // 帆板
  Windsurfing: getImageAssetsFile('sport/Windsurfing-w.svg'),
  // 赛艇
  Row: getImageAssetsFile('sport/Row-w.svg'),
  // 划船机
  Indoor_Row: getImageAssetsFile('sport/Indoor_Row-w.svg'),
  // 激流运动
  Whitewater: getImageAssetsFile('sport/Whitewater-w.svg'),
  // 静水运动
  Flatwater: getImageAssetsFile('sport/Flatwater-w.svg'),
  // 多段攀登
  Multi_Pitch: getImageAssetsFile('sport/Multi_Pitch-w.svg'),
  // 走路
  Walk: getImageAssetsFile('sport/Walk-w.svg'),
  Competition: getImageAssetsFile('sport/label-race.svg'),
  // 跳绳
  Jump_Rope: getImageAssetsFile('sport/Jump_Rope-w.svg'),
  /** ***************** 自定义运动图标 ****************/
  // 兴趣点
  Custom_Star: getImageAssetsFile('customSport/Custom_star-w.svg'),
  // 球类
  Custom_Ball: getImageAssetsFile('customSport/Custom_ball-w.svg'),
  // 骑行
  Custom_Bike: getImageAssetsFile('customSport/Custom_bike-w.svg'),
  // 艇类
  Custom_Craft: getImageAssetsFile('customSport/Custom_craft-w.svg'),
  // 步行跑步类
  Custom_Walk: getImageAssetsFile('customSport/Custom_walk-w.svg'),
  // 探险类
  Custom_Adventure: getImageAssetsFile('customSport/Custom_adventure-w.svg'),
  // 载具类
  Custom_Car: getImageAssetsFile('customSport/Custom_car-w.svg'),
  // 健身/塑体/舞蹈
  Custom_Fitness: getImageAssetsFile('customSport/Custom_fitness-w.svg'),
  // 船内划桨
  Custom_Boat: getImageAssetsFile('customSport/Custom_boat-w.svg'),
  // 冲浪
  Custom_Surfing: getImageAssetsFile('customSport/Custom_surfing-w.svg'),
  // 山顶，雪山
  Custom_Hill: getImageAssetsFile('customSport/Custom_hill-w.svg'),
  // 水上运动
  Custom_River: getImageAssetsFile('customSport/Custom_river-w.svg'),
  // 丛林
  Custom_Forest: getImageAssetsFile('customSport/Custom_forest-w.svg'),
  // 露营
  Custom_Tent: getImageAssetsFile('customSport/Custom_tent-w.svg'),
  // 拍照
  Custom_Photo: getImageAssetsFile('customSport/Custom_photo-w.svg'),
  // 喜欢
  Custom_Like: getImageAssetsFile('customSport/Custom_like-w.svg'),
  // 狩猎
  Custom_Footprint: getImageAssetsFile('customSport/Custom_footprint-w.svg'),
  // 雪上运动
  Custom_Snow: getImageAssetsFile('customSport/Custom_snow-w.svg'),
  // 钓鱼
  Custom_Fish: getImageAssetsFile('customSport/Custom_fish-w.svg'),
  // 跳伞
  Custom_Highpoint: getImageAssetsFile('customSport/Custom_highpoint-w.svg'),
  // 毛球/网球
  Custom_badminton: getImageAssetsFile('customSport/Custom_badminton-w.svg'),
  // 乒乓球
  Custom_tabletennis: getImageAssetsFile('customSport/Custom_tabletennis-w.svg'),
  // 高尔夫
  Custom_golf: getImageAssetsFile('customSport/Custom_golf-w.svg'),
  // 马术
  Custom_equestrian: getImageAssetsFile('customSport/Custom_equestrian-w.svg'),
  // 飞盘
  Custom_frisbee: getImageAssetsFile('customSport/Custom_frisbee-w.svg'),
  // 橄榄球
  Custom_rugby: getImageAssetsFile('customSport/Custom_rugby-w.svg'),
  // 滑板
  Custom_skateboarding: getImageAssetsFile('customSport/Custom_skateboarding-w.svg'),
  // 滑冰
  Custom_skating: getImageAssetsFile('customSport/Custom_skating-w.svg'),
  // 冠军
  Custom_king: getImageAssetsFile('customSport/Custom_king-w.svg'),
  // 器械运动
  Custom_gym: getImageAssetsFile('customSport/Custom_gym-w.svg')
};
// 运动图标映射(灰色) 已废弃
const sportIconGray = {
  // 全部
  All: '',
  // 户外跑
  Run: getImageAssetsFile('sport/Running-g.svg'),
  // 跑步机
  Indoor_Run: getImageAssetsFile('sport/Indoor_Run-g.svg'),
  // 越野跑
  Trail_Run: getImageAssetsFile('sport/Trail_Run-g.svg'),
  // 操场跑圈
  Track_Run: getImageAssetsFile('sport/Track_Run-g.svg'),
  // 徒步
  Hike: getImageAssetsFile('sport/Hike-g.svg'),
  // 登山
  Mtn_Climb: getImageAssetsFile('sport/Mtn_Climb-g.svg'),
  // 骑行
  Bike: getImageAssetsFile('sport/Bike-g.svg'),
  // 头盔骑行
  Helmet_Bike: getImageAssetsFile('sport/Bike-g.svg'),
  // 室内骑行
  Indoor_Bike: getImageAssetsFile('sport/Indoor_Bike-g.svg'),
  // 泳池游泳
  Pool_Swim: getImageAssetsFile('sport/Pool_Swim-g.svg'),
  // 公开水域
  Open_Water: getImageAssetsFile('sport/Open_Water-g.svg'),
  // 铁三
  Triathlon: getImageAssetsFile('sport/Triathlon-g.svg'),
  // 健身
  Strength: getImageAssetsFile('sport/Strength-g.svg'),
  // 室内有氧
  Gym_Cardio: getImageAssetsFile('sport/Gym_Cardio-g.svg'),
  // 户外有氧
  GPS_Cardio: getImageAssetsFile('sport/GPS_Cardio-g.svg'),
  // 雪场双板
  Ski: getImageAssetsFile('sport/Ski-g.svg'),
  // 雪场单板
  Snowboard: getImageAssetsFile('sport/Snowboard-g.svg'),
  // 越野滑雪
  XC_Ski: getImageAssetsFile('sport/XC_Ski-g.svg'),
  // 登山滑雪
  Ski_Touring: getImageAssetsFile('sport/Ski_Touring-g.svg'),
  // 登山滑雪（旧）
  Ski_Touring_Old: getImageAssetsFile('sport/Ski_Touring-g.svg'),
  // 组合运动
  Multi_Sport: getImageAssetsFile('sport/Multi_Sport-g.svg'),
  // 竞速帆板
  Speedsurfing: getImageAssetsFile('sport/Speedsurfing-g.svg'),
  // 帆板
  Windsurfing: getImageAssetsFile('sport/Windsurfing-g.svg'),
  // 赛艇
  Row: getImageAssetsFile('sport/Row-g.svg'),
  // 划船机
  Indoor_Row: getImageAssetsFile('sport/Indoor_Row-g.svg'),
  // 激流运动
  Whitewater: getImageAssetsFile('sport/Whitewater-g.svg'),
  // 静水运动
  Flatwater: getImageAssetsFile('sport/Flatwater-g.svg'),
  // 多段攀登
  Multi_Pitch: getImageAssetsFile('sport/Multi_Pitch-g.svg'),
  // 走路
  Walk: getImageAssetsFile('sport/Walk-g.svg'),
  Competition: getImageAssetsFile('sport/label-race.svg'),
  // 跳绳
  Jump_Rope: getImageAssetsFile('sport/Jump_Rope-g.svg')
};
// 运动多语言key映射
const sportNameI18n = {
  // 全部
  All: 'C1052',
  // 户外跑
  Run: 'H1001',
  // 跑步机
  Indoor_Run: 'H1002',
  // 越野跑
  Trail_Run: 'H1003',
  // 操场跑圈
  Track_Run: 'H1004',
  // 徒步
  Hike: 'H1005',
  // 登山
  Mtn_Climb: 'H1006',
  // 骑行
  Bike: 'H1007',
  // 头盔骑行
  Helmet_Bike: 'H1072',
  // 室内骑行
  Indoor_Bike: 'H1008',
  // 公路E-Bike 作为骑行子项
  // E_BIKE = 202;
  Road_Ebike: 'H9087',
  // 砾石路骑行
  // GRAVEL_BIKE = 203;
  Gravel_Road_Bike: 'H9085',
  // 山地骑行
  // MOUNTAIN_BIKE = 204;
  Mountain_Riding: 'H9086',
  // 山地E-Bike
  // E_MOUNTAIN_BIKE = 205;
  Mountain_Ebike: 'H9088',
  // 泳池游泳
  Pool_Swim: 'H1009',
  // 公开水域
  Open_Water: 'H1010',
  // 铁三
  Triathlon: 'H1011',
  // 健身
  Strength: 'H1012',
  // 室内有氧
  Gym_Cardio: 'H1013',
  // 户外有氧
  GPS_Cardio: 'H1014',
  // 雪场双板
  Ski: 'H1015',
  // 雪场单板
  Snowboard: 'H1016',
  // 越野滑雪
  XC_Ski: 'H1017',
  // 登山滑雪
  Ski_Touring: 'H1018',
  // 登山滑雪
  Ski_Touring_Old: 'H1018',
  // 组合运动
  Multi_Sport: 'H1019',
  // 竞速帆板
  Speedsurfing: 'H1043',
  // 帆板
  Windsurfing: 'H1044',
  // 赛艇
  Row: 'H1045',
  // 划船机
  Indoor_Row: 'H1046',
  // 激流运动
  Whitewater: 'H1047',
  // 静水运动
  Flatwater: 'H1048',
  // 多段攀登
  Multi_Pitch: 'H2025',
  // 攀登
  Climb: 'H2026',
  // 健走
  Walk: 'H2024',
  // 跳绳
  Jump_Rope: 'H9028'
};

// 泳姿编号与名称映射
const swimStyleName = {
  0: 'NOT_SET',
  // 自由泳
  1: 'FREE_STROKE',
  // 蛙泳
  2: 'BREAST_STROKE',
  // 仰泳
  3: 'BACK_STROKE',
  // 蝶泳
  4: 'BUTTERFLY_STROKE',
  // 打腿
  5: 'DRILL_LOG',
  // 混合泳
  255: 'MEDLEY',
  // 自定义距离圈，不展示游姿
  254: 'CUSTOM'
};

const swimStyleConfig = {
  // 不设置
  NOT_SET: {
    nameI18n: 'S4313',
    type: 0
  },
  // 自由泳
  FREE_STROKE: {
    nameI18n: 'S2615',
    type: 1
  },
  // 蛙泳
  BREAST_STROKE: {
    nameI18n: 'S2616',
    type: 2
  },
  // 仰泳
  BACK_STROKE: {
    nameI18n: 'S2617',
    type: 3
  },
  // 蝶泳
  BUTTERFLY_STROKE: {
    nameI18n: 'S2618',
    type: 4
  },
  // 打腿
  DRILL_LOG: {
    nameI18n: 'H6033',
    type: 5
  },
  // 混合泳
  MEDLEY: {
    nameI18n: 'S2619',
    type: 255
  },
  // 自定义距离圈，不展示游姿
  CUSTOM: {
    nameI18n: '',
    type: 254
  }
};

export default {
  // 公制key
  get METRIC_UNITS() {
    return 0;
  },
  // 英制key
  get IMPERIAL_UNITS() {
    return 1;
  },
  sportCategoryName,
  sportCategory,
  targetTypeName,
  targetType,
  intensityTypeName,
  restTypeName,
  restType,
  exerciseTypeName,
  exerciseTypeOptions,
  sportTypeName,
  sportIcon,
  sportNameI18n,
  swimStyleName,
  swimStyleConfig,

  /**
   * 运动大类相关
   */
  // 根据sportCategory的key获取sportCategoryType
  sportCategoryNameToType(name) {
    if (Array.isArray(name)) {
      return name.map((item) => {
        return this.sportCategoryNameToType(item);
      });
    }

    let result = '';
    for (const n in sportCategoryName) {
      if (sportCategoryName[n] === name) {
        result = Number(n);
        break;
      }
    }
    return result;
  },

  // 获取需要的目标类型（根据value获取）
  getSportCategoryByValue(value) {
    if (Array.isArray(value)) {
      return value.map((item) => {
        return this.getSportCategoryByValue(item);
      });
    }

    let result = {};
    const name = sportCategoryName[value];
    if (name && sportCategory[name]) {
      result = {
        ...sportCategory[name],
        key: name,
        value
      };
    }
    return result;
  },

  /**
   * 目标类型相关
   */
  // 根据target的key获取targetType
  targetNameToType(name) {
    if (Array.isArray(name)) {
      return name.map((item) => {
        return this.targetNameToType(item);
      });
    }

    let result = '';
    for (const n in targetTypeName) {
      if (targetTypeName[n] === name) {
        result = Number(n);
        break;
      }
    }
    return result;
  },

  // 获取需要的训目标类型（根据key获取）
  getTargetTypeByName(name) {
    if (Array.isArray(name)) {
      return name.map((item) => {
        return this.getTargetTypeByName(item);
      });
    }
    let result = {};
    if (targetType[name]) {
      result = {
        ...targetType[name],
        key: name,
        value: this.targetNameToType(name)
      };
    }
    return result;
  },

  // 获取需要的目标类型（根据value获取）
  getTargetTypeByValue(value) {
    if (Array.isArray(value)) {
      return value.map((item) => {
        return this.getTargetTypeByValue(item);
      });
    }

    let result = {};
    const name = targetTypeName[value];
    if (name && targetType[name]) {
      result = {
        ...targetType[name],
        key: name,
        value
      };
    }
    return result;
  },

  // 根据目标单位类型数值转换为对应的key
  getTargetUnitNameByType(type, defaultType = '') {
    return targetUnitName[type] || defaultType || '';
  },

  // 根据目标单位key转换为对应的类型数值
  getTargetUnitTypeByName(name) {
    let result = 0;
    for (const n in targetUnitName) {
      if (targetUnitName[n] === name) {
        result = n;
        break;
      }
    }
    return result;
  },

  /**
   * 强度类型相关
   */
  // 根据intensity的key获取intensityType
  intensityNameToType(name) {
    if (Array.isArray(name)) {
      return name.map((item) => {
        return this.intensityNameToType(item);
      });
    }

    let result = '';
    for (const n in intensityTypeName) {
      if (intensityTypeName[n] === name) {
        result = Number(n);
        break;
      }
    }
    return result;
  },

  // 获取需要的强度类型（根据key获取）
  getIntensityTypeByName(name) {
    if (Array.isArray(name)) {
      return name.map((item) => {
        return this.getIntensityTypeByName(item);
      });
    }
    let result = {};
    if (name) {
      result = {
        key: name,
        value: this.intensityNameToType(name)
      };
    }
    return result;
  },

  // 获取需要的强度类型（根据value获取）
  getIntensityTypeByValue(value) {
    if (Array.isArray(value)) {
      return value.map((item) => {
        return this.getIntensityTypeByValue(item);
      });
    }

    let result = {};
    const name = intensityTypeName[value];
    if (name) {
      result = {
        key: name,
        value
      };
    }
    return result;
  },

  // 根据强度单位类型数值转换为对应的key
  getIntensityUnitNameByType(type) {
    return intensityUnitName[type] || '';
  },

  // 根据强度单位key转换为对应的类型数值
  getIntensityUnitTypeByName(name) {
    let result = 0;
    for (const n in intensityUnitName) {
      if (intensityUnitName[n] === name) {
        result = n;
        break;
      }
    }
    return result;
  },

  /**
   * 休息相关
   */
  // 根据rest的key获取restType
  restNameToType(name) {
    if (Array.isArray(name)) {
      return name.map((item) => {
        return this.restNameToType(item);
      });
    }

    let result = '';
    for (const n in restTypeName) {
      if (restTypeName[n] === name) {
        result = Number(n);
        break;
      }
    }
    return result;
  },

  // 获取需要的训练支持的休息类型（根据key获取）
  getRestTypeByName(name) {
    if (Array.isArray(name)) {
      return name.map((item) => {
        return this.getRestTypeByName(item);
      });
    }
    let result = {};
    if (restType[name]) {
      result = {
        ...restType[name],
        key: name,
        value: this.restNameToType(name)
      };
    }
    return result;
  },

  // 获取需要的训练支持的休息类型（根据value获取）
  getRestTypeByValue(value) {
    if (Array.isArray(value)) {
      return value.map((item) => {
        return this.getRestTypeByValue(item);
      });
    }

    let result = {};
    const name = restTypeName[value];
    if (name && restType[name]) {
      result = {
        ...restType[name],
        key: name,
        value
      };
    }
    return result;
  },

  /**
   * 动作相关
   */
  // 根据exercise的key获取exerciseType
  exerciseNameToType(name) {
    if (Array.isArray(name)) {
      return name.map((item) => {
        return this.exerciseNameToType(item);
      });
    }

    let result = '';
    for (const n in exerciseTypeName) {
      if (exerciseTypeName[n] === name) {
        result = Number(n);
        break;
      }
    }
    return result;
  },

  // 获取需要的训练类型（根据key获取）
  getExerciseTypeInfoByName(name) {
    if (Array.isArray(name)) {
      return name.map((item) => {
        return this.getExerciseTypeInfoByName(item);
      });
    }
    let result = {};
    if (exerciseTypeOptions[name]) {
      result = {
        ...exerciseTypeOptions[name],
        key: name,
        value: this.exerciseNameToType(name)
      };
    }
    return result;
  },

  // 获取需要的训练支持的休息类型（根据value获取）
  getExerciseNameByType(value) {
    if (Array.isArray(value)) {
      return value.map((item) => {
        return this.getExerciseNameByType(item);
      });
    }

    let result = {};
    const name = exerciseTypeName[value];
    if (name && exerciseTypeOptions[name]) {
      result = {
        ...exerciseTypeOptions[name],
        key: name,
        value
      };
    }
    return result;
  },

  /**
   * 单项运动相关
   */
  // 根据type获取运动Icon
  getSportIconByType(type, color, bitmapType = 1) {
    if (this.isCustomSport(type)) {
      return this.getCustomSportIconByMap(bitmapType);
    }
    if (color === 'white') {
      return (sportTypeName[type] && sportIconWhite[sportTypeName[type]]) || '';
    } else if (color === 'gray') {
      return (sportTypeName[type] && sportIconGray[sportTypeName[type]]) || '';
    }
    return (sportTypeName[type] && sportIcon[sportTypeName[type]]) || '';
  },
  // 根据type获取自定义运动Icon
  getCustomSportIconByMap(mapType, color) {
    const type = customSportIconMap[mapType];
    if (color === `white`) {
      return (type && sportIconWhite[type]) || '';
    } else if (color === `gray`) {
      return (type && sportIconGray[type]) || '';
    }
    return (type && sportIcon[type]) || '';
  },
  // 根据type获取运动key
  getSportNameByType(type) {
    return sportTypeName[type] || '';
  },

  // 根据运动key获取type
  getSportTypeBySportName(sportName) {
    for (const n in sportTypeName) {
      if (sportTypeName[n] === sportName) {
        return n;
      }
    }
    return '';
  },

  // 根据运动名称获取运动名称的i18n key
  getSportI18nKeyBySportName(sportName) {
    return sportNameI18n[sportName] || '';
  },

  // 根据sportType获取运动名称的i18n key
  getSportI18nKeyBySportType(sportType) {
    return sportNameI18n[this.getSportNameByType(sportType)] || '';
  },

  // 获取泳姿名称
  getSwimStyleNameByCode(code) {
    return swimStyleName[code];
  },

  // 获取泳姿名称
  getSwimStyleI18nByName(name) {
    return swimStyleConfig[name].nameI18n;
  },

  // 获取泳姿名称
  getSwimStyleI18nByCode(code) {
    return swimStyleConfig[this.getSwimStyleNameByCode(code)].nameI18n;
  },

  // mode
  // 0:普通运动自动记圈
  // 1:普通运动手动记圈
  // 2:间隙运动的训练
  // 3:间隙运动的休息
  // 4:间隙运动的热身
  // 5:间隙运动的放松
  // 10:gomore运动的热身
  // 11:gomore运动的训练
  // 12:gomore运动的放松
  // 13:为了兼容佳明，游泳的休息趟，不在显示在圈里面，在趟里面也不显示
  // 14:动作的训练
  // 15:动作的休息，休息可有可无
  // 16:动作多组训练汇总圈
  // 17:动作多组休息汇总圈, 休息可有可无
  // 32:按照用户设置的圈距自动记圈
  // 64:手动记圈
  // 128:骑行(5km)或者跑步(1km)的默认圈只有普通运动才有的

  // mode是否表示训练
  isTestExerciseMode(mode) {
    return [11].includes(mode);
  },
  isExerciseMode(mode) {
    return [2, 25, 14, 16].includes(mode);
  },
  isCustomSport(originSportType) {
    return (originSportType >= 9800 && originSportType <= 9930) || originSportType === 98;
  },
  // mode是否表示休息
  isRestMode(mode) {
    return [17].includes(mode);
  },

  // mode是否表示热身
  isWarmupMode(mode) {
    return [4, 10].includes(mode);
  },

  // mode是否表示放松
  isRelaxMode(mode) {
    return [5, 12].includes(mode);
  },

  // mode是否表示恢复
  isRecoveryMode(mode) {
    return [3, 13, 15].includes(mode);
  },

  // 获取mode类型的多语言key
  getModeI18nKey(mode) {
    // 热身
    if (this.isWarmupMode(mode)) {
      return 'T1120';
    }
    // 测试训练
    if (this.isTestExerciseMode(mode)) {
      return 'R5004';
    }
    // 训练
    if (this.isExerciseMode(mode)) {
      return 'T1121';
    }

    // 休息
    if (this.isRestMode(mode)) {
      return 'H1112';
    }

    // 放松
    if (this.isRelaxMode(mode)) {
      return 'T1122';
    }

    // 恢复
    if (this.isRecoveryMode(mode)) {
      return 'T1123';
    }

    return '';
  }
};
