<template>
  <Modal
    v-model:visible="state.active"
    :title="''"
    :width="state.width"
    :height="state.height"
    :modal-style="{ height: '100%' }"
    body-class="flex flex-col h-full p-0"
    :footer="false"
    :closable="false"
    :maskClosable="false"
  >
    <div class="flex flex-none flex-row items-center top-bar">
      <div class="flex-auto">
        {{ state.teamInfo ? state.teamInfo.name : '' }}
      </div>
      <div class="flex-none close-icon cursor-pointer">
        <i
          class="iconfont iconicon_jichutubiao_guanbi"
          @click="close"
        ></i>
      </div>
    </div>
    <div
      ref="list"
      class="flex-auto list"
    >
      <template v-if="quitUserList">
        <div
          v-for="item in quitUserList"
          :key="item.id"
          :ref="item.id"
          :class="['item', { active: state.activeCommentId === item.id }]"
        >
          <div
            class="content"
            v-html="item.contentText"
          ></div>
          <div class="time-text">{{ item.userQuitTimeText }}</div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script setup>
import { Message, Modal } from '@arco-design/web-vue';
import { computed, reactive, getCurrentInstance } from 'vue';

import mixin from '@client/mixins/getDateFormat';

import { teamSettingStore } from '@client/store/teamSettingStore';

const teamSettingStoreRef = teamSettingStore();
const getDateFormat = mixin.methods.getDateFormat;

const vm = getCurrentInstance();
const t = vm.proxy.$root.$t;

Message._context = vm?.appContext;

defineExpose({
  open,
  close
});

const state = reactive({
  active: false,
  width: '896px',
  height: '592px',

  teamId: '',
  activeCommentId: '',
  openOption: {},

  teamInfo: null
});

const quitUserList = computed(() => {
  if (!state.teamInfo) {
    return null;
  }
  const result = state.teamInfo.quitUserList || [];

  result.forEach((item) => {
    item.contentText = t('N0030', {
      name: `<span style="color: rgba(255, 255, 255, 1);">${item.nickname}</span>`
    });

    item.userQuitTimeText = getDateFormat(item.userQuitTime * 1000, t);
  });

  return result;
});

// 暴露给上级用
function open(option = {}) {
  if (!option.teamId) return;
  state.active = true;
  state.openOption = option;
  getTeamInfo();
}
// 暴露给上级用
function close() {
  state.active = false;
  state.openOption = {};
  state.teamInfo = null;
}

async function getTeamInfo() {
  try {
    const data = await teamSettingStoreRef.getTeam({ teamId: state.openOption.teamId });
    if (data) {
      state.teamInfo = data;
    }
  } catch (error) {
    Message.error(error);
  }
}
</script>

<style lang="scss">
.team-quitting-list-dialog {
  .body {
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss" scoped>
.top-bar {
  height: 72px;
  padding: 0 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  color: rgba(255, 255, 255, 1);

  .close-icon {
    .iconfont {
      font-size: 20px;
      color: #d9dadd;
    }
  }
}

.list {
  margin: 24px 0;
  padding: 0 24px;
  box-sizing: border-box;
  overflow: auto;

  .item {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    line-height: 20px;
    white-space: pre-wrap;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;

    display: flex;
    flex-direction: row;
    align-items: center;

    &.active {
      background-color: rgba(0, 179, 255, 0.4);
    }

    &:hover {
      background-color: rgba(0, 179, 255, 0.1);
    }

    .content {
      flex-shrink: 1;
      flex-grow: 1;
    }

    .time-text {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}
</style>
