<template>
  <Modal
    v-model:visible="state.active"
    :title="''"
    :popper-class="['activity-comment-dialog']"
    :width="state.width"
    :footer="false"
    unmount-on-close
    :modal-style="{ height: state.height }"
    body-class="flex flex-col p-0 h-full"
    :closable="false"
    :maskClosable="false"
  >
    <div class="flex-none flex flex-row items-center activity-detail-info">
      <SportIcon
        class="text-20 mr-10"
        :sport-type="iconConfig.sportType"
        :map-type="iconConfig.bitmapType"
      ></SportIcon>
      <div class="flex-none">
        <!--      运动名称-->
        <a
          class="text-ellipsis description"
          :href="state.info?.activityDetailPath"
          target="_blank"
        >
          {{ activityName || $t('H1071') }}
        </a>
        <!--      运动时间地点-->
        <div class="date-time">
          {{ activityTime }}
        </div>
      </div>
      <div class="flex-1"></div>
      <div class="flex-none close-icon cursor-pointer">
        <i
          class="iconfont iconicon_jichutubiao_guanbi"
          @click="close"
        ></i>
      </div>
    </div>
    <div
      ref="listRef"
      class="flex-auto list"
    >
      <template v-if="state.info?.messages">
        <div class="total">
          {{ $t('H9011', { num: state.info.messages.length }) }}
        </div>
        <div
          v-for="item in state.info.messages"
          :key="item.id"
          ref="itemsRef"
          :class="['item', { active: state.activeCommentId === item.id }]"
        >
          <div class="user-header">
            <Avatar
              class="w-full h-full bg-fill-5"
              shape="circle"
              :image-url="item.userInfo.headPic"
            >
              <span class="iconfont icontuanduibeifen text-24 text-fill-3"></span>
            </Avatar>
          </div>
          <div class="item-content">
            <div class="message-title">{{ item.title }}</div>
            <div class="message-content">{{ item.content }}</div>
            <div class="message-other">
              <div class="create-time">{{ item.createTimeText }}</div>
            </div>
          </div>
          <div class="operate">
            <i
              class="iconfont iconxiaoxi reply"
              @click="replyMessage(item, true)"
            ></i>
            <a-spin
              :loading="state.isDeleting.indexOf(item.id) > -1"
              tip=""
            >
              <i
                v-if="state.info.isOwner"
                class="iconfont iconicon_jichutubiao_shanchu1 delete"
                @click.stop="deleteMessageItem(item)"
              ></i>
            </a-spin>
          </div>
        </div>
      </template>
    </div>
    <div class="flex-none reply-line">
      <Textarea
        ref="replyRef"
        v-model="state.replyContent"
        class="reply-content"
        :placeholder="placeholder"
      ></Textarea>
      <div class="flex flex-none flex-row items-center justify-end btn-group">
        <Button
          v-if="state.replyingItem"
          class="cancel-btn"
          @click="replyMessage(null, false)"
        >
          {{ $t('H9017') }}
        </Button>
        <Button
          class="reply-btn"
          :disabled="!state.replyContent"
          :loading="state.addMessageBtnLoading"
          @click="addMessage"
        >
          {{ $t('H9020') }}
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import dayjs from 'dayjs';
import { Avatar, Textarea, Button, Message, Modal } from '@arco-design/web-vue';
import { messageStore } from '@client/store/messageStore';
import SportIcon from '@client/components/sportIcon/index.vue';
import { computed, ref, nextTick, reactive, getCurrentInstance } from 'vue';
import unit from '@client/helper/unit';

const messageStoreRef = messageStore();

const vm = getCurrentInstance();
Message._context = vm?.appContext;

const listRef = ref(null);
const itemsRef = ref([]);
const replyRef = ref(null);

defineExpose({
  open,
  close
});

const t = vm.proxy.$root.$t;

const state = reactive({
  info: {
    messages: [],
    isOwner: 1,
    dataObj: {
      sportType: '',
      name: '',
      startTime: 0
    },
    activityDetailPath: ''
  },
  addMessageBtnLoading: false,
  active: false,
  width: '896px',
  height: '592px',

  activeCommentId: '',
  openOption: {
    commentId: '',
    labelId: ''
  },

  replyContent: '',
  // 回复某一条
  replyingItem: null,

  isDeleting: []
});

const iconConfig = computed(() => {
  if (!state.info?.dataObj?.sportType) {
    return {
      icon: '',
      color: '#fff'
    };
  }
  const { sportType, bitmapType } = state.info?.dataObj;
  return {
    sportType,
    bitmapType
  };
});

const activityName = computed(() => {
  return (state?.info?.dataObj?.name || t('H1071')).trim();
});
const activityTime = computed(() => {
  let startTimestamp = state?.info?.dataObj?.startTime;
  if (!startTimestamp) {
    return '- -';
  }

  startTimestamp = startTimestamp * 1000;

  return dayjs(startTimestamp).format(t('C6004'));
});
const placeholder = computed(() => {
  if (state.replyingItem) {
    return t('H9019', { name: state.replyingItem.userInfo.nickname });
  }
  return t('H9014');
});
// 暴露给上级用
function open(option = { commentId: '', labelId: '' }) {
  state.active = true;
  state.openOption = option;
  getList();
}
// 暴露给上级用
function close() {
  state.active = false;
  state.openOption = { commentId: '', labelId: '' };
  state.info = null;
}
async function getList(scrollToBottom = true) {
  const options = {
    params: {
      type: 1,
      dataId: state.openOption.labelId
    }
  };

  let data;
  try {
    data = await messageStoreRef.fetchLeavingmessages(options);
  } catch (err) {
    Message.error(err);
  }
  if (!data) {
    return;
  }

  try {
    data.dataObj = JSON.parse(data.dataJson);
    data.activityDetailPath = `/activity-detail?labelId=${data.dataObj?.labelId}&sportType=${data.dataObj?.sportType}&collapse=comment&teamId=${data.teamId}&userId=${data.userId}`;
  } catch (error) {
    console.error(error);
  }

  if (!data.dataObj) return;

  data.messages = dealMessageData(data.messages || []);
  state.info = data;

  nextTick(() => {
    const activeCommentId = state.openOption.commentId || '';

    if (!listRef.value) return;

    if (!activeCommentId) {
      if (scrollToBottom) {
        listRef.value.scrollTop = listRef.value.scrollHeight;
      }
      return;
    }

    // 可能 nextTick 之后评论没了
    if (!state.info?.messages) return;

    // 需要定位到对应的评论
    const index = state.info.messages.findIndex((item) => item.id === activeCommentId);
    const el = itemsRef.value[index];
    if (el) {
      listRef.value.scrollTop = el.offsetTop - 70;
      state.activeCommentId = activeCommentId;

      setTimeout(() => {
        state.activeCommentId = '';
        state.openOption.commentId = '';
      }, 2500);
    }
  });
}

function dealMessageData(data) {
  return data.map((item) => {
    const newItem = { ...item };
    newItem.title = '';
    if ([1, '1'].includes(newItem.type)) {
      newItem.title = newItem.userInfo.nickname;
    }
    if ([2, '2'].includes(newItem.type)) {
      newItem.title = t('H9013', {
        name1: newItem.userInfo.nickname,
        // TODO: pendding-xjf  replyToUser 不存在！
        name2: newItem.replyToUser.nickname
      });
    }

    newItem.createTimeText = unit.getDateText(newItem.createTime * 1000);

    return newItem;
  });
}
async function deleteMessageItem(item) {
  // TODO: pendding-xjf 这里为什么不直接不出现dom？
  if (!state.info?.isOwner) {
    return;
  }

  state.isDeleting.push(item.id);
  let data;
  try {
    data = await messageStoreRef.deleteReply([item.id]);
  } catch (error) {}
  if (state.replyingItem && state.replyingItem.id === item.id) {
    state.replyingItem = null;
  }
  state.isDeleting.splice(state.isDeleting.indexOf(item.id), 1);
  getList(false);
}

/**
 *
 * @param {object | null} item 取消的时候传 null
 * @param {boolean} focus 取消的时候 false
 */
function replyMessage(item, focus) {
  if (item) {
    state.replyingItem = item;
  } else {
    state.replyingItem = null;
  }

  if (focus && replyRef.value) {
    replyRef.value.focus();
  }
}

async function addMessage() {
  if (state.addMessageBtnLoading) {
    return;
  }

  const data = {
    dataId: state.openOption.labelId,
    type: 1,
    content: state.replyContent,
    messageType: 1
  };

  if (state.replyingItem) {
    data.messageType = 2;
    data.replyTo = state.replyingItem.id;
  }

  state.addMessageBtnLoading = true;

  try {
    await messageStoreRef.addLeavingmessage(data);
    state.addMessageBtnLoading = false;
    state.replyContent = '';
    state.openOption.commentId = '';
    getList();
  } catch (error) {
    Message.error(error);
    state.addMessageBtnLoading = false;
  }
}
</script>

<style lang="scss">
.activity-comment-dialog {
  .body {
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss" scoped>
.activity-detail-info {
  height: 72px;
  padding: 0 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #181e30;
  box-sizing: border-box;

  .sport-icon {
    margin-right: 12px;

    img {
      max-width: 32px;
      max-height: 32px;
    }
  }

  .description {
    margin-bottom: 4px;
    max-width: 300px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    text-decoration-line: underline;
  }

  .date-time {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);
    line-height: 20px;
  }

  .close-icon {
    .iconfont {
      font-size: 20px;
      color: #d9dadd;
    }
  }
}

.list {
  background-color: #181e30;
  overflow: auto;

  .total {
    padding: 12px 24px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    line-height: 20px;
  }

  .item {
    padding: 12px 24px;
    box-sizing: border-box;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &:nth-of-type(1) {
      padding-top: 12px;
    }

    &.active {
      background-color: rgba(0, 152, 255, 0.4);
    }

    &:hover {
      background-color: rgba(0, 152, 255, 0.2);

      .operate {
        visibility: visible;
      }
    }

    .user-header {
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 8px;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      overflow: hidden;
      box-sizing: border-box;
      font-size: 0;
      line-height: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .item-content {
      flex-grow: 1;
      flex-shrink: 1;
      padding-right: 32px;
      box-sizing: border-box;

      .message-title {
        margin-bottom: 0.08rem;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
      }

      .message-content {
        margin-bottom: 0.16rem;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
        line-height: 20px;
      }

      .message-other {
        display: flex;
        flex-direction: row;
        align-items: center;

        .create-time {
          flex-grow: 1;
          flex-shrink: 1;
          font-size: 12px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
          line-height: 18px;
        }
      }
    }

    .operate {
      flex-grow: 0;
      flex-shrink: 0;
      padding-top: 24px;
      visibility: hidden;

      span {
        margin-right: 16px;
        font-size: 14px;
        color: #a1a3aa;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: #00b3ff;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      i {
        margin-right: 16px;
        font-size: 20px;
        color: #a1a3aa;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: #00b3ff;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.reply-line {
  background-color: #181e30;
  padding: 32px 72px 24px 72px;
  box-sizing: border-box;

  .reply-content {
    margin-bottom: 16px;
    height: 84px;
    background-color: #1a2134;
    //box-shadow: 0px -8px 28px 0px rgba(9, 12, 21, 0.1),
    //  0px -16px 40px 0px rgba(9, 12, 21, 0.2),
    //  0px -2px 16px 0px rgba(9, 12, 21, 0.4);
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.btn-group {
  button {
    min-width: 100px;
    margin-right: 16px;
    padding: 0 16px;
    height: 32px;
    border-radius: 2px;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:nth-last-of-type(1) {
      margin-right: 0;
    }

    &.cancel-btn {
      color: #ffffff;
      background: #283352;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    &.reply-btn {
      color: #ffffff;
      background: #00b3ff;
      border: 1px solid rgba(255, 255, 255, 0.1);

      &[disabled] {
        color: rgba(255, 255, 255, 0.4);
        background-color: #232b40;
      }
    }
  }
}
</style>
