import { ref } from 'vue';

const tipsModalVisible = ref(false);
const tipsModalActiveIndex = ref(0);

function showTipsModal() {
  tipsModalVisible.value = true;
}
function hideTipsModal() {
  tipsModalVisible.value = false;
}
function toggleTipsModal() {
  tipsModalVisible.value = !tipsModalVisible.value;
}
function setTipsModalActiveIndex(idx) {
  showTipsModal();
  setTimeout(() => {
    tipsModalActiveIndex.value = idx;
  });
}

export function useTipsModal() {
  return {
    tipsModalVisible,
    tipsModalActiveIndex,
    showTipsModal,
    hideTipsModal,
    toggleTipsModal,
    setTipsModalActiveIndex
  };
}
