const PersonalSetting = () => import('../pages/personalSetting/index.vue');

export default [
  {
    name: 'personalSetting',
    path: '/admin/personal',
    component: PersonalSetting,
    children: []
  }
];
