import { defineStore } from 'pinia';
import axios from '@client/helper/http';
import { team } from '@client/api/index';
import _ from 'lodash';
import { TEAM_COACH_ROLE, TEAM_GROUP_HOLDER_ROLE } from '@client/config/team';
import { i18n } from '@client/helper';

const { t } = i18n;

const DEFAULT_GROUP_OPTION = 'all';

export const TAB_MEMBER_LIST = 1;
export const TAB_APPLY_LIST = 2;
export const OPERATE_KICK_OUT = 'kick out';
export const OPERATE_SET_COACH = 'set coach';
export const OPERATE_FIRE_COACH = 'fire coach';
export const OPERATE_DISMISS = 'dismiss';
export const OPERATE_SET_BOSS = 'set boss';

// function sortList(arr) {
//   if (arr.length === 0) return arr;
//   const a = _.sortBy(arr, ['remarkName']);
//   let back = [];
//   let master = null;
//   const coach = [];
//   const member = [];
//   for (let i = 0; i < a.length; i++) {
//     if (a[i].role === TEAM_GROUP_HOLDER_ROLE) {
//       master = a[i];
//     } else if (a[i].role === TEAM_COACH_ROLE) {
//       coach.push(a[i]);
//     } else {
//       member.push(a[i]);
//     }
//   }
//   if (master) back.push(master);
//   back = back.concat(coach).concat(member);
//   return back;
// }

export const teamSettingStore = defineStore('teamSetting', {
  state() {
    return {
      drawerOpenStatus: true,
      isShowCreateTeam: false,
      teamList: [],
      activeTeamId: '',
      search: '',

      listType: TAB_MEMBER_LIST,

      // 申请列表
      waitApprovedMember: 0,
      // 当前team的申请列表
      applyList: [],
      applyListLoading: false,

      // 当前选择的分组
      activeGroupId: DEFAULT_GROUP_OPTION,

      // 当前team的成员列表
      memberList: [],
      memberListLoading: false,

      // memberList 列表分页
      pagination: {
        total: 0,
        // 当前的页数
        current: 1,
        // 每页展示的数据条数
        pageSize: 10,
        showPageSize: true,
        showJumper: true
      },

      // memberList 排序
      sortConf: [
        {
          // 1：用户名remarkName，2：用户角色role
          column: 2,
          order: 0
        },
        {
          column: 1,
          order: 0
        }
      ],

      // applylist 列表分页
      paginationForApplyTab: {
        total: 0,
        // 当前的页数
        current: 1,
        // 每页展示的数据条数
        pageSize: 10,
        showPageSize: true,
        showJumper: true
      },
      sortConfForApply: [
        {
          // 3：用户状态status,4：用户申请时间applyTime
          column: 3,
          order: 0
        },
        {
          column: 4,
          // 正反序，0:正序，1：反序
          order: 1
        }
      ],

      groupList: []
    };
  },
  getters: {
    isCoach(state) {
      return state.activeTeam?.role === TEAM_GROUP_HOLDER_ROLE;
    },
    activeTeam(state) {
      return state.teamList.find((item) => item.teamId === state.activeTeamId);
    },
    // 搜索过滤
    filteredTeamList(state) {
      return state.search
        ? state.teamList.filter((item) => {
            return item.name && item.name.includes(state.search);
          })
        : state.teamList;
    },
    showingApplyList(state) {
      return state.applyList;
    },
    applyTabVisible(state) {
      return state.applyList.length;
    },
    groupListOptions() {
      const val = this.groupList;
      const opt = {
        name: `${t('TD1063')}`,
        id: 'all'
      };
      if (val.length > 0) return [opt, ...val];
      return [];
    }
  },
  actions: {
    changeListType(val) {
      this.pagination.current = 1;
      this.paginationForApplyTab.current = 1;
      this.listType = val;
    },
    changeListTypeAndFetchNew(val) {
      this.changeListType(val);
      if (val === TAB_MEMBER_LIST) {
        this.activeGroupId = DEFAULT_GROUP_OPTION;
        this.resetGroupMembersPage();
      } else {
        this.getApplyList(1);
      }
    },
    toggleDrawerStatus() {
      this.drawerOpenStatus = !this.drawerOpenStatus;
    },
    // 获取团队列表
    async fetchUserTeamlist() {
      const res = await axios.get(`${team.userTeamlist}`);
      const { data } = res;
      let arr = [];
      if (data.teamList.length) {
        for (let i = 0; i < data.teamList.length; i++) {
          if (data.teamList[i].role !== 3) arr.push(data.teamList[i]);
        }
        this.teamList = arr;
      }
      return arr;
    },
    // 操作类接口完成后，直接更新缓存，避免请求团队列表接口
    updateCurrentTeamInfo(info) {
      this.teamList = this.teamList.map((item) => {
        if (item.teamId === this.activeTeamId) {
          return { ...item, ...info };
        }
        return item;
      });
    },
    setActiveTeamId(id) {
      this.activeTeamId = id;
    },
    toggleCreateModal(val) {
      this.isShowCreateTeam = val;
    },
    // 侧边栏搜索
    doSearch(val) {
      this.search = val;
    },

    // 成员列表
    resetMemberListPage() {
      this.activeGroupId = DEFAULT_GROUP_OPTION;
      this.getTeamGroup();
      this.resetGroupMembersPage();
    },
    // 获取team的分组
    async getTeamGroup() {
      const res = await axios.post('/team/group/query', {
        teamId: this.activeTeamId,
        startNo: 0,
        //  20个分组是上限
        size: 20
      });
      const { data } = res;
      this.groupList = data.groupList;
      return data;
    },
    // 先切换到第一页，然后再 fetch，不管是否成功，页码都是第一页
    async resetGroupMembersPage() {
      this.pagination.current = 1;
      this.getGroupMembers(1);
    },
    // 刷新当前页
    async refreshCurrentGroupMembers(isKickout) {
      let { current, pageSize, total } = this.pagination;
      // 踢了人要防止最后一页没有人了
      if (isKickout) {
        const nextLastPage = Math.ceil((total - 1) / pageSize);
        if (current > nextLastPage) {
          current = nextLastPage;
        }
      }
      this.getGroupMembers(current);
    },
    // 刷新当前页
    async refreshCurrentApplyList() {
      let { current } = this.paginationForApplyTab;

      this.getApplyList(current);
    },
    // 获取分组成员列表，默认获取team所有成员
    async getGroupMembers(page) {
      this.memberListLoading = true;
      const { pageSize } = this.pagination;
      const startNo = (page - 1) * pageSize;
      const res = await axios.post('/team/user/search', {
        teamId: this.activeTeamId,
        groupId: this.activeGroupId === DEFAULT_GROUP_OPTION ? '' : this.activeGroupId,
        startNo,
        orderByList: this.sortConf,
        size: pageSize,
        statusList: [100]
      });
      const { data } = res;
      this.pagination.total = data.totalRecords;
      this.pagination.current = page;
      this.memberList = data.teamUserDataList;
      this.memberListLoading = false;
      return data;
    },
    handlePageChange(page) {
      this.getGroupMembers(page);
    },
    handlePageSizeChange(val) {
      this.pagination.pageSize = val;
      this.handlePageChange(1);
    },
    handleApplyPageChange(page) {
      this.getApplyList(page);
    },
    handleApplyPageSizeChange(val) {
      this.paginationForApplyTab.pageSize = val;
      this.handleApplyPageChange(1);
    },
    // memberlist 操作
    async teamMemberRoleChange(item, role) {
      const res = await axios.post('/team/user/save', {
        teamId: this.activeTeamId,
        userId: item.userId,
        role
      });
      return res;
    },
    async teamMemberDelete(item) {
      const res = await axios.post('/team/user/delete', {
        teamId: this.activeTeamId,
        userId: item.userId
      });
      return res;
    },
    async teamDissolve() {
      const res = await axios.post('/team/dissolve', {
        teamId: this.activeTeamId
      });
      return res;
    },

    // 获取team申请列表
    async getApplyList(page) {
      const { pageSize } = this.paginationForApplyTab;
      const startNo = (page - 1) * pageSize;
      this.applyListLoading = true;
      const res = await axios.post('/team/user/search', {
        teamId: this.activeTeamId,
        startNo,
        orderByList: this.sortConfForApply,
        size: pageSize,
        statusList: [1, 2, 100]
      });
      const { data } = res;
      // applyList一定要变化
      if (data.teamUserDataList) {
        const list = data.teamUserDataList;

        const wait = [];
        if (list.length > 0) {
          list.forEach((item) => {
            // status = 1 是未批准
            if (item.status === 1) wait.push(item);
          });
        }

        // 只第一页的才用于计算
        if (page === 1) {
          this.waitApprovedMember = wait.length;
        }

        this.applyList = list;
        this.paginationForApplyTab.current = page;
        this.paginationForApplyTab.total = data.totalRecords;
      } else {
        this.applyList = [];
        this.waitApprovedMember = 0;
      }
      this.applyListLoading = false;
      return data;
    },
    async doAgree(id) {
      const res = await axios.post(`/team/approve?teamId=${this.activeTeamId}&userId=${id}&status=100`);
      return res;
    },
    async doReject(ids) {
      const res = await axios.post(`/team/approve?teamId=${this.activeTeamId}&userId=${ids}&status=2`);
      return res;
    },
    async saveRemark(u, remarkName) {
      const res = await axios.post('/team/user/save', {
        teamId: this.activeTeamId,
        userId: u.userId,
        remarkName: remarkName
      });
      return res.data;
    },

    // 创建team
    async createTeam(fData) {
      if (!fData) return;
      const res = await axios.post('/team/save', fData);

      return res;
    },

    // 消息列表打开团队消息使用
    async getTeam({ teamId }) {
      if (!teamId) return;
      const res = await axios.get(`/team/info?teamId=${teamId}`);
      return res.data;
    },

    // 更新team资料
    async updateTeam(fData) {
      if (!fData) return;
      fData.append('teamId', `${this.activeTeamId}`);
      const res = await axios.post('/team/update', fData);

      return res.data;
    },

    // invite
    async refreshCode() {
      const res = await axios.post(`/team/refreshcode?teamId=${this.activeTeamId}`);
      return res;
    },
    async mailinvite(emails) {
      const res = await axios.post('/team/mailinvite', {
        teamId: this.activeTeamId,
        emails
      });
      return res.data;
    }
  }
});
