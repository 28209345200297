import { createI18n } from 'vue-i18n';

const instance = createI18n({ allowComposition: true, warnHtmlMessage: false, warnHtmlInMessage: 'off', fallbackLocale: 'en-US' });

export default instance;
const oldT = instance.global.t;

export const i18nKeyValidateRegex = /^wind_[a-z]+|weather_[a-z0-9]+|weather_accu_\d+|[A-Z]{1,4}\d+(_desc)?$/;
export function custom_t(key, options) {
  // 多语言 key 是4个以内的大写字母开头，数字结尾或者_desc结尾，还有天气枚举值等特殊字符
  if (key && i18nKeyValidateRegex.test(key)) {
    return oldT(key, options);
  }
  return key;
}

instance.global.t = custom_t;
export const i18n = instance.global;

// 跟 app/extend/helper.js 同步更新
export const getSupportLanguage = (lang = 'en') => {
  // ISO 639 标准语言代码 zh-CN  zh-TW, zh-HK
  lang = lang.toLowerCase();
  const reg_zh = /^zh.*/;
  const reg_zh_hant = /^zh-.*(tw|hk|hant)(.*?)$/;
  const reg_fr = /^fr.*/;
  const reg_pl = /^pl.*/;
  const reg_de = /^de.*/;
  const reg_es = /^es.*/;
  const reg_ja = /^ja.*/;
  const reg_th = /^th.*/;
  const reg_pt = /^pt.*/;
  const reg_it = /^it.*/;
  const reg_vi = /^vi.*/;
  const reg_ru = /^ru.*/;

  if (reg_zh_hant.test(lang)) {
    return 'zh-TW';
  } else if (reg_zh.test(lang)) {
    return 'zh-CN';
  } else if (reg_fr.test(lang)) {
    return 'fr-FR';
  } else if (reg_pl.test(lang)) {
    return 'pl-PL';
  } else if (reg_de.test(lang)) {
    return 'de-DE';
  } else if (reg_es.test(lang)) {
    return 'es-ES';
  } else if (reg_ja.test(lang)) {
    return 'ja-JP';
  } else if (reg_th.test(lang)) {
    return 'th-TH';
  } else if (reg_pt.test(lang)) {
    return 'pt-PT';
  } else if (reg_it.test(lang)) {
    return 'it-IT';
  } else if (reg_vi.test(lang)) {
    return 'vi-VN';
  } else if (reg_ru.test(lang)) {
    return 'ru-RU';
  }
  return 'en-US';
};
