const TeamManagement = () => import('../pages/teamManagement/index.vue');
const TeamSetting = () => import('../pages/teamManagement/subviews/setting.vue');
import { TEAM_MANAGEMENT } from '@client/pages/layout/components/instance';
const meta = {
  menuName: TEAM_MANAGEMENT
};
export default [
  {
    name: 'teamManagement',
    path: '/admin/settings',
    component: TeamManagement,
    children: [
      {
        name: 'teamSetting',
        path: '/admin/settings/:teamId',
        component: TeamSetting,
        meta
      }
    ]
  }
];
