import { defineStore } from 'pinia';
import { team } from '@client/api/index';
import { userStore } from './userStore';

export const memberDataStore = defineStore('memberData', {
  state() {
    return {
      userData: null,
      viewerData: null,
      isLoading: false,
      teamViewMode: false,
      activeTeamCoachList: [],
      activeTeamUserList: [],
      groupList: [],
      activeGroupUserList: [],
      readPermission: true,
      editPermission: true
    };
  },
  getters: {
    isMyself(state) {
      const userStoreRef = userStore();
      if (!state.userData && !state.teamViewMode) {
        return true;
      }
      if (state.userData && userStoreRef) {
        return state.userData.userId && userStoreRef.userId && state.userData.userId === userStoreRef.userId;
      } else {
        return false;
      }
    }
  },
  actions: {
    resetData() {
      this.userData = null;
      this.viewerData = null;
      this.isLoading = false;
      this.teamViewMode = false;
      // state.activeTeamCoachList = [];
      // state.activeTeamUserList = [];
    },
    setMemberData(user, hasPermission) {
      this.userData = user;
      // 如果是教练, 用户开启了教练编辑权限, 也请求数据
      if (hasPermission) {
        this.getTeamMemberData(user.teamId, user.userId);
      }
    },
    // 当前查看者的权限
    setViewerData(viewerData) {
      this.viewerData = viewerData;
      this.teamViewMode = true;
      this.checkEditPermission();
    },

    // 当前的团队教练名单
    setActiveTeamCoachList(coachList) {
      this.activeTeamCoachList = coachList;
    },
    // 判断当前团队是否有分组
    setGroupList(groupList) {
      this.groupList = groupList;
    },
    checkEditPermission() {
      if (this.isMyself) {
        this.editPermission = true;
        return;
      }
      if (!this.viewerData) {
        this.editPermission = false;
        return;
      }
      let editable = false;
      const { userData } = this;
      if (userData && userData.userProfile && userData.userProfile.allowCoachEditing === 1) {
        editable = true;
      }
      if (!editable) {
        this.editPermission = false;
        return;
      }
      const { role } = this.viewerData;
      const isCoachInTeam = role !== 3;
      if (!isCoachInTeam) {
        this.editPermission = false;
      } else {
        // 判断是否有分组, 并判断是不是在同一个分组
        if (this.groupList.length) {
          const userStoreRef = userStore();
          const currentUserId = userStoreRef.userId; // 当前查看者的 id
          const userId = userData.userId;
          let currentUserInGroup = false;
          let userInGroup = false;
          this.activeGroupUserList.forEach((user) => {
            if (user.userId === currentUserId) {
              currentUserInGroup = true;
            }
            if (user.userId === userId) {
              userInGroup = true;
            }
          });
          const result = currentUserInGroup && userInGroup;
          this.editPermission = result;
        } else {
          this.editPermission = true;
        }
      }
    },
    // 当前分组内的成员名单
    setActiveGroupUserList(userList) {
      this.activeGroupUserList = userList;
    },
    setActiveTeamUserList(teamUserList) {
      this.activeTeamUserList = teamUserList;
    },
    updateEditePermission(value) {
      this.editPermission = value;
    },
    updateReadPermission(value) {
      this.readPermission = value;
    },
    // 获取团队的成员的详细信息
    async getTeamMemberData(teamId, userId) {
      this.isLoading = true;

      const { data } = await team.api4GetTeamUserDetail({
        params: {
          teamId,
          userId
        }
      });

      const res = data.value;
      console.log('get team user detail');
      this.userData.zoneData = res.zoneData;
      this.userData.climbConfig = res.climbConfig;
      this.userData.criticalPower = res.criticalPower;
      this.isLoading = false;
    }
  }
});
