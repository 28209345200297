<template>
  <div class="flex flex-col items-stretch">
    <div class="layout-left-logo flex justify-center items-center border-b border-dark-60">
      <div
        @click="openWeb"
        class="coros-logo cursor-pointer flex flex-row justify-center items-center"
      >
        <span class="iconfont iconCOROS-Logobai text-40 text-white"></span>
      </div>
    </div>
    <!-- menu 导航按钮 -->
    <div class="flex flex-col justify-between flex-1">
      <!-- 上部分 -->
      <div class="layout-left-upper flex flex-col space-between flex-1">
        <div
          v-for="(item, index) in leftMenus"
          :key="index"
          class="layout-left-personal flex items-center cursor-pointer justify-center mt-48"
          @click="handleTabChange(item.name)"
        >
          <span
            class="iconfont text-30"
            :class="[item.icon, item.name === activeName ? 'text-danger-6' : '']"
          />
        </div>
      </div>
      <!-- 下部分 -->
      <div class="layout-left-bottom flex flex-col mt-48">
        <div
          class="layout-left-personal-setting flex items-center justify-center cursor-pointer mb-30"
          @click="handleTabChange('personalSetting')"
        >
          <Avatar
            class="user-avatar"
            :image-url="userAvatar"
          ></Avatar>
        </div>
        <Message class="layout-left-message mb-30" />
        <!-- more & 多语言切换 -->
        <More class="layout-left-more mb-30" />
      </div>
    </div>
  </div>
</template>
<script setup>
import defaultAvatar from '@assets/images/personal/avatar.png';
import { TABS, TEAM_MANAGEMENT, COMPETITION, TEAM } from './instance';
import { Avatar } from '@arco-design/web-vue';
import More from './more.vue';
import Message from './message/Message.vue';
import { nextTick, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { userStore } from '@client/store/userStore';
import useTeams from '@client/pages/team/useTeams';
import { isCoach } from '@client/helper';

const userStoreRef = userStore();
const { availableTeams, getAllTeam } = useTeams();

const router = useRouter();
const route = useRoute();

const userAvatar = computed(() => userStoreRef.headPic || defaultAvatar);
const leftMenus = computed(() => {
  const hasTeam = availableTeams.value.length > 0;
  return TABS.filter((i) => {
    if (i.name === TEAM_MANAGEMENT) {
      return isCoach(userStoreRef.role);
    }

    if (i.name === TEAM) {
      return hasTeam;
    }

    if (i.name === COMPETITION) return isCoach(userStoreRef.role) && hasTeam && userStoreRef.userData?.isCompetitionTestUser === 1;
    return true;
    // return  !i.hideWhenInit;
  });
});

const activeName = ref('dashboard');
nextTick(() => {
  // 子菜单用 meta.menuName
  let name = route.meta?.menuName || route.name;
  activeName.value = name;
});
function handleTabChange(name) {
  activeName.value = name;
  router.push({ name });
}
const openWeb = () => {
  window.open('https://coros.com');
};

getAllTeam();
</script>

<style lang="scss" scoped>
@import '@client/styles/_mixins.scss';
.layout-left-logo {
  height: px2rem144(56);
  flex: 0 0 px2rem144(56);
}
.layout-left-personal-setting {
  .user-avatar {
    width: px2rem144(36);
    height: px2rem144(36);
  }
}

.coros-logo {
  width: px2rem144(32);
  height: px2rem144(32);
}
</style>

<!-- 侧边栏响应式 -->
<style lang="scss" scoped>
@import '@client/styles/_mixins.scss';

// @media screen and (max-height: 503px) {
//   .layout-left {
//     overflow: hidden;
//     padding-right: px2rem144(6);
//     &:hover {
//       padding-right: 0;
//       overflow-y: auto;
//     }
//   }
// }
.layout-left {
  color: rgba(255, 255, 255, 0.6);
}
@media screen and (max-height: 719px) {
  .layout-left-personal {
    margin-top: px2rem144(30);
  }
  .layout-left-bottom {
    margin-top: px2rem144(30);
  }
}
@media screen and (max-height: 600px) {
  .layout-left-personal {
    margin-top: px2rem144(14);
  }
  .layout-left-bottom {
    margin-top: px2rem144(20);
  }
}
@media screen and (max-height: 503px) {
  .layout-left-upper {
    .layout-left-personal {
      margin-top: px2rem144(12);
    }
  }
}

@media screen and (max-height: 719px) {
  // .layout-left-more,
  .layout-left-message {
    margin-top: px2rem144(30);
  }
}
@media screen and (max-height: 600px) {
  // .layout-left-more,
  .layout-left-message {
    margin-top: px2rem144(14);
  }
}
@media screen and (max-height: 503px) {
  // .layout-left-more,
  .layout-left-message {
    margin-top: px2rem144(12);
  }
}
</style>
