import md5 from 'md5';
const SALT = 'EtsPYVgTCk184RxjLveofpW0BIMNn2wr';
export function signPassword(password) {
  return `bear-${SALT}${window.btoa(password)}`;
}

// 请求faq项目 openapi 加签算法
export function signParams(params, appSecret) {
  const paramsKeys = Object.keys(params).sort((a, b) => {
    if (a > b) {
      return 1;
    } else {
      return -1;
    }
  });
  let str = '';
  paramsKeys.forEach((item) => {
    let value = params[item];
    if (Array.isArray(value)) {
      value = '';
    }
    if (typeof value === 'object') {
      value = '';
    }
    str += item + value;
  });
  str += appSecret;
  const signStr = md5(str).toUpperCase();
  return signStr;
}
