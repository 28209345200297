export const assetsRequire = function (filepath) {
  const CDN_URL = import.meta.env.VITE_CDN_URL;
  filepath = filepath.replace(/@assets/, CDN_URL);
  return filepath;
};

export const getImgSrcset = (fileName) => {
  const name = fileName.split('.');
  if (name.length !== 2) {
    return '';
  }
  let result = '';
  for (let n = 1; n <= 3; n++) {
    if (n === 1) {
      result += `${name[0]}.${name[1]} ${n}x, `;
      continue;
    }
    result += `${name[0]}@${n}x.${name[1]} ${n}x, `;
  }
  result = result.substr(0, result.length - 2);
  return result;
};

export const getOssImageHandleUrl = (url, width = 0, height = 0, isSupportWebp = false, quality = 80) => {
  try {
    // 判断文件格式
    // 获取最后一个.的位置
    const index = url.lastIndexOf('.');
    // 获取后缀
    const ext = url.substr(index + 1);
    const supportArr = ['png', 'jpg', 'jpeg', 'webp'];
    let ossType = 'aws';
    if (supportArr.indexOf(ext) > -1) {
      const reg = /^http(s)?:\/\/(.*?)\//;
      const arr = reg.exec(url);
      if (!arr) {
        return url;
      }
      const host = arr[0];
      if (!host) return url;
      const ossTypeConfig = {
        aliyun: ['https://staticcn.coros.com/'],
        aws: ['https://static.coros.com/']
      };
      ossType = 'aliyun';
      for (const key in ossTypeConfig) {
        const item = ossTypeConfig[key];
        if (item.indexOf(host) > -1) {
          ossType = key;
        }
      }
    } else {
      return url;
    }
    // 输出结果
    if (ossType === 'aliyun') {
      const format = isSupportWebp ? '/format,webp' : '';
      const resize = width > 0 || height > 0 ? `/resize,w_${width},h_${height}` : '';
      const rawUrl = `${url}?type=img&x-oss-process=image${resize}${format}/quality,q_${quality}/format,webp`;
      // 规避阿里云文件名空格问题
      return encodeURI(rawUrl);
    }
    const host = 'https://d1teks7lx8pls2.cloudfront.net';
    const arrUrl = url.split('//');
    const path = arrUrl[arrUrl.length - 1];
    const start = path.indexOf('/');
    let relUrl = path.substring(start); // stop省略，截取从start开始到结尾的所有字符

    if (relUrl.indexOf('?') !== -1) {
      relUrl = relUrl.split('?')[0];
    }
    return `${host}${isSupportWebp ? '/filters:format(webp)' : ''}/filters:quality(${quality})/fit-in/${width}x${height}${relUrl}`;
  } catch (err) {
    return url;
  }
};
export const getImageAssetsFile = function (name) {
  // 获取文件夹下所有图片名称
  const assetUrl = assetsRequire(`@assets/coros-traininghub-v2/images/${name}`);
  return assetUrl;
};

export const getScheduleAssetsFile = function (name) {
  // 获取文件夹下所有图片名称
  const assetUrl = assetsRequire(`@assets/coros-traininghub-v2/images/schedule/${name}`);
  return assetUrl;
};
