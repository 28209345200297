import * as Sentry from '@sentry/vue';
export const initSentry = function (app, router) {
  let locals=window.__INITIAL_STATE__
  // 如果类型是字符, 需要转换为对象
  if (typeof locals === 'string') {
    locals = JSON.parse(locals);
  }
  let { version, env, web_dsn } = locals;
  if (!web_dsn) return;
  Sentry.init({
    app,
    dsn: web_dsn,
    environment: env,
    release: version,
    tracesSampleRate: 1.0,
    debug: false,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        // tracingOrigins: ['localhost', 'my-site-url.com', /^\//]
      })
    ]
  });
};
