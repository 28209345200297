import { createApp } from 'vue';
import { createRouter } from './router';
import createStore from './store';
// import ArcoVue from '@arco-design/web-vue';
import i18n from './helper/i18n';
import { initTrace } from './trace';
import { initSentry } from './sentry';

if (!window.ResizeObserver) {
  import('@juggle/resize-observer').then((module) => {
    window.ResizeObserver = module.ResizeObserver;
  });
}

/** ********** 项目扩展 *************/

import plugin from './plugins/index';
import resize from '@client/directives/resize';
// vue-coros-dark 里面已经包含 import '@arco-design/web-vue/dist/arco.css';
import '@arco-themes/vue-coros-dark/index.less';

import './styles/index.scss';
import '@unocss/reset/tailwind.css';
import 'virtual:uno.css';

import '@assets/js/flexible';
const router = createRouter('client');
const pinia = createStore();

import App from './App.vue';
const app = createApp(App);

initTrace(app);
initSentry(app, router);

app.directive('resize', resize);
app.use(router);
app.use(pinia);

app.use(plugin);
// app.use(ArcoVue);
app.use(i18n);
// 初始化 pinia
// 注意：__INITIAL_STATE__需要在 src/types/shims-global.d.ts中定义
if (window.__INITIAL_STATE__) {
  pinia.state.value = JSON.parse(window.__INITIAL_STATE__);
}

// login 页面不能启用暗色
if (!location.href.includes('login')) {
  document.body.setAttribute('arco-theme', 'dark');
}

app.mount('#app');
