const Login = () => import('../pages/login/index.vue');
const Apply = () => import('../pages/apply/Apply.vue');
const ActivityDetail = () => import('../pages/activity-detail/index.vue');
const PCCompetitionDetail = () => import('../pages/competition/detail/pc/index.vue');
const MobileCompetitionDetail = () => import('../pages/competition/detail/mobile/index.vue');
const LiveCompetition = () => import('../pages/competition/live/mobile/index.vue');

const AuthConfirmMobile = () => import('../pages/auth-confirm/MobileViewer.vue');
const AuthConfirmPC = () => import('../pages/auth-confirm/PcViewer.vue');

import { getClientPlatform } from '@client/helper/index';
const platform = getClientPlatform();
const isMobile = platform !== 'pc';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/apply/:teamId/:inviteCode',
    name: 'apply',
    component: Apply
  },
  {
    path: '/activity-detail',
    name: 'activity-detail',
    component: ActivityDetail
  },
  {
    path: '/live',
    name: 'LiveCompetition',
    component: LiveCompetition
  },
  {
    // 需要授权
    path: '/admin/competitionDetail',
    name: 'competitionDetail',
    component: isMobile ? MobileCompetitionDetail : PCCompetitionDetail,
    children: []
  },
  {
    path: '/observerAuth',
    name: 'observerAuth',
    component: isMobile ? AuthConfirmMobile : AuthConfirmPC
  }
];
