import { custom_t } from '@client/helper/i18n';
import axios from '../helper/http';
import Cookie from 'js-cookie';
export default {
  install: (app) => {
    app.config.globalProperties.$getRandomNumber = function (min, max) {
      return parseInt(Math.random() * (max - min + 1) + min);
    };
    app.config.globalProperties.$cookie = Cookie;
    app.config.globalProperties.$axios = axios;

    app.config.globalProperties.$t = custom_t;
    // app.config.globalProperties.$helper = helper;
  }
};
