import { defineStore } from 'pinia';
import http from 'axios';
import Cookies from 'js-cookie';
import axios from '@client/helper/http';
import { getSupportLanguage, i18n } from '@client/helper/i18n';
import { user } from '@client/api/index';
import { isGroupHolder, isCoach } from '@client/helper';
import { initStore as useInitStore } from '@client/store/initStore';
import * as Sentry from '@sentry/vue';
import useTempView from '@client/hooks/useTempView';
import corosTrace from '@coros/trace-core';

export const userStore = defineStore('user', {
  state() {
    let locale = window.__INITIAL_STATE__.locale || 'en-US';
    return {
      accessToken: '',
      locale,
      themes: ['theme-dark', 'theme-light'],
      theme: 'theme-dark',
      userData: null,
      // TODO: 这个 teamUserData 好像没有设置过?
      teamUserData: null,
      email: '',
      nickname: '',
      headPic: '',
      userId: null,
      role: '',
      unit: 0,
      fetched: false,
      zoneData: {},
      climbConfig: {},
      criticalPower: 0,
      sportDataSummary: {},
      // 是否没有运动数据，true是没有，false是有
      isEmpty: true,
      // 是否处了体能模型，true是没出，false是出了
      isNo: true,
      gray_keys: [] // 灰度key
    };
  },
  getters: {
    isGroupHolder(state) {
      return isGroupHolder(state.role);
    },
    isCoach(state) {
      return isCoach(state.role);
    },
    isStudent(state) {
      state.role === 3;
    },
    isMetric(state) {
      return state.unit == 0;
    },
    paceUnit(state) {
      return state.isMetric ? 'min/km' : 'min/mi';
    },
    distanceUnit(state) {
      return state.isMetric ? 'km' : 'mi';
    },
    currentLocale(state) {
      return state.locale;
    }
  },
  actions: {
    getUserInfo() {
      return axios.get(user.account).then((res) => {
        return res.data;
      });
    },
    updateUserInfo(formData) {
      return axios.post(user.accountUpdate, formData).then((res) => {
        return res.data;
      });
    },
    fetchI18n(locale) {
      const initStore = useInitStore();
      const { isProd } = initStore;
      const localeEnv = isProd ? 'prod' : 'test';
      const CDN_URL = import.meta.env.VITE_CDN_URL;
      return http.get(`${CDN_URL}/locale/coros-traininghub-v2/${locale}.${localeEnv}.json?locale=${locale}`).then((res) => {
        return res.data;
      });
    },
    // 只在 Layout 中 saveLocale 为 true
    // options  axios 请求配置, 跨服的情况下base_url 不能使用服务端返回的, 要使用指定base_url
    async initUserInfo({ saveLocale = false, noWarnningMsg = false, options = {} } = {}) {
      const data = await this.fetchUserAccount(options, noWarnningMsg);
      let {
        //
        email,
        nickname,
        headPic = '',
        userId,
        accessToken,
        userProfile,
        role,
        unit,
        zoneData,
        climbConfig,
        criticalPower,
        sportDataSummary
      } = data;
      let locale = userProfile.language;
      if (!locale) locale = window.__INITIAL_STATE__.locale || 'en-US';
      else locale = getSupportLanguage(locale);

      this.switchLocale(locale, saveLocale);

      this.updateUserState({
        email,
        nickname,
        headPic,
        userId,
        accessToken,
        role,
        unit,
        zoneData,
        climbConfig,
        criticalPower,
        sportDataSummary,
        userData: data
      });
      try {
        const scope = Sentry.getCurrentScope();
        scope.setTag('locale', locale);
        scope.setUser({
          username: nickname,
          id: userId,
          email: email || ''
        });
        console.log('sentry scope update');
      } catch (error) {
        console.log('sentry scope error');
      }
      return data;
    },
    // 从 cookie 初始化
    initLocaleFromClientCache() {
      this.switchLocale(this.locale, false);
    },
    async _switchI18nLocale(locale) {
      let _locale = locale.replace(/-/gi, '_');
      // zh en 都已经从 header script 加载
      let localeMessage = window[_locale];
      if (!localeMessage) {
        localeMessage = await this.fetchI18n(locale);
      }
      i18n.setLocaleMessage(locale, localeMessage);
      i18n.locale = locale;
    },
    async switchLocale(locale, saveToServer) {
      await this._switchI18nLocale(locale);
      // this.locale 用于触发 arco ui configProvider 切换语言
      this.locale = locale;

      const { isTempView } = useTempView();
      if (saveToServer && !isTempView.value) {
        Cookies.set('locale', locale, { expires: 365, path: '/' });
        const fd = new FormData();
        fd.append('language', locale);
        this.updateUserInfo(fd);
      }
    },
    async fetchUserAccount(options = {}, noWarnningMsg = false) {
      if (noWarnningMsg) {
        options = {
          ...options,
          headers: {
            'X-No-Warnning': '1'
          }
        };
      }
      const res = await axios.get(`${user.account}`, options);
      if (res.data) {
        const { sportDataSummary } = res.data;
        this.isEmpty = sportDataSummary.count === 0;
        this.isNo = !sportDataSummary.modelValidState;
        // console.log(`🔔 this.isEmpty`, this.isEmpty);
        // console.log(`🔔 this.isNo`, this.isNo);
      }
      return res.data;
    },
    updateUserState(data) {
      this.$patch(data);
      if (this.fetched === false) {
        this.fetched = true;
      }
      setTimeout(() => {
        corosTrace.setArgs({
          pvReportEnable: true,
          pvExt: { role: data.role, user_id: data.userId }
        });
        corosTrace.tracePageView();
      });
    },
    async logoutAccount() {
      await axios.get(`${user.logout}`);
    }
  }
});
