'use strict';
import axios, { CancelToken } from 'axios';
import Cookies from 'js-cookie';
import {
  CROSS_REGION_TEAM_APPLY_ERROR,
  THE_USER_NOT_JOIN_THE_TEAM_ERROR,
  LOGIN_EXPIRED_ERROR,
  VISIT_LATER_ERROR
} from '@client/config/errCode';
import useServerHealthCheck from '@client/pages/errorPage/error-500/hooks'
const { handleServerError,handleApiError } = useServerHealthCheck();
axios.defaults.timeout = 30000;

axios.defaults.xsrfHeaderName = 'x-csrf-token';
axios.defaults.xsrfCookieName = 'csrfToken';
import * as REGIONCONFIG from '@root/config/dict/regionConfig.json';

import { exceptionStore as exceptionStoreRef } from '@client/store/exceptionStore';

export const TEMP_TOKEN = 'coros-temp-token';
export const TEMP_REGION = 'coros-temp-region';

export const getRegionConfig = () => {
  const { regionId } = window.__INITIAL_STATE__;
  const sessionRegion = sessionStorage.getItem(TEMP_REGION);
  return REGIONCONFIG.default[sessionRegion] || REGIONCONFIG.default[regionId];
};

export const getBaseUrl = () => {
  const { teamapi } = getRegionConfig();
  return teamapi;
};
export const getBaseConfigByRegion = (regionId) => {
  return REGIONCONFIG.default[regionId];
};
export const getTokenCookie = () => {
  const { TOKEN } = getRegionConfig();
  const cookie = Cookies.get(TOKEN);
  return cookie;
};

export const getSessionToken = () => {
  const cookie = sessionStorage.getItem(TEMP_TOKEN);
  return cookie;
};

// const handleException = (data) => {
//   let { result } = data;
//   switch (result) {
//     case '1001':
//       // 服务端异常
//       handleMessageWarning('E1001');
//       break;
//     // 扫码状态查询，不需要报错
//     case '5025':
//     // 12019 详情页后台生成中页面有提示，不需要报错
//     case VISIT_LATER_ERROR:
//       break;
//     case '1019':
//       //未登录或token 失效
//       // handleMessageWarning('1019');
//       getRegionConfig()?.TOKEN &&
//         Cookies.remove(getRegionConfig().TOKEN, { domain: import.meta.env.PROD ? '.coros.com' : location.hostname });
//       sessionStorage.removeItem(TEMP_TOKEN);
//       const lastUrl = location.href.replace(location.origin, '');
//       window.location.href = `${location.origin}/login?lastUrl=${encodeURIComponent(lastUrl)}`;
//       break;
//     case '40001':
//       // 教练查看用户数据，相关写操作的接口返回错误码40001，需要提示
//       handleMessageWarning('E40001', true);
//       throw new Error('E40001');
//     default:
//       handleMessageWarning(`E${result}`);
//       break;
//   }
// };

const errorsWithoutTips = [
  '1030',
  '12015',
  '2008',
  VISIT_LATER_ERROR,
  THE_USER_NOT_JOIN_THE_TEAM_ERROR,
  CROSS_REGION_TEAM_APPLY_ERROR,
  '12006',
  '5025',
  '1015',
  '2064',
  '1042'
];

const handleLoginExpired = () => {
  const regionConfig = getRegionConfig();
  if (regionConfig?.TOKEN) {
    const domain = import.meta.env.PROD ? '.coros.com' : location.hostname;
    Cookies.remove(regionConfig.TOKEN, { domain });
  }
  sessionStorage.removeItem(TEMP_TOKEN);

  const lastUrl = location.href.replace(location.origin, '');
  const loginUrl = `${location.origin}/login?lastUrl=${encodeURIComponent(lastUrl)}`;
  window.location.href = loginUrl;
};

const requestInterceptor = (config) => {
  if (config.baseURL === undefined) {
    config.baseURL = getBaseUrl();
  }
  // 追加accesstokan 参数
  config.withCredentials = true;
  let { headers = {} } = config;
  const tempToken = getSessionToken();
  if (tempToken) {
    config.headers.tempToken = tempToken;
  }
  headers.accessToken = getTokenCookie();
  return config;
};

const commonInstance = axios.create();
const instance4useAxios = axios.create();

const responseInterceptor = (response) => {
  let { data = {}, config } = response;
  const { result } = data;
  if (result === '0000' || errorsWithoutTips.includes(result)) {
    // useAxios 非异常会返回上游的data 字段值，接口异常返回的数据没有data 字段，需要返回整个response
    if (config._useAxios && errorsWithoutTips.includes(result)) {
      return response;
    }
    return data;
  }

  if (result === LOGIN_EXPIRED_ERROR) {
    handleLoginExpired();
  }
  
  if (!config?.headers['X-No-Warnning']) {
    handleMessageWarning(`E${result}`);
    handleApiError(result)
  }
  return Promise.reject(data);
};
instance4useAxios.interceptors.request.use(requestInterceptor, function (error) {
  return Promise.reject(error);
});
instance4useAxios.interceptors.request.use(
  (config) => {
    config._useAxios = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
instance4useAxios.interceptors.response.use(responseInterceptor, async function (error) {
  
  handleServerError(error)
  return Promise.reject(error);
});

export { CancelToken };
commonInstance.interceptors.request.use(requestInterceptor, function (error) {
  return Promise.reject(error);
});
commonInstance.interceptors.response.use(responseInterceptor, function (error) {
  // const { handleServerError } = useServerHealthCheck();
  handleServerError(error)
  return Promise.reject(error);
});

export default commonInstance;

const handleMessageWarning = (content, force = false) => {
  let _now = Date.now();
  const exceptionStore = new exceptionStoreRef();
  if (force || (_now > exceptionStore.warningMessageExpiredAt && !exceptionStore.warningMessageStatus)) {
    // 更新过期时间
    exceptionStore.updateWarningMessageStatus(true, content);
  }
  // 检查服务是否异常
};

export { instance4useAxios as instance };
