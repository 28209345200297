import dayjs from 'dayjs';

export default {
  methods: {
    getDateFormat(timestamp, t) {
      if (!t) t = this.$t;
      const thatDay = new Date();
      thatDay.setTime(timestamp);

      const thatDayMoment = dayjs(thatDay);

      let maxDate = new Date();
      maxDate.setDate(maxDate.getDate() + 1);
      maxDate = new Date(dayjs(maxDate).format('YYYY-MM-DD'));

      const minDate = new Date(maxDate);
      minDate.setDate(minDate.getDate() - 7);

      // 今天
      if (thatDayMoment.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
        return `${t('C1038')} ${thatDayMoment.format('HH:mm')}`;
      }

      // 昨天
      if (thatDayMoment.format('YYYY-MM-DD') === dayjs().subtract(1, 'days').format('YYYY-MM-DD')) {
        return `${t('C6037')} ${thatDayMoment.format('HH:mm')}`;
      }

      // 一周内
      if (minDate.getTime() <= thatDay.getTime()) {
        return t(
          {
            0: 'C1045',
            1: 'C1039',
            2: 'C1040',
            3: 'C1041',
            4: 'C1042',
            5: 'C1043',
            6: 'C1044'
          }[thatDay.getDay()]
        );
      }

      // 本年
      if (thatDayMoment.format('YYYY') === dayjs().format('YYYY')) {
        return `${thatDayMoment.format(t('C6006'))}`;
      }

      return `${thatDayMoment.format(t('C6002'))}`;
    }
  }
};
