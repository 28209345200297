import openapi from '@client/helper/openapi';
import { getCookieOption } from '@client/helper/cookie';
import Cookies from 'js-cookie';
class Gray {
  constructor() {}
  async syncV2Gray(user_id) {
    if (!user_id) return;
    const res = await openapi.get(`/openapi/v1/grayUser/find`, {
      user_id,
      readonly: 1
    });
    const result = res.data;
    const { code, data } = result;

    if (code === 200 && data && data.rule_id) {
      const { rule_id: rule } = data;
      const { gray_key: key, gray_value: value, end_time: endTime } = rule;
      // 写入cookie
      const { domain } = getCookieOption();
      console.log(777, domain);
      Cookies.set(key, value, {
        domain,
        expires: new Date(endTime * 1000),
        path: '/'
      });
      return data;
    }
  }
}
export default Gray;
