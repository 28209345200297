// 自动注册到全局
export default {
  name: 'resize',
  mounted(el, binding) {
    // el为绑定的元素，binding为绑定给指令的对象
    console.log('resize绑定');
    let width = '',
      height = '';

    function isReize() {
      const style = document.defaultView.getComputedStyle(el);
      if (width !== style.width || height !== style.height) {
        // 关键(这传入的是函数,所以执行此函数)
        let { clientHeight, clientWidth } = el;
        binding.value({ width: clientWidth, height: clientHeight });
      }

      width = style.width;
      height = style.height;
    }

    el.__vueSetInterval__ = setInterval(isReize, 300);
  },

  beforeUnmount(el) {
    console.log('解绑');
    clearInterval(el.__vueSetInterval__);
  }
};
