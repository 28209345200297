'use strict';
/* eslint-disable */
import axios from 'axios';
import { signParams } from './sign.js';
import { initStore as initStoreRef } from '@client/store/initStore';
// import RegionConfig from '@/config/region.js';
// axios.defaults.baseURL = 'http://127.0.0.1:7001';
axios.defaults.timeout = 15000;
const app = {
  test: {
    app_id: '1656988961565501084',
    app_secret: 'e97d0d505e5678d1e6f67c0e0a80d1c2',
    host: 'https://webtestfaq.coros.com'
  },
  prod: {
    app_id: '1660188068672619112',
    app_secret: 'e03f8a02bd61636076a0c4a87320a5f4',
    host: 'https://faq.coros.com'
  }
};
export default {
  async post(
    url,
    data = {},
    options = {
      timeout: 30000
    }
  ) {
    const env = this.getEnvApp();
    const { app_id, app_secret, host } = app[env];
    if (data instanceof FormData) {
      data.append('app_id', app_id);
      const sign = signParams(data, app_secret);
      data.append('sign', sign);
    } else {
      delete data.sign;
      data.app_id = app_id;
      data.sign = signParams(data, app_secret);
    }

    return await axios.post(`${host}${url}`, data, options);
  },
  async get(
    url,
    data = {},
    options = {
      timeout: 30000
    }
  ) {
    delete data.sign;
    const env = this.getEnvApp();
    const { app_id, app_secret, host } = app[env];
    data.app_id = app_id;

    const sign = signParams(data, app_secret);
    data.sign = sign;

    return await axios.get(`${host}${url}`, { params: data, ...options });
  },
  getEnvApp() {
    const initStore = initStoreRef();
    const regionId = initStore.regionId;
    let _env = 'prod';
    if (regionId > 100) {
      _env = 'test';
    }
    return _env;
  }
};
